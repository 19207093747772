import { useQuery } from "react-query";
import { getPlans } from "../api/v1/subscriptions.api";
import { LONG_STALE } from "../api/axios.config";

export const useGetSubscriptionPlans = (enabled = true) => {
  const query = "plans";

  const { data, isFetching, isFetched, isError } = useQuery(
    query,
    () => getPlans(),
    {
      enabled,
      staleTime: LONG_STALE,
      refetchOnWindowFocus: false,
    },
  );

  return { data, isError, isFetched, isFetching, query };
};

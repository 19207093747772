import { SvgIcon } from "@mui/material";

export const Farcaster = (props) => {
  const defaultProps = {
    width: 24,
    height: 24,
    ...props,
  };

  return (
    <SvgIcon sx={defaultProps}>
      <path d="M22.2667 20.5714C22.6718 20.5714 23 20.8911 23 21.2857V22H15.6667V21.2857C15.6667 20.8911 15.9948 20.5714 16.4 20.5714H22.2667Z" />
      <path d="M22.2667 20.5714V19.8571C22.2667 19.4625 21.9385 19.1429 21.5333 19.1429H17.1333C16.7282 19.1429 16.4 19.4625 16.4 19.8571L16.4 20.5714H22.2667Z" />
      <path d="M19.3333 2H4.66667V4.85714H19.3333V2Z" />
      <path d="M19.3333 4.14286H4.66667V4.85714H19.3333V4.14286Z" />
      <path d="M22.2667 7.71428H1.73333L1 4.85714H23L22.2667 7.71428Z" />
      <path d="M21.5333 7.71428H17.1333L17.1333 19.1429H21.5333V7.71428Z" />
      <path d="M7.6 20.5714C8.00517 20.5714 8.33333 20.8911 8.33333 21.2857V22H1V21.2857C1 20.8911 1.32817 20.5714 1.73333 20.5714H7.6Z" />
      <path d="M7.6 20.5714V19.8571C7.6 19.4625 7.27183 19.1429 6.86667 19.1429H2.46667C2.0615 19.1429 1.73333 19.4625 1.73333 19.8571L1.73333 20.5714H7.6Z" />
      <path d="M6.86667 7.71428H2.46667L2.46667 19.1429H6.86667L6.86667 7.71428Z" />
      <path d="M6.86667 13.3714C6.86667 10.61 9.16493 8.37143 12 8.37143C14.8351 8.37143 17.1333 10.61 17.1333 13.3714V6.22857H6.86667V13.3714Z" />
    </SvgIcon>
  );
};

import { SvgIcon } from "@mui/material";

export const Rarible = (props) => {
  const defaultProps = {
    width: 24,
    height: 24,
    ...props,
  };

  return (
    <SvgIcon sx={defaultProps}>
      <g clip-path="url(#clip0_511_10)">
        <path
          d="M0.047348 5.24655C0.047348 2.34897 2.35565 0 5.2031 0H18.4018C21.2493 0 23.5576 2.34897 23.5576 5.24655V18.6778C23.5576 21.5754 21.2493 23.9244 18.4018 23.9244H5.2031C2.35565 23.9244 0.047348 21.5754 0.047348 18.6778V5.24655Z"
          fill="#FEDA03"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.7021 10.0154C17.7021 11.163 17.0453 11.7339 16.3071 11.9322C17.1906 12.2025 17.83 12.9416 17.83 14.1133V16.2644H14.4587V14.2214C14.4587 13.5965 14.0983 13.3442 13.4822 13.3442H9.48319V16.2644H6.11192V7.66005H14.1216C16.1327 7.66005 17.7021 8.10465 17.7021 10.0154ZM9.48471 10.064H14.0423C14.0491 10.0637 14.0559 10.064 14.0628 10.064C14.3762 10.064 14.6302 10.3266 14.6302 10.6506C14.6302 10.9745 14.3762 11.2371 14.0628 11.2371C14.0559 11.2371 14.0491 11.237 14.0423 11.2368L9.48471 11.2371V10.064Z"
          fill="black"
        />
      </g>
    </SvgIcon>
  );
};

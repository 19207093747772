import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useCollections } from "../../hooks/useCollections";
import ProgressLoader from "../utils/ProgressLoader";
import { CardListing } from "../layout/CardListing";
import { CollectionMenuCard } from "../cards/CollectionMenuCard";
import { grey } from "@mui/material/colors";

const i18n = {
  modalTitle: "Search Collections",
  error: "Error loading collections...",
  placeholder: "Search Public Collections",
  success: (items) =>
    items.length === 0
      ? "No collections matched your search criteria"
      : `Found ${items.length} collection${items.length === 1 ? "" : "s"}`,
};

export const CollectionSelectModal = ({ onClose, onSelect, open, group }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const {
    data: collections,
    isError: isErrorDefault,
    isFetching: isFetchingDefault,
  } = useCollections({
    enabled: true,
    search: searchTerm,
    includePublic: true,
  });

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  const onSubmit = ({ keyword }) => {
    if (keyword === null || keyword === undefined || keyword === "") {
      keyword = null;
    }
    setSearchTerm(keyword);
  };

  const renderCollections = () => {
    if (isErrorDefault) {
      return i18n.error;
    }

    if (isFetchingDefault || !collections) {
      return (
        <Box mt={2}>
          <ProgressLoader />
        </Box>
      );
    }

    return (
      <Box mt={2}>
        <>
          <Typography color={grey[500]}>
            {i18n.success(collections.data.data)}
          </Typography>
          <CardListing>
            {collections.data.data.map((collection) => (
              <CollectionMenuCard
                key={`group-${collection.id}`}
                onSelect={(id) => {
                  onSelect(id);
                  onClose();
                }}
                collection={collection}
              />
            ))}
          </CardListing>
        </>
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.modalTitle}</DialogTitle>
      <DialogContent sx={{ height: 600 }}>
        <Box sx={{ width: "100%" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              sx={{ width: "100%" }}
              {...register("keyword")}
              defaultValue={searchTerm}
              variant="outlined"
              size="small"
            />
          </form>
        </Box>
        {renderCollections()}
      </DialogContent>
    </Dialog>
  );
};

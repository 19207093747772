import { useState } from "react";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { collectionQuery } from "./useCollection";
import { updateContent } from "../api/v1/content.api";
import { collectionContentsQuery } from "./useCollectionContents";
import { contentQuery } from "./useContent";

const i18n = {
  success: "Content update successfully!",
  error: "Unable unable to update content at this time.",
};

export const useContentUpdate = () => {
  const [isFetching, setFetching] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [isError, setError] = useState(false);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const invalidateQueries = async (queries) => {
    await Promise.all(
      queries.map((key) => {
        return queryClient.invalidateQueries(key);
      }),
    );
  };

  const call = (collectionId, contentId, payload) => {
    setFetching(true);

    updateContent(contentId, payload)
      .then(async () => {
        await invalidateQueries([
          collectionContentsQuery(collectionId),
          collectionQuery(collectionId),
          contentQuery(contentId),
        ]);
        setFetching(false);
        setFetched(true);
        enqueueSnackbar(i18n.success, { variant: "success" });
      })
      .catch((err) => {
        const errorMsg =
          err && err.response.data.error
            ? err.response.data.error[0]
            : i18n.error;

        setFetching(false);
        setError(true);
        enqueueSnackbar(errorMsg, { variant: "error" });
      });
  };
  return { call, isFetching, isFetched, isError };
};

import {
  AttachMoney,
  BarChart,
  Collections,
  Comment,
  Description,
  Egg,
  EmojiEvents,
  Favorite,
  Gavel,
  Group,
  Handshake,
  HowToVote,
  Insights,
  Leaderboard,
  LegendToggle,
  NoteAdd,
  Notifications,
  PeopleAlt,
  PersonAddAlt1,
  Receipt,
  Repeat,
  RssFeed,
  Sell,
  Settings,
  SettingsSuggest,
  ShowChart,
  StackedBarChart,
  Stream,
  Task,
  Token,
  TrendingUp,
  Wallet,
  WavingHand,
  Workspaces,
} from "@mui/icons-material";

import { Avatar, Badge, Tooltip } from "@mui/material";

import { yellow } from "@mui/material/colors";
import React from "react";
import { Arbitrum } from "../../assets/icons/Arbitrum";
import { Base } from "../../assets/icons/Base";
import { Builder } from "../../assets/icons/Builder";
import { ChatGPT } from "../../assets/icons/ChatGPT";
import { Dune } from "../../assets/icons/Dune";
import { Ethereum } from "../../assets/icons/Ethereum";
import { Highlight } from "../../assets/icons/Highlight";
import { Optimism } from "../../assets/icons/Optimism";
import { Polygon } from "../../assets/icons/Polygon";

import { FoundationLogo, OpenSeaLogo } from "../../assets/Logos";
import {
  ARBITRUM,
  BASE,
  ETHEREUM,
  OPTIMISM,
  POLYGON,
} from "../../constants/blochainTypes";

export const chainIconFactory = (blockchain) => {
  const tooltipTitle = `${blockchain} BLOCKCHAIN`;

  switch (blockchain) {
    case BASE:
      return (
        <Tooltip title={tooltipTitle} placement="right-end">
          <Avatar
            sx={{
              width: 25,
              height: 25,
              border: 2,
              backgroundColor: "#FFFFFF",
            }}
          >
            <Base width={25} height={25} color={"#0051ff"} />
          </Avatar>
        </Tooltip>
      );

    case ETHEREUM:
      return (
        <Tooltip title={tooltipTitle} placement="right-end">
          <Avatar
            sx={{
              width: 25,
              height: 25,
              border: 2,
              backgroundColor: "#FFFFFF",
            }}
          >
            <Ethereum width={18} height={18} color={"#234daf"} />
          </Avatar>
        </Tooltip>
      );

    case OPTIMISM:
      return (
        <Tooltip title={tooltipTitle} placement="right-end">
          <Avatar
            sx={{
              width: 25,
              height: 25,
              border: 2,
              backgroundColor: "#ff0000",
            }}
          >
            <Optimism width={18} height={18} color={"#FFFFFF"} />
          </Avatar>
        </Tooltip>
      );

    case POLYGON:
      return (
        <Tooltip title={tooltipTitle} placement="right-end">
          <Avatar
            sx={{
              width: 25,
              height: 25,
              border: 2,
              backgroundColor: "#6d3cf5",
            }}
          >
            <Polygon width={16} height={16} color={"#FFFFFF"} />
          </Avatar>
        </Tooltip>
      );

    case ARBITRUM:
      return (
        <Tooltip title={tooltipTitle} placement="right-end">
          <Avatar
            sx={{
              width: 25,
              height: 25,
              border: 2,
              backgroundColor: "#30496A",
            }}
          >
            <Arbitrum width={16} height={16} color={"#ffffff"} />
          </Avatar>
        </Tooltip>
      );

    default:
      return (
        <Tooltip title={"MULTICHAIN"} placement="right-end">
          <Avatar
            sx={{
              width: 25,
              height: 25,
              border: 2,
              backgroundColor: yellow[300],
            }}
          >
            <Token sx={{ width: 18, height: 18 }} />
          </Avatar>
        </Tooltip>
      );
  }
};

export const behaviorIconFactory = (type, blockchain) => {
  switch (type) {
    case "FollowUsers":
      return (
        <MultichainBadge chain={blockchain}>
          <PersonAddAlt1 />
        </MultichainBadge>
      );

    case "JsonShare":
      return (
        <Avatar sx={{ backgroundColor: "#FFFFFF" }}>
          <Dune width={45} height={45} />
        </Avatar>
      );

    case "RssShare":
      return (
        <Avatar sx={{ backgroundColor: "#EE802F" }}>
          <RssFeed />
        </Avatar>
      );

    case "ChatGptTextShare":
      return (
        <Avatar sx={{ backgroundColor: "#74AA9C" }}>
          <ChatGPT />
        </Avatar>
      );

    case "StreamingEvent":
      return (
        <MultichainBadge chain={blockchain}>
          <Stream />
        </MultichainBadge>
      );

    case "StreamingSales":
      return (
        <MultichainBadge chain={blockchain}>
          <AttachMoney />
        </MultichainBadge>
      );

    case "StreamingMints":
      return (
        <MultichainBadge chain={blockchain}>
          <SettingsSuggest />
        </MultichainBadge>
      );

    case "NounsProposalVoteThreshold":
      return (
        <NounsBadge chain={blockchain}>
          <TrendingUp />
        </NounsBadge>
      );

    case "NounsProposalVoteScoreboard":
      return (
        <NounsBadge chain={blockchain}>
          <StackedBarChart />
        </NounsBadge>
      );

    case "NounsDaoSummary":
      return (
        <NounsBadge chain={blockchain}>
          <Insights />
        </NounsBadge>
      );

    case "NounsProposalReminder":
    case "NounsAuctionReminder":
      return (
        <NounsBadge chain={blockchain}>
          <Notifications />
        </NounsBadge>
      );

    case "NounsAuctionSettled":
    case "NounsBid":
    case "NounsNewAuctions":
      return (
        <NounsBadge chain={blockchain}>
          <Gavel />
        </NounsBadge>
      );

    case "NounsFollowupExistingMembers":
    case "NounsFollowupNewMembers":
      return (
        <NounsBadge chain={blockchain}>
          <WavingHand />
        </NounsBadge>
      );

    case "NounsProposalVoteCast":
    case "NounsProposalVotingStarted":
      return (
        <NounsBadge chain={blockchain}>
          <HowToVote />
        </NounsBadge>
      );

    case "NounsProposalFunded":
      return (
        <NounsBadge chain={blockchain}>
          <Task />
        </NounsBadge>
      );

    case "NounsProposalCreated":
      return (
        <NounsBadge chain={blockchain}>
          <NoteAdd />
        </NounsBadge>
      );

    case "NounsProposalFailed":
      return (
        <NounsBadge chain={blockchain}>
          <Description />
        </NounsBadge>
      );

    case "NounsMembershipSummary":
      return (
        <NounsBadge chain={blockchain}>
          <PeopleAlt />
        </NounsBadge>
      );

    case "NounsTreasurySummary":
      return (
        <NounsBadge chain={blockchain}>
          <Wallet />
        </NounsBadge>
      );

    case "NounsTreasuryWithdrawal":
    case "NounsTreasuryDeposit":
      return (
        <NounsBadge chain={blockchain}>
          <Receipt />
        </NounsBadge>
      );

    case "RaribleSalesSync":
    case "RaribleSalesSummary":
      return (
        <BlockchainAvatarBadge>
          <AttachMoney />
        </BlockchainAvatarBadge>
      );

    case "RaribleMints":
      return (
        <BlockchainAvatarBadge>
          <SettingsSuggest />
        </BlockchainAvatarBadge>
      );

    case "RaribleListings":
      return (
        <BlockchainAvatarBadge>
          <Sell />
        </BlockchainAvatarBadge>
      );

    case "RaribleStats":
      return (
        <BlockchainAvatarBadge>
          <ShowChart />
        </BlockchainAvatarBadge>
      );

    case "OnchainCollectionShare":
      return (
        <BlockchainAvatarBadge>
          <Collections />
        </BlockchainAvatarBadge>
      );

    case "OpenseaSalesSync":
      return (
        <OpenSeaBadge>
          <AttachMoney />
        </OpenSeaBadge>
      );

    case "OpenseaMarketCap":
      return (
        <OpenSeaBadge>
          <BarChart />
        </OpenSeaBadge>
      );

    case "OpenseaCollectorInsight":
      return (
        <OpenSeaBadge>
          <Group />
        </OpenSeaBadge>
      );

    case "OpenseaFloorPrice":
      return (
        <OpenSeaBadge>
          <LegendToggle />
        </OpenSeaBadge>
      );

    case "OpenseaDailyStats":
    case "OpenseaMonthlyStats":
      return (
        <OpenSeaBadge>
          <ShowChart />
        </OpenSeaBadge>
      );

    case "FoundationPrimarySaleTransactions":
      return (
        <FoundationBadge>
          <AttachMoney />
        </FoundationBadge>
      );

    case "FoundationSecondarySaleTransactions":
      return (
        <FoundationBadge>
          <AttachMoney />
        </FoundationBadge>
      );

    case "FoundationSecondaryBidTransactions":
    case "FoundationBidTransactions":
      return (
        <FoundationBadge>
          <Gavel />
        </FoundationBadge>
      );

    case "FoundationOfferTransactions":
    case "FoundationSecondaryOfferTransactions":
      return (
        <FoundationBadge>
          <Handshake />
        </FoundationBadge>
      );

    case "FoundationCollectionLeaderboard":
    case "FoundationCollectorLeaderboard":
      return (
        <FoundationBadge>
          <Leaderboard />
        </FoundationBadge>
      );

    case "FoundationTrendingCollection":
      return (
        <FoundationBadge>
          <TrendingUp />
        </FoundationBadge>
      );

    case "FoundationCollectionSoldOut":
      return (
        <FoundationBadge>
          <EmojiEvents />
        </FoundationBadge>
      );

    case "FoundationDropMinting":
    case "FoundationDropSoldOut":
      return (
        <FoundationBadge>
          <Egg />
        </FoundationBadge>
      );

    case "RecommendBotToFollow":
    case "RetweetOtherBot":
      return (
        <Avatar>
          <Workspaces />
        </Avatar>
      );

    case "TwitterRepost":
    case "TwitterRepostSearch":
      return (
        <Avatar>
          <Repeat />
        </Avatar>
      );

    case "ItemShare":
    case "CollectionPromo":
      return (
        <Avatar>
          <Collections />
        </Avatar>
      );

    case "TextShare":
      return (
        <Avatar>
          <Comment />
        </Avatar>
      );

    case "BotFrensPromotion":
      return (
        <Avatar>
          <Favorite />
        </Avatar>
      );

    case "HighlightItemMint":
    case "HighlightCollectionMint":
    case "HighlightCollectionMintedOut":
      return <HighlightBadge chain={blockchain} />;

    case "HighlightMintStats":
      return <HighlightBadge />;

    default:
      return (
        <Avatar>
          <Settings />
        </Avatar>
      );
  }
};

export const EthereumBadge = ({ children }) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={
        <Avatar
          sx={{ width: 25, height: 25, border: 2, backgroundColor: "#f7adff" }}
        >
          <Ethereum width={18} height={18} />
        </Avatar>
      }
    >
      <Avatar>{children}</Avatar>
    </Badge>
  );
};

export const BlockchainAvatarBadge = ({ children }) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={
        <Avatar
          sx={{
            width: 25,
            height: 25,
            border: 2,
            backgroundColor: yellow[300],
          }}
        >
          <Token sx={{ width: 18, height: 18 }} />
        </Avatar>
      }
    >
      <Avatar>{children}</Avatar>
    </Badge>
  );
};

const MultichainBadge = ({ children, chain }) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={chainIconFactory(chain)}
    >
      <Avatar>{children}</Avatar>
    </Badge>
  );
};

const HighlightBadge = ({ chain }) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={chainIconFactory(chain)}
    >
      <Avatar sx={{ backgroundColor: "#000000" }}>
        <Highlight width={24} height={24} />
      </Avatar>
    </Badge>
  );
};

const NounsBadge = ({ children, chain }) => {
  const fallback = (
    <Avatar
      sx={{ width: 25, height: 25, border: 2, backgroundColor: "#ffc229" }}
    >
      <Builder width={16} height={16} />
    </Avatar>
  );

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={chain ? chainIconFactory(chain) : fallback}
    >
      <Avatar>{children}</Avatar>
    </Badge>
  );
};

const FoundationBadge = ({ children }) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={
        <Avatar sx={{ width: 25, height: 25, border: 2 }}>
          <FoundationLogo />
        </Avatar>
      }
    >
      <Avatar>{children}</Avatar>
    </Badge>
  );
};

const OpenSeaBadge = ({ children }) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={
        <Avatar sx={{ width: 25, height: 25, border: 2 }}>
          <OpenSeaLogo />
        </Avatar>
      }
    >
      <Avatar>{children}</Avatar>
    </Badge>
  );
};

import { Api } from "../axios.config";

const path = "/users";

export const newUser = (payload) => Api.post(path, payload);
export const getUser = (id) => Api.get(`${path}/${id}`);
export const editUser = (id, payload) => Api.put(`${path}/${id}`, payload);

export const getUserCollections = (userId, page) =>
  Api.get(`${path}/${userId}/collections?page=${page}`);

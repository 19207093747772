import { useQuery } from "react-query";
import { MED_STALE } from "../api/axios.config";
import { getBehaviorTemplateStats } from "../api/v1/behavior_templates";

export const behaviorTemplateStatsQuery = (id) =>
  `/behavior_templates/${id}/stats`;

export const useBehaviorTemplateStats = (id) => {
  const query = behaviorTemplateStatsQuery(id);

  const { data, isFetched, isFetching, isError, remove } = useQuery(
    query,
    () => getBehaviorTemplateStats(id),
    {
      refetchOnWindowFocus: false,
      staleTime: MED_STALE,
      onError: (err) => {
        if (err.response.status === 401) {
          remove();
        }
      },
    },
  );
  return { data, isError, isFetched, isFetching, query };
};

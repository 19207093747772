import {
  BOT_BLUESKY,
  BOT_DISCORD,
  BOT_FARCASTER,
  BOT_TWITTER,
} from "../../constants/botTypes";
import { FarcasterConnect } from "../parts/FarcasterConnect";
import { DiscordConnect } from "../parts/DiscordConnect";
import { TwitterConnect } from "../parts/TwitterConnect";
import { BlueskyConnect } from "../parts/BlueskyConnect";
import React, { useContext } from "react";
import { GlobalContext } from "../../contexts/GlobalState";

const AuthenticationConnectCard = ({ bot }) => {
  const { setActiveBot } = useContext(GlobalContext);

  switch (bot.type) {
    case BOT_FARCASTER:
      return (
        <FarcasterConnect
          onClick={() => setActiveBot(bot.id)}
          onConnected={() => {}}
        />
      );

    case BOT_DISCORD:
      return (
        <DiscordConnect
          onClick={() => setActiveBot(bot.id)}
          onConnected={() => {}}
        />
      );

    case BOT_TWITTER:
      return (
        <TwitterConnect
          onClick={() => setActiveBot(bot.id)}
          onConnected={() => {}}
        />
      );

    case BOT_BLUESKY:
      return (
        <BlueskyConnect
          onClick={() => setActiveBot(bot.id)}
          onConnected={() => {}}
        />
      );

    default:
      return "Bot Not defined";
  }
};

export default AuthenticationConnectCard;

import React from "react";
import { Avatar, AvatarGroup } from "@mui/material";
import { GroupWork, LinkOff } from "@mui/icons-material";

export const groupUrl = (id) => `/groups/${id}/`;
export const groupBotsCount = (count) =>
  `${count} ${count === 1 ? "Bot" : "Bots"}`;

export const groupVisibility = (bool) =>
  bool ? "Public Group" : "Private Group";

export const groupAvatar = (avatars) => {
  if (avatars.length > 0) {
    return (
      <AvatarGroup max={3} spacing={"small"}>
        {avatars.map((auth, idx) => {
          return auth ? (
            <Avatar
              key={`auth-${idx}`}
              sx={{ width: 42, height: 42 }}
              src={auth.avatar.original}
            />
          ) : (
            <Avatar key={`auth-${idx}`} sx={{ width: 40, height: 40 }}>
              <LinkOff />
            </Avatar>
          );
        })}
      </AvatarGroup>
    );
  } else {
    return (
      <Avatar>
        <GroupWork />
      </Avatar>
    );
  }
};

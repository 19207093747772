import { useSnackbar } from "notistack";
import { useState } from "react";
import { newUser } from "../api/v1/users";
import { capitalize } from "../components/utils/utils";
import { useAccountSignIn } from "./useAccountSignIn";

const i18n = {
  error:
    "Unable to create an account with those credentials. Please try again.",
  success: "Welcome to BotFrens!",
};

export const useAccountCreate = () => {
  const [isFetching, setFetching] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [isError, setError] = useState(false);

  const { call: signIn } = useAccountSignIn();

  const { enqueueSnackbar } = useSnackbar();

  const call = (payload) => {
    setFetching(true);
    // Register mew user
    newUser(payload)
      .then(async () => {
        const { email, password } = payload.user;
        await signIn({ email, password });
        window.sendToTrackdesk(email);

        setFetched(true);
        setFetching(false);
      })
      .catch((err) => {
        let errorMsg = i18n.registrationError;

        if (err?.response?.data?.errors) {
          const key = Object.keys(err.response.data.errors)[0];
          errorMsg = capitalize(
            `${key} ${err.response.data.errors[key].toString()}`,
          );
        }
        enqueueSnackbar(errorMsg, { variant: "error" });
        setError(true);
        setFetching(false);
      });
  };
  return { call, isFetched, isFetching, isError };
};

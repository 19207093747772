import React from "react";
import { Card, CardHeader, Link } from "@mui/material";
import { authTitle, authURL, authAvatar } from "../utils/authenticationUtils";
import { truncate } from "../utils/utils";

export const AuthenticationCard = ({ authentication }) => {
  return (
    <Card variant="outlined" sx={{ background: "none" }}>
      <CardHeader
        avatar={authAvatar(authentication)}
        title={authTitle(authentication)}
        subheader={
          <Link href={authURL(authentication)} target="_blank">
            {truncate(authURL(authentication), 35)}
          </Link>
        }
      />
    </Card>
  );
};

import React, { useEffect, useState, useMemo } from "react";
import { Box, Dialog, Link, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useContent } from "../../hooks/useContent";
import ProgressLoader from "../utils/ProgressLoader";
import { truncate } from "../utils/utils";

const ContentModal = ({ onClose, open, id }) => {
  const { data: content, isFetched } = useContent(id);
  const [mediaSize, setMediaSize] = useState(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const image = useMemo(() => new Image(), []);
  image.onload = () => {
    const { width, height } = getResizedDimensions(image.width, image.height);
    setMediaSize({ width, height });
  };

  useEffect(() => {
    if (content && content.data.media) {
      image.src = content.data.media.original;
    }
  }, [content, image]);

  function getResizedDimensions(w, h) {
    const maxWidth = isSmallScreen
      ? window.screen.width - 100
      : window.screen.width - 300;
    const maxHeight = window.screen.height - 300;

    const originalRatio = w / h;

    let newWidth = maxWidth;
    let newHeight = newWidth / originalRatio;

    if (newHeight > maxHeight) {
      newHeight = maxHeight;
      newWidth = newHeight * originalRatio;
    }

    return { width: Math.round(newWidth), height: Math.round(newHeight) };
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      {!isFetched && <ProgressLoader />}
      {isFetched && (
        <Box sx={{ width: mediaSize ? mediaSize.width : "auto" }}>
          {mediaSize && (
            <img
              src={content.data.media.original}
              alt={truncate(content.data.title, 50)}
              width={mediaSize.width}
              height={mediaSize.height}
            />
          )}
          <Box sx={{ width: "100%", padding: 2 }}>
            <Typography mb={2}>{content.data.title}</Typography>
            {content.data.url && (
              <Link underline="none" href={content.data.url} target="_blank">
                {content.data.url}
              </Link>
            )}
          </Box>
        </Box>
      )}
    </Dialog>
  );
};

export default ContentModal;

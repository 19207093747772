import React from "react";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import ProgressLoader from "../utils/ProgressLoader";
import { useTheme } from "@mui/material/styles";
import { useSubscriptionInfo } from "../../hooks/useSubscriptionInfo";
import { useSubscriptionUpdate } from "../../hooks/useSubscriptionUpdate";

const i18n = {
  cancelButton: "nevermind",
  discountCoupon: "Discount Coupon",
  modalTitle: "Update Payment Method",
  existingPaymentMethod: (card) => `Credit card on file is ${card}`,
  planFetchError: "Unable to load plan details at this time",
  planUpdateError: "Unable to upload payment info at this time",
  planUpdateSuccess: "Payment info updated",
  updateButton: "update",
};

const SubscriptionUpdateModal = ({ plan, onClose, open }) => {
  const { enqueueSnackbar } = useSnackbar();
  const elements = useElements();
  const stripe = useStripe();
  const theme = useTheme();

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    shouldUseNativeValidation: false,
  });

  const { call: updatePaymentMethod, isFetching } = useSubscriptionUpdate();
  const { data: sub, isFetched } = useSubscriptionInfo({ id: plan.id });

  const onSubmit = ({ coupon }) => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    stripe.createToken(cardElement).then((result) => {
      // Validate credit card
      if (result.error) {
        enqueueSnackbar(result.error.message, { variant: "error" });
        return;
      }
      updatePaymentMethod(plan.id, { payment_token: result.token.id, coupon });
    });
  };

  const renderUpgradeForm = () => {
    const existingCoupon = sub.data.details.discount
      ? sub.data.details.discount.coupon
      : "";
    return (
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack direction="column" spacing={2}>
              <Typography variant="body1">
                {i18n.existingPaymentMethod(plan.payment_method)}
              </Typography>
              <Box
                sx={{
                  padding: 2,
                  border: 1,
                  borderColor: theme.palette.action.disabled,
                  borderRadius: 1,
                }}
              >
                <CardElement
                  options={{
                    style: {
                      base: {
                        color: theme.palette.text.primary,
                        fontWeight: theme.typography.fontWeightLight,
                        fontSize: `${theme.typography.htmlFontSize}px`,
                        "::placeholder": {
                          color: theme.palette.text.secondary,
                        },
                      },
                    },
                  }}
                />
              </Box>
              <TextField
                {...register("coupon")}
                label={i18n.discountCoupon}
                variant="outlined"
                defaultValue={existingCoupon}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" disabled={isFetching} onClick={onClose}>
              {i18n.cancelButton}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isFetching}
            >
              {i18n.updateButton}
            </LoadingButton>
          </DialogActions>
        </form>
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.modalTitle}</DialogTitle>

      {isFetched ? (
        renderUpgradeForm()
      ) : (
        <DialogContent>
          <ProgressLoader />
        </DialogContent>
      )}
    </Dialog>
  );
};
export default SubscriptionUpdateModal;

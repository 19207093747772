import axios from "axios";

export const LONG_STALE = 3.6e6; // one hour
export const MED_STALE = 600000; // ten minutes
export const SHORT_STALE = 60000; // minute

export const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "Z-Client-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

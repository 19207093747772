import React from "react";
import { Avatar, Card, CardHeader, IconButton } from "@mui/material";
import CollectionsIcon from "@mui/icons-material/Collections";
import { truncate } from "../utils/utils";
import { Collection } from "../../types/types";
import { collectionItemCount } from "../utils/collectionUtils";
import AddCircle from "@mui/icons-material/AddCircle";

interface Props {
  collection: Collection;
  onSelect: (id: number) => void;
}

export const CollectionMenuCard = ({ collection, onSelect }: Props) => {
  const { cover, items, title, id } = collection;

  const renderAvatar = () => {
    if (cover) {
      return <Avatar variant="rounded" src={cover} />;
    } else {
      return (
        <Avatar variant="rounded">
          <CollectionsIcon />
        </Avatar>
      );
    }
  };

  return (
    <Card sx={{ width: "100%" }} variant="outlined">
      <CardHeader
        avatar={renderAvatar()}
        title={truncate(title, 25)}
        subheader={collectionItemCount(items)}
        action={
          <IconButton
            color="primary"
            size="small"
            sx={{ mt: 1 }}
            onClick={() => onSelect(id)}
          >
            <AddCircle fontSize="small" />
          </IconButton>
        }
      />
    </Card>
  );
};

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useGroupDelete } from "../../hooks/useGroupDelete";

const i18n = {
  cancel: "nevermind",
  confirmation:
    "Are you sure you want to permanently delete this group? This will also delete all bot behaviors associated with this group, but the bots themselves won't be deleted.",
  submit: "delete",
  title: "Delete Group?",
};

export const GroupDeleteModal = ({ group, open, onClose }) => {
  const { call, isFetching } = useGroupDelete();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.title}</DialogTitle>

      <DialogContent sx={{ marginBottom: 2 }}>
        <Typography>{i18n.confirmation}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          aria-label={i18n.cancel}
          disabled={isFetching}
        >
          {i18n.cancel}
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          onClick={() => call(group.id)}
          loading={isFetching}
          aria-label={i18n.title}
        >
          {i18n.submit}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { useContext, useState } from "react";
import { useSnackbar } from "notistack";
import { createSession } from "../api/v1/sessions";
import { UserContext } from "../contexts/UserContext";

const i18n = {
  error: "Unable to log in with the provided credentials. Please try again.",
  success: "Welcome back!",
};

export const useAccountSignIn = () => {
  const { setUser } = useContext(UserContext);

  const [isFetching, setFetching] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [isError, setError] = useState(false);
  const [data, setData] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const call = (payload) => {
    setFetching(true);

    createSession(payload)
      .then((result) => {
        const { id, name } = result.data;
        setUser({ id, name });

        enqueueSnackbar(i18n.success, { variant: "success" });
        setFetching(false);
        setFetched(true);
        setData(result);
      })
      .catch(() => {
        setFetching(false);
        setError(true);
        enqueueSnackbar(i18n.error, { variant: "error" });
      });
  };
  return { call, data, isFetched, isFetching, isError };
};

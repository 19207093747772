import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { resetPassword } from "../../api/v1/sessions";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const i18n = {
  cancelLabel: "Return Home",
  confirmLabel: "Confirm Password",
  errorMessage: "Unable to reset password at this time",
  errorNoMatch: "Passwords do not match",
  modalTitle: "Reset Password",
  passwordLabel: "New Password",
  successMessage: "Password has been reset",
  toggleLabel: "Toggle password visibility",
};

const PasswordReset = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const [isLoading, setLoading] = useState(false);
  const [isPwdVisible, setPwdVisibility] = useState(false);

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  const onSubmit = ({ password, password_confirmation }) => {
    // Validate passwords match
    if (password_confirmation !== password) {
      enqueueSnackbar(i18n.errorNoMatch, { variant: "error" });
      return;
    }

    setLoading(true);

    resetPassword({
      password,
      password_confirmation,
      token: queryParams.get("token"),
    })
      .then((result) => {
        setLoading(false);
        enqueueSnackbar(i18n.successMessage, { variant: "success" });

        setTimeout(() => {
          navigate("/home");
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(i18n.errorMessage, { variant: "error" });
      });
  };

  const onPasswordClick = () => {
    setPwdVisibility(!isPwdVisible);
  };

  return (
    <Dialog open="true" fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.modalTitle}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={2} sx={{ marginBottom: 5 }}>
            <FormControl>
              <InputLabel htmlFor="password-new">
                {i18n.passwordLabel}
              </InputLabel>
              <OutlinedInput
                {...register("password")}
                autoComplete="off"
                fullWidth
                id="password-new"
                label={i18n.passwordLabel}
                required
                type={isPwdVisible ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={i18n.toggleLabel}
                      onClick={onPasswordClick}
                      edge="end"
                    >
                      {isPwdVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl>
              <InputLabel htmlFor="password-confirmation">
                {i18n.confirmLabel}
              </InputLabel>
              <OutlinedInput
                {...register("password_confirmation")}
                fullWidth
                id="password-confirmation"
                label={i18n.confirmLabel}
                required
                type={isPwdVisible ? "text" : "password"}
              />
            </FormControl>
          </Stack>
          <Divider />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate("/home")}>{i18n.cancelLabel}</Button>
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            {i18n.modalTitle}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default PasswordReset;

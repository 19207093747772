import { useState } from "react";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { invalidateQueries } from "../components/utils/reactQueryUtils";
import { botQuery } from "./useBot";
import { botsQuery } from "./useBots";
import { addBehaviorTemplate } from "../api/v1/behavior_templates";
import { behaviorTemplateStatsQuery } from "./useBehaviorTemplateStats";

const i18n = {
  error: "Unable to create bot behavior at this time",
  success: "Behavior added successfully",
};

export const useBehaviorTemplateAdd = () => {
  const [isFetching, setFetching] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [isError, setError] = useState(false);
  const [data, setData] = useState(null);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const reset = () => {
    setData(null);
    setFetched(false);
    setFetching(false);
    setError(false);
  };

  const call = (id, payload) => {
    setFetching(true);

    const { bot_id } = payload;

    addBehaviorTemplate(id, payload)
      .then(async (result) => {
        await invalidateQueries(queryClient, [
          behaviorTemplateStatsQuery(id),
          botQuery(bot_id),
          botsQuery(),
        ]);

        enqueueSnackbar(i18n.success, { variant: "success" });
        setFetching(false);
        setFetched(true);
        setData(result);
      })
      .catch((err) => {
        const errorMsg =
          err && err.response.data.error ? err.response.data.error : i18n.error;
        enqueueSnackbar(errorMsg, { variant: "error" });
        setFetching(false);
        setError(true);
      });
  };
  return { call, reset, data, isFetched, isFetching, isError };
};

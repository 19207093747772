import { useState } from "react";
import { useSnackbar } from "notistack";
import { deleteSubscription } from "../api/v1/subscriptions.api";
import { useLoggedUserQuery } from "./useLoggedUser";
import { useQueryClient } from "react-query";

const i18n = {
  error: "Unable to cancel your subscription at this time.",
  success: "Your subscription has been cancelled.",
};

export const useSubscriptionDelete = () => {
  const [isFetching, setFetching] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [isError, setError] = useState(false);
  const [data, setData] = useState(null);
  const queryClient = useQueryClient();
  const userQuery = useLoggedUserQuery();
  const { enqueueSnackbar } = useSnackbar();

  const call = (planId) => {
    setFetching(true);

    deleteSubscription(planId)
      .then(async (result) => {
        queryClient.invalidateQueries(userQuery).then(() => {
          enqueueSnackbar(i18n.success, { variant: "success" });
          setFetching(false);
          setFetched(true);
          setData(result);
        });
      })
      .catch((err) => {
        const errorMsg =
          err && err.response.data.error ? err.response.data.error : i18n.error;
        enqueueSnackbar(errorMsg, { variant: "error" });
        setFetching(false);
        setError(true);
      });
  };
  return { call, data, isError, isFetched, isFetching };
};

import { useQuery } from "react-query";
import { getBehaviorDefinitions } from "../api/v1/definitions";
import { LONG_STALE } from "../api/axios.config";

export const useBotBehaviors = ({ bot, enabled = true }) => {
  const query = `behaviors/${bot.type}`;

  const { data, isError, isFetched, isFetching, remove } = useQuery(
    query,
    () => getBehaviorDefinitions(bot.type),
    {
      enabled,
      onError: () => remove(),
      refetchOnWindowFocus: false,
      staleTime: LONG_STALE,
    },
  );

  return { data, isError, isFetched, isFetching, query };
};

import { useQuery } from "react-query";
import { getSubscription } from "../api/v1/subscriptions.api";
import { useSnackbar } from "notistack";

const i18n = {
  error: "Unable to load plan details at this time.",
};

export const useSubscriptionInfoQuery = () => "subscription";

export const useSubscriptionInfo = ({ enabled = true, id }) => {
  const query = useSubscriptionInfoQuery();

  const { enqueueSnackbar } = useSnackbar();

  const { data, isFetched, isFetching, isError } = useQuery(
    query,
    () =>
      getSubscription(id).catch(() => {
        enqueueSnackbar(i18n.error, { variant: "error" });
      }),
    {
      enabled,
      refetchOnWindowFocus: true,
    },
  );
  return { data, isFetching, isFetched, isError, query };
};

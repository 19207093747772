import {
  Box,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useContext, useEffect, useState } from "react";
import { BotMenuCard } from "../../../components/cards/BotMenuCard";
import { BlueskyConnect } from "../../../components/parts/BlueskyConnect";
import { DiscordConnect } from "../../../components/parts/DiscordConnect";
import { FarcasterConnect } from "../../../components/parts/FarcasterConnect";
import { TwitterConnect } from "../../../components/parts/TwitterConnect";
import ProgressLoader from "../../../components/utils/ProgressLoader";
import { GlobalContext } from "../../../contexts/GlobalState";
import { useBots } from "../../../hooks/useBots";

const i18n = {
  error: "Error loading bots. Please try again later...",
  title: "Destination",
  connect: (bool) => (bool ? "back" : "connect another account"),
  helper: "Choose a destination where you'd like to share this collection.",
};

const HEIGHT = "80px";

export const BotSelect = ({ bot, onSelect }) => {
  const {
    data: bots,
    isFetched,
    isError,
  } = useBots({ enabled: true, page: 1 });
  const { setActiveBot } = useContext(GlobalContext);
  const [displayConnect, setDisplayConnect] = useState(false);

  const resetActiveBot = () => {
    setActiveBot(null);
  };

  const onAcccountConnected = () => {
    setDisplayConnect(false);
  };

  const renderConnect = () => {
    return (
      <Stack spacing={2} sx={{ marginTop: 1 }}>
        <Typography variant="body2" sx={{ color: grey[500] }}>
          {i18n.helper}
        </Typography>
        <TwitterConnect
          onClick={resetActiveBot}
          onConnected={onAcccountConnected}
        />
        <DiscordConnect
          onClick={resetActiveBot}
          onConnected={onAcccountConnected}
        />
        <FarcasterConnect
          onClick={resetActiveBot}
          onConnected={onAcccountConnected}
        />
        <BlueskyConnect
          onClick={resetActiveBot}
          onConnected={onAcccountConnected}
        />
      </Stack>
    );
  };

  const renderSelect = () => {
    return (
      <FormControl fullWidth sx={{ height: HEIGHT }}>
        <InputLabel id="bot-select-label">{i18n.title}</InputLabel>
        <Select
          labelId="bot-select-label"
          value={bot ? bot.id : ""}
          label={i18n.title}
          onChange={(event) => {
            const selectedBot = bots.data.data.find(
              (item) => Number(item.id) === Number(event.target.value),
            );

            onSelect(selectedBot);
          }}
        >
          {bots.data.data.map((item, idx) => (
            <MenuItem key={`bot-${idx}`} value={item.id}>
              <BotMenuCard {...item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  // Select the first bot by default
  useEffect(() => {
    if (!bot && bots && bots.data.data.length > 0) {
      onSelect(bots.data.data[0]);
    }
  }, [bot, bots, isFetched, onSelect]);

  if (!isFetched) {
    return (
      <Box sx={{ height: HEIGHT }}>
        <ProgressLoader />
      </Box>
    );
  }

  if (isError || !bots) {
    return i18n.error;
  }

  return (
    <Box>
      {(bots.data.data.length === 0 || displayConnect) && renderConnect()}
      {bots.data.data.length > 0 && !displayConnect && renderSelect()}
      {bots.data.data.length > 0 && (
        <Link
          sx={{ cursor: "pointer" }}
          underline="hover"
          onClick={() => setDisplayConnect((prevState) => !prevState)}
        >
          <Typography variant="overline">
            {i18n.connect(displayConnect)}
          </Typography>
        </Link>
      )}
    </Box>
  );
};

import React, { useEffect, useState } from "react";
import ProgressLoader from "../utils/ProgressLoader";
import BehaviorAddCard from "../cards/BehaviorAddCard";
import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useBotBehaviors } from "../../hooks/useBotBehaviors";
import { useBotBehaviorCreate } from "../../hooks/useBotBehaviorCreate";

const i18n = {
  title: "Bot Behaviors",
};

export const BehaviorAddModal = ({ bot, open, onClose }) => {
  const [addingBehaviorId, setAddingBehaviorId] = useState(null);
  const [addedBehaviorId, setAddedBehaviorId] = useState(null);

  const { data: behaviors, isFetched } = useBotBehaviors({ bot });
  const {
    call: addBehavior,
    isFetched: isBehaviorAdded,
    isFetching: isBehaviorAdding,
  } = useBotBehaviorCreate();

  //TODO: Move this logic into behavior add card. All actions must happen in the child components
  useEffect(() => {
    if (isBehaviorAdded && !isBehaviorAdding) {
      setAddedBehaviorId(addingBehaviorId);
    }
  }, [addingBehaviorId, isBehaviorAdded, isBehaviorAdding]);

  const onBehaviorAdd = ({ id, type }, meta) => {
    setAddingBehaviorId(id);
    addBehavior(bot.id, { shareable_class: type, meta });
  };

  const renderContent = () => {
    if (behaviors.data.length > 0) {
      return (
        <Stack spacing={1} sx={{ marginTop: 1 }}>
          {behaviors.data.map((behavior) => (
            <BehaviorAddCard
              key={behavior.id}
              {...behavior}
              isSaving={addingBehaviorId === behavior.id}
              isAdded={addedBehaviorId === behavior.id}
              onClick={(data) => onBehaviorAdd(behavior, data)}
            />
          ))}
        </Stack>
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.title}</DialogTitle>
      <DialogContent>
        {isFetched ? renderContent() : <ProgressLoader />}
      </DialogContent>
    </Dialog>
  );
};

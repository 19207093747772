import React from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";

import { truncate } from "../utils/utils";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const i18n = {
  etherscan: "etherscan",
};
export const RaribleCollectionCard = ({ data }) => {
  const address = data.id.split(":")[1];
  const image = data?.meta?.content?.find((itm) => itm["@type"] === "IMAGE");
  const etherScanLink = `https://etherscan.io/address/${address}`;

  const renderButton = (title, link) => {
    return (
      <Button
        onClick={() => {
          window.open(link, "_blank");
        }}
        size="small"
        variant="outlined"
      >
        {title}
        <OpenInNewIcon sx={{ ml: 0.5, fontSize: 12 }} />
      </Button>
    );
  };

  return (
    <Card sx={{ display: "flex", width: "100%" }}>
      {image && (
        <CardMedia
          component="img"
          sx={{ width: 150 }}
          image={image.url}
          alt={data.name}
        />
      )}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Typography variant={"body1"}>{data.name}</Typography>
            <Typography variant={"body2"}>
              {truncate(data?.meta?.description, 100)}
            </Typography>

            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              {renderButton(i18n.etherscan, etherScanLink)}
            </Stack>
          </Stack>
        </CardContent>
      </Box>
    </Card>
  );
};

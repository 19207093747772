import React from "react";
import { Stack, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useForm } from "react-hook-form";
import { constructUrlWithParams } from "../utils/utils";

type FormData = {
  keyword: string | null;
};

const SearchForm = () => {
  const search = new URLSearchParams(window.location.search).get("search");

  const { register, handleSubmit } = useForm<FormData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  const onSubmit = ({ keyword }: FormData) => {
    if (keyword === null || keyword === undefined || keyword === "") {
      keyword = null;
    }

    window.location.href = constructUrlWithParams([{ search: keyword }]);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ display: "flex", alignItems: "center" }}
    >
      <Typography variant="overline" sx={{ mr: 2 }} color={grey[600]}>
        search
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register("keyword")}
          defaultValue={search}
          variant="outlined"
          size="small"
        />
      </form>
    </Stack>
  );
};
export default SearchForm;

import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

interface ProgressLoaderProps {
  size?: number;
}

const ProgressLoader: React.FC<ProgressLoaderProps> = ({ size = 24 }) => {
  return <CircularProgress size={size} />;
};

export default ProgressLoader;

import React, { useContext, useState } from "react";
import { Alert, AlertTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { unmuteBot } from "../../../api/v1/bots";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { BOT_TWITTER } from "../../../constants/botTypes";
import { UserContext } from "../../../contexts/UserContext";

const i18n = {
  errorDescription:
    "Please dismiss this notification once you've taken steps to resolve the error above.",
  unmuteError: "Unable to resolve the error at this time",
  dismissButton: "Dismiss error",
};

export const Error = ({ bot, invalidateQuery }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setUpdating] = useState(false);

  const { hasUser } = useContext(UserContext);

  const belongsToUser = () => {
    return hasUser() && bot && bot.is_owner;
  };

  let customError;

  if (!bot.error || !belongsToUser()) {
    return "";
  }

  if (bot.error.includes("Error 32:") && bot.type === BOT_TWITTER) {
    customError =
      "Unable to connect to your Twitter account. Reconnect Twitter acc via Bot Settings and try again.";
  }

  const onUnmute = () => {
    setUpdating(true);

    unmuteBot(bot.id)
      .then((result) => {
        queryClient.invalidateQueries(invalidateQuery).then(() => {
          setUpdating(false);
        });
      })
      .catch(() => {
        setUpdating(false);
        enqueueSnackbar(i18n.unmuteError, { variant: "error" });
      });
  };

  return (
    <Alert
      severity="error"
      variant="filled"
      action={
        <LoadingButton
          mt={2}
          color="inherit"
          size="small"
          aria-label={i18n.dismissButton}
          loading={isUpdating}
          onClick={onUnmute}
        >
          <CloseIcon />
        </LoadingButton>
      }
    >
      <AlertTitle>{customError || bot.error}</AlertTitle>
      {i18n.errorDescription}
    </Alert>
  );
};

import { SvgIcon } from "@mui/material";

export const Dune = (props) => {
  const defaultProps = {
    width: 24,
    height: 24,
    ...props,
  };

  return (
    <SvgIcon sx={defaultProps}>
      <path
        d="M12 21.0002C16.9706 21.0002 21 16.9708 21 12.0002C21 7.02968 16.9706 3.00024 12 3.00024C7.02944 3.00024 3 7.02968 3 12.0002C3 16.9708 7.02944 21.0002 12 21.0002Z"
        fill="#F06040"
      />
      <path
        d="M4.56 17.0643C4.56 17.0643 10.5055 15.1168 20.9843 11.7C20.9843 11.7 21.5583 17.2954 15.7576 20.2141C15.7576 20.2141 12.8971 21.585 9.75888 20.7056C9.75888 20.7056 6.63396 20.1157 4.56 17.0643Z"
        fill="#2B286C"
      />
    </SvgIcon>
  );
};

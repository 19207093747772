import { useState } from "react";
import { useSnackbar } from "notistack";
import { forgotPassword } from "../api/v1/sessions";

const i18n = {
  error: "Unable to reset password at this time.",
};

export const useAccountPasswordReset = () => {
  const [isFetching, setFetching] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [isError, setError] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const call = (payload) => {
    setFetching(true);

    forgotPassword(payload)
      .then((result) => {
        setFetching(false);
        setFetched(true);
        setError(false);
        enqueueSnackbar(result.data.message, { variant: "success" });
      })
      .catch(() => {
        setFetching(false);
        setError(true);
        enqueueSnackbar(i18n.error, { variant: "error" });
      });
  };
  return { call, isFetched, isFetching, isError };
};

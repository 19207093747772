import { SvgIcon } from "@mui/material";

export const Discord = (props) => {
  const defaultProps = {
    width: 24,
    height: 24,
    ...props,
  };

  return (
    <SvgIcon sx={defaultProps}>
      <path d="M20.1536 4.4785C18.5905 3.76287 16.9403 3.25517 15.2453 2.96838C15.0133 3.38304 14.8034 3.80966 14.6165 4.24647C12.811 3.97439 10.9748 3.97439 9.16924 4.24647C8.98222 3.8097 8.77234 3.38309 8.54049 2.96838C6.84434 3.25759 5.19308 3.76649 3.62839 4.48225C0.522068 9.0781 -0.320006 13.5598 0.101031 17.9779C1.92017 19.3219 3.9563 20.3441 6.12093 21C6.60833 20.3445 7.03963 19.649 7.41024 18.921C6.70632 18.6581 6.02692 18.3338 5.3799 17.9517C5.55019 17.8282 5.71673 17.7009 5.87766 17.5774C7.76035 18.4628 9.8152 18.9219 11.8957 18.9219C13.9762 18.9219 16.031 18.4628 17.9137 17.5774C18.0765 17.7103 18.2431 17.8375 18.4115 17.9517C17.7632 18.3344 17.0826 18.6594 16.3774 18.9229C16.7475 19.6505 17.1789 20.3454 17.6667 21C19.8332 20.3468 21.8709 19.3251 23.6903 17.9798C24.1844 12.8562 22.8464 8.41567 20.1536 4.4785ZM7.94355 15.2608C6.77026 15.2608 5.80094 14.196 5.80094 12.8861C5.80094 11.5763 6.73658 10.5021 7.93981 10.5021C9.14304 10.5021 10.1049 11.5763 10.0843 12.8861C10.0637 14.196 9.1393 15.2608 7.94355 15.2608ZM15.8478 15.2608C14.6727 15.2608 13.7071 14.196 13.7071 12.8861C13.7071 11.5763 14.6427 10.5021 15.8478 10.5021C17.0529 10.5021 18.0073 11.5763 17.9867 12.8861C17.9661 14.196 17.0436 15.2608 15.8478 15.2608Z" />
    </SvgIcon>
  );
};

import React, { useContext, useEffect, useRef, useMemo } from "react";
import { useParams } from "react-router";
import ProgressLoader from "../../components/utils/ProgressLoader";
import { GlobalContext } from "../../contexts/GlobalState";
import Layout from "../../components/Layout";
import {
  Avatar,
  Box,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import { useAuthenticationUpdate } from "../../hooks/useAuthenticationUpdate";
import { Discord } from "../../assets/icons/Discord";
import { useAuthenticationCreate } from "../../hooks/useAuthenticationCreate";
import { capitalize } from "../../components/utils/utils";
import { DISCORD, TWITTER } from "../../constants/providerTypes";
import { useBotCreate } from "../../hooks/useBotCreate";
import { botCreatePayload } from "../../components/utils/botUtils";

const i18n = {
  error: (provider) =>
    `Unable to connect ${capitalize(
      provider,
    )} account at this time. Please try again.`,
  success: "All set! Please close this window and return to the main app.",
};

const Auth = () => {
  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );
  const { activeBot } = useContext(GlobalContext);
  const { provider } = useParams();

  const {
    call: createBot,
    data: bot,
    isError: isErrorBot,
    isFetched: isFetchedBot,
    isFetching: isFetchingBot,
  } = useBotCreate();

  const {
    call: createAuth,
    data: auth,
    isError: isErrorAuth,
    isFetching: isFetchingAuth,
  } = useAuthenticationCreate();

  const {
    data: updatedAuth,
    isFetching: isFetchingAuthUpdate,
    call: updateAuth,
  } = useAuthenticationUpdate();

  const firstRender = useRef(true); // prevents useEffect from running twice
  const isAddingBot = useRef(false);

  const hasAuth = auth && !isFetchingAuth && !isErrorAuth;
  const hasUpdatedAuth = updatedAuth && !isFetchingAuthUpdate;
  const hasBot = bot && isFetchedBot && !isFetchingBot && !isErrorBot;

  const authCreatePayload = useMemo(() => {
    switch (provider) {
      case DISCORD:
        return {
          bot_id: activeBot,
          code: queryParams.get("code"),
          provider,
        };

      case TWITTER:
        return {
          bot_id: activeBot,
          oauth_token: queryParams.get("oauth_token"),
          oauth_verifier: queryParams.get("oauth_verifier"),
          provider,
        };
      default:
        return null;
    }
  }, [provider, activeBot, queryParams]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      createAuth(authCreatePayload);
    }

    // Create a discord bot
    if (
      provider === DISCORD &&
      hasUpdatedAuth &&
      !hasBot &&
      !activeBot &&
      !isAddingBot.current
    ) {
      isAddingBot.current = true;
      createBot(botCreatePayload(updatedAuth));
    }

    // Create a Twitter bot
    if (
      provider === TWITTER &&
      hasAuth &&
      !hasBot &&
      !activeBot &&
      !isAddingBot.current
    ) {
      isAddingBot.current = true;
      createBot(botCreatePayload(auth));
    }
  }, [
    activeBot,
    auth,
    createBot,
    authCreatePayload,
    hasAuth,
    hasBot,
    hasUpdatedAuth,
    provider,
    updatedAuth,
    createAuth,
  ]);

  const renderTwitter = () => {
    if (isFetchingAuth) {
      return <ProgressLoader />;
    }

    if (auth && !isErrorAuth) {
      return i18n.success;
    }
  };

  const renderDiscord = () => {
    if (isFetchingAuth || isFetchingAuthUpdate) {
      return <ProgressLoader />;
    }

    if (!isFetchingAuth && !auth) {
      return i18n.error(provider);
    }

    if (auth && updatedAuth) {
      return i18n.success;
    }

    const { channels } = auth;

    return (
      <Box>
        <Typography mb={2}>
          One more thing! Please select a channel in which your bot will post.
        </Typography>
        <Card raised>
          <List sx={{ width: "100%" }}>
            {Object.keys(channels).map((key, idx) => (
              <ListItem key={idx}>
                <ListItemButton
                  onClick={() =>
                    updateAuth(auth.id, {
                      channel_name: key,
                      channel_id: channels[key],
                    })
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "#7289DA" }}>
                      <Discord />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={key}
                    secondary={`Channel ${channels[key]}`}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Card>
      </Box>
    );
  };

  return (
    <Layout>
      {provider === DISCORD && renderDiscord()}
      {provider === TWITTER && renderTwitter()}
    </Layout>
  );
};
export default Auth;

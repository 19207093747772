import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  TextField,
} from "@mui/material";

import { AddAPhoto } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useContentCreate } from "../../hooks/useContentCreate";
import { useContentUpdate } from "../../hooks/useContentUpdate";
import { truncate } from "../utils/utils";

const i18n = {
  cancel: "Nevermind",
  inputDescription: "Description",
  inputHelper: "Optional field available in the post template",
  inputTitle: "Title",
  inputTitleHelper: "280 character limit. Emojis are encouraged",
  inputUrl: "Url",
  title: (content) =>
    content ? `${truncate(content.title, 55)}` : "New Content",
  submit: (content) => (content ? "Update" : "Create"),
};

const ContentCreateModal = ({ content, collection, page, onClose, open }) => {
  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  const [imageFile, setImageFile] = useState();

  const {
    call: createContent,
    isFetching: isCreating,
    isFetched: isCreated,
  } = useContentCreate();
  const { call: updateContent, isFetching: isUpdating } = useContentUpdate();

  const iconBgColor = "rgba(0,0,0,0.6)";

  useEffect(() => {
    if (isCreated) {
      onClose();
    }
  }, [isCreated, onClose]);

  const onSubmit = async (data) => {
    let formData = new FormData();
    formData.append("content[title]", data.title);
    formData.append("content[url]", data.url);
    formData.append("content[collection_ids]", [collection.id]);
    formData.append("content[description]", data.description);

    // Append image file only if it exists
    if (imageFile) {
      formData.append("content[image]", imageFile);
    }

    if (content) {
      updateContent(collection.id, content.id, formData);
    } else {
      createContent(collection.id, page, formData);
    }
  };

  const renderFileUpload = () => {
    const contentFile = Boolean(content && content.media)
      ? content.media.xs
      : null;
    const localFile = imageFile ? URL.createObjectURL(imageFile) : null;
    const filePath = localFile || contentFile;

    return (
      <Paper variant="outlined" sx={{ width: "100%" }}>
        <Stack
          sx={{ height: 200 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {filePath && (
            <img
              alt=""
              style={{ position: "absolute" }}
              height={200}
              src={filePath}
            />
          )}

          <IconButton
            aria-label="upload image"
            sx={{ backgroundColor: iconBgColor }}
            component="label"
          >
            <input
              hidden
              type="file"
              accept="image/*, video/mp4"
              onChange={(e) => setImageFile(e.target.files[0])}
            />
            <AddAPhoto />
          </IconButton>
        </Stack>
      </Paper>
    );
  };

  const renderButtons = () => {
    return (
      <Stack direction="row" sx={{ marginTop: 1 }} spacing={1}>
        <Button
          variant="outlined"
          disabled={isCreating || isUpdating}
          onClick={onClose}
        >
          {i18n.cancel}
        </Button>
        <LoadingButton
          variant="contained"
          loading={isCreating || isUpdating}
          type="submit"
        >
          {i18n.submit(content)}
        </LoadingButton>
      </Stack>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.title(content)}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogContent sx={{ paddingTop: 0 }}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            {renderFileUpload()}

            <TextField
              aria-label={i18n.inputTitle}
              defaultValue={content ? content.title : null}
              fullWidth
              helperText={i18n.inputTitleHelper}
              inputProps={{ maxLength: 280 }}
              label={i18n.inputTitle}
              maxRows={5}
              multiline={true}
              required={true}
              type={"text"}
              {...register(`title`)}
            />
            <TextField
              aria-label={i18n.inputDescription}
              defaultValue={content ? content.description : null}
              fullWidth
              helperText={i18n.inputHelper}
              label={i18n.inputDescription}
              multiline={true}
              type={"text"}
              {...register(`description`)}
            />
            <TextField
              aria-label={i18n.inputUrl}
              defaultValue={content ? content.url : null}
              fullWidth
              helperText={i18n.inputHelper}
              label={i18n.inputUrl}
              type={"text"}
              {...register(`url`)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>{renderButtons()}</DialogActions>
      </form>
    </Dialog>
  );
};

export default ContentCreateModal;

import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { ethers } from "ethers";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { createBotQuickstart } from "../../api/v1/bots";
import { Error, CheckCircle } from "@mui/icons-material";
import { useRaribleCollectionMeta } from "../../hooks/useRaribleCollectionMeta";
import ProgressLoader from "../utils/ProgressLoader";
import { RaribleCollectionCard } from "../cards/RaribleCollectionCard";
import { ETHEREUM, BASE, OPTIMISM } from "../../constants/blochainTypes";

const i18n = {
  blockchainLabel: "Blockchain",
  cancelButton: "Cancel",
  errorMessage: "Unable to configure bot behaviors at this time",
  modalTitle: "Builder DAO Bot Quickstart ⌐◨-◨",
  successMessage: "Bot behaviors added successfully",
  updateButton: "Create Bot",
};

const QUICKSTART_TYPE = "nouns";
const CHAINS = [ETHEREUM, BASE, OPTIMISM];

const BehaviorQuickstartBuilderModal = ({
  bot,
  parentQuery,
  onClose,
  open,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [blockchain, setBlockchain] = useState(ETHEREUM);
  const [nftContract, setNftContract] = useState();
  const [aucContract, setAucContract] = useState();
  const [govContract, setGovContract] = useState();
  const [treContract, setTreContract] = useState();

  const [isUpdating, setUpdating] = useState(false);

  const { data, isFetched } = useRaribleCollectionMeta({
    enabled: ethers.isAddress(nftContract) && blockchain === ETHEREUM,
    contractAddress: nftContract,
    blockchain: ETHEREUM,
  });

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  const builderExampleURL =
    "https://nouns.build/dao/0xdf9b7d26c8fc806b1ae6273684556761ff02d422/1?tab=smart-contracts";

  const isInputValid = () => {
    return (
      ethers.isAddress(nftContract) &&
      ethers.isAddress(aucContract) &&
      ethers.isAddress(govContract) &&
      ethers.isAddress(treContract)
    );
  };

  const renderAdornment = (value) => {
    if (value && value.length > 0) {
      if (ethers.isAddress(value)) {
        return <CheckCircle color="success" />;
      } else {
        return <Error color="error" />;
      }
    }
  };

  const onSubmit = async (data) => {
    setUpdating(true);

    createBotQuickstart(bot.id, { ...data, ...{ type: QUICKSTART_TYPE } })
      .then(() => {
        enqueueSnackbar(i18n.successMessage, { variant: "success" });

        queryClient.invalidateQueries(parentQuery).then(() => {
          onClose();
          setUpdating(false);
        });
      })
      .catch((err) => {
        setUpdating(false);

        const errorMsg =
          err && err.response.data.error
            ? err.response.data.error
            : i18n.errorMessage;
        enqueueSnackbar(errorMsg, { variant: "error" });
      });
  };

  const renderVerify = () => {
    if (ethers.isAddress(nftContract) && blockchain === ETHEREUM) {
      if (!isFetched) {
        return <ProgressLoader />;
      }

      if (data && data.data) {
        return (
          <Box width={"100%"}>
            <RaribleCollectionCard data={data.data} />
          </Box>
        );
      } else {
        return (
          <Alert variant="outlined" severity="error" sx={{ width: "100%" }}>
            Collection Not Found. Please verify contract address above.
          </Alert>
        );
      }
    }
  };

  const renderButtons = () => {
    return (
      <Stack direction="row" mt={1} spacing={1}>
        <Button variant="outlined" disabled={isUpdating} onClick={onClose}>
          {i18n.cancelButton}
        </Button>
        <LoadingButton
          variant="contained"
          loading={isUpdating}
          disabled={!isInputValid()}
          type="submit"
        >
          {i18n.updateButton}
        </LoadingButton>
      </Stack>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.modalTitle}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogContent>
          <Typography variant="body">
            <strong>Nouns Builder</strong>: Copy & paste the DAO's contract
            addresses from the Nouns Builder Smart Contracts tab (
            <Link target="_blank" href={builderExampleURL}>
              see example
            </Link>
            )
          </Typography>

          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={3}
            mt={4}
          >
            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="blockchain">{i18n.blockchainLabel}</InputLabel>
                <Select
                  fullWidth
                  {...register("blockchain")}
                  labelId="blockchain"
                  value={blockchain}
                  label={i18n.blockchainLabel}
                  onChange={(event) => {
                    setBlockchain(event.target.value);
                  }}
                >
                  {CHAINS.map((chain, idx) => (
                    <MenuItem key={idx} value={chain}>
                      {chain}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                {...register(`nft_contract_address`)}
                InputProps={{ endAdornment: renderAdornment(nftContract) }}
                aria-label={"NFT Contract Address"}
                fullWidth
                helperText={"Eg. 0xdf9b7d26c8fc806b1ae6273684556761ff02d422"}
                label={"NFT"}
                maxRows={1}
                multiline={false}
                onChange={(e) => setNftContract(e.target.value)}
                required={true}
                type={"text"}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                fullWidth
                {...register(`auc_contract_address`)}
                aria-label={"Auction House Contract"}
                helperText={"Eg. 0x658D3A1B6DaBcfbaa8b75cc182Bf33efefDC200d"}
                label={"Auction"}
                onChange={(e) => setAucContract(e.target.value)}
                InputProps={{ endAdornment: renderAdornment(aucContract) }}
                maxRows={1}
                multiline={false}
                required={true}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                fullWidth
                {...register(`gov_contract_address`)}
                aria-label={"Governor Contract Address"}
                helperText={"Eg. 0xe3F8d5488C69d18ABda42FCA10c177d7C19e8B1a"}
                label={"Governor"}
                maxRows={1}
                multiline={false}
                onChange={(e) => setGovContract(e.target.value)}
                InputProps={{ endAdornment: renderAdornment(govContract) }}
                required={true}
                type={"text"}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <TextField
                fullWidth
                {...register(`treasury_contract_address`)}
                aria-label={"Treasury Contract Address"}
                helperText={"Eg. 0xDC9b96Ea4966d063Dd5c8dbaf08fe59062091B6D"}
                label={"Treasury"}
                maxRows={1}
                multiline={false}
                onChange={(e) => setTreContract(e.target.value)}
                InputProps={{ endAdornment: renderAdornment(treContract) }}
                required={true}
                type={"text"}
              />
            </Box>
            {renderVerify()}
          </Stack>
        </DialogContent>

        <DialogActions>{renderButtons()}</DialogActions>
      </form>
    </Dialog>
  );
};
export default BehaviorQuickstartBuilderModal;

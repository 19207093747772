import { ArrowForward } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { useBehaviorTemplateAdd } from "../../hooks/useBehaviorTemplateAdd";
import { useBotBehaviorCreate } from "../../hooks/useBotBehaviorCreate";
import { BotSelect } from "../../pages/collection/components/BotSelect";

const i18n = {
  cancel: "nevermind",
  helper:
    "You will be able to update the sharing schedule and other parameters later.",
  submit: "save",
  title: "Start Sharing",
  titleSuccess: "All Set!",
};

export const BehaviorQuickstartModal = ({
  onClose,
  open,
  template,
  meta = null,
}) => {
  const navigate = useNavigate();
  const { hasUser, showLoginModal, showRegisterModal } =
    useContext(UserContext);
  const { name, definition } = template;
  const [bot, setBot] = useState(null);

  const {
    call: addBehavior,
    reset,
    isFetching: isAddingBehavior,
    isFetched: isBehaviorAdded,
  } = useBotBehaviorCreate();

  const {
    call: addTemplate,
    isFetching: isAddingTemplate,
    isFetched: isTemplateAdded,
  } = useBehaviorTemplateAdd();

  const isSaving = isAddingTemplate || isAddingBehavior;
  const isSaved = isTemplateAdded || isBehaviorAdded;

  const getMetaFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const result = {};
    const WHITELIST = ["text_template"];

    for (let param of params) {
      if (WHITELIST.includes(param[0])) {
        result[param[0]] = param[1];
      }
    }
    return result;
  };

  const createBehavior = () => {
    if (template.id) {
      addTemplate(template.id, {
        bot_id: bot.id,
        meta: meta || getMetaFromUrl(),
      });
    } else {
      addBehavior(bot.id, definition);
    }
  };

  const renderCallToAction = () => {
    return (
      <Typography variant="body1">
        {" "}
        Where would you like to share{" "}
        <span style={{ fontWeight: "bold", color: green[400] }}>
          {template.name}
        </span>
        {" ?"}
      </Typography>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setTimeout(() => {
          reset();
        }, 100);
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="dialog-title">
        {isSaved ? i18n.titleSuccess : `Share: ${name}`}
      </DialogTitle>

      {isSaved && (
        <DialogContent>
          You can now review the{" "}
          <Link
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(`/bots/${bot.id}/behaviors`)}
          >
            Sharing Settings
          </Link>{" "}
          and make any additional changes.
        </DialogContent>
      )}

      {!isSaved && (
        <>
          <DialogContent>
            <Typography sx={{ mb: 1 }}></Typography>
            <Stack direction={"column"} spacing={3}>
              {renderCallToAction()}

              {hasUser() ? (
                <BotSelect onSelect={(value) => setBot(value)} bot={bot} />
              ) : (
                <Alert variant="outlined" severity="info">
                  <AlertTitle>BotFrens Account Required</AlertTitle>
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() => showLoginModal(true)}
                  >
                    Log in
                  </Link>{" "}
                  to an existing account or{" "}
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() => showRegisterModal(true)}
                  >
                    register
                  </Link>{" "}
                  a new one.
                </Alert>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              aria-label={i18n.cancel}
              disabled={isSaving}
              onClick={onClose}
              variant="outlined"
            >
              {i18n.cancel}
            </Button>
            <LoadingButton
              aria-label={i18n.submit}
              disabled={!bot || !hasUser()}
              loading={isSaving}
              onClick={() => createBehavior()}
              variant="contained"
            >
              <ArrowForward />
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

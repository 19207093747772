import { SvgIcon } from "@mui/material";

export const Salesbots = (props) => {
  const defaultProps = {
    width: 24,
    height: 24,
    ...props,
  };

  return (
    <SvgIcon sx={defaultProps}>
      <path
        opacity="0.4"
        d="M13.2 16.64H10.8V15.5138H13.2V16.64ZM16.8 16.64H14.4V15.5138H16.8V16.64ZM24 11.0092V15.5138C24.0004 15.8126 23.8741 16.0993 23.6489 16.3106C23.4238 16.5218 23.1184 16.6404 22.8 16.64H21.6V9.88307H22.8C23.1184 9.88272 23.4238 10.0012 23.6489 10.2125C23.8741 10.4238 24.0004 10.7104 24 11.0092ZM9.6 16.64H7.2V15.5138H9.6V16.64ZM1.2 9.88307H2.4V16.64H1.2C0.881625 16.6404 0.576178 16.5218 0.351052 16.3106C0.125925 16.0993 -0.000380517 15.8126 7.59959e-07 15.5138V11.0092C-0.000380517 10.7104 0.125925 10.4238 0.351052 10.2125C0.576178 10.0012 0.881625 9.88272 1.2 9.88307ZM12 2C12.6627 2 13.2 2.5042 13.2 3.12615V5.37846H10.8V3.12615C10.8 2.5042 11.3373 2 12 2Z"
        fill="#00FFE3"
      />
      <path
        d="M6.32066 5.36H17.1261C17.9223 5.35976 18.6859 5.6563 19.2488 6.18434C19.8118 6.71238 20.1279 7.42862 20.1277 8.17539V17.7477C20.1263 18.9911 19.0521 19.9988 17.7264 20H5.72036C4.39475 19.9988 3.32045 18.9911 3.31914 17.7477V8.17539C3.31888 7.42862 3.63503 6.71238 4.19798 6.18434C4.76093 5.6563 5.52453 5.35976 6.32066 5.36ZM14.1246 16.6215H16.5258V15.4954H14.1246V16.6215ZM15.3252 12.3985C16.1541 12.3985 16.826 11.7682 16.826 10.9908C16.826 10.2133 16.1541 9.58308 15.3252 9.58308C14.4964 9.58308 13.8245 10.2133 13.8245 10.9908C13.8245 11.3641 13.9825 11.7222 14.264 11.9862C14.5454 12.2502 14.9272 12.3985 15.3252 12.3985ZM10.5228 16.6215H12.924V15.4954H10.5228V16.6215ZM6.92097 16.6215H9.32218V15.4954H6.92097V16.6215ZM8.12158 12.3985C8.95042 12.3985 9.62234 11.7682 9.62234 10.9908C9.62234 10.2133 8.95042 9.58308 8.12158 9.58308C7.29273 9.58308 6.62082 10.2133 6.62082 10.9908C6.62082 11.3641 6.77889 11.7222 7.06035 11.9862C7.3418 12.2502 7.72354 12.3985 8.12158 12.3985Z"
        fill="#00FFE3"
        fillOpacity="0.8"
      />
    </SvgIcon>
  );
};

import {
  Card,
  CardContent,
  Container,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Layout from "../components/Layout";
import { Salesbots } from "../assets/icons/Salesbots";
import { grey } from "@mui/material/colors";

const SalesbotsLanding = () => {
  const headerColor = "#1cc2b0";
  const textColor = grey[400];

  return (
    <Layout>
      <Container maxWidth="lg">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          mb={10}
          mt={8}
        >
          <Salesbots width={80} height={80} />
          <Typography variant="h2" color={"#00FFE3"}>
            Welcome Salesbots.xyz Frens
          </Typography>
        </Stack>

        <Card>
          <CardContent>
            <Typography variant="h6">TL;DR for SalesBots Customers</Typography>
            <Typography color={textColor}>
              <ul>
                <li>
                  BotFrens acquired Salesbots.xyz and will power your bots going
                  forward.
                </li>
                <li>Sign up for a BotFrens account to migrate your bot.</li>
                <li>
                  SalesBots passes purchased prior to March 29, 2023 will be
                  converted to a BotFrens Lifetime Premium subscription.
                </li>
              </ul>
            </Typography>

            <Typography variant={"h4"} mb={5} mt={5}>
              FAQ
            </Typography>
            <Stack spacing={4}>
              <Stack>
                <Typography color={headerColor} variant={"h6"}>
                  Why do I need to migrate my bot to BotFrens?
                </Typography>
                <Typography color={textColor}>
                  BotFrens uses a different technology and needs your permission
                  to post your content. The migration process only takes a few
                  clicks. To get started, sign up for an account and connect
                  your bot’s Twitter account. That’s it!
                </Typography>
              </Stack>
              <Stack>
                <Typography color={headerColor} variant={"h6"}>
                  What happens to my SalesBots Pass?
                </Typography>
                <Typography color={textColor}>
                  Your SalesBots Pass can be redeemed for a BotFrens Lifetime
                  Premium subscription after it is burned. Please join the{" "}
                  <Link href="https://discord.gg/EqZkPb77TV" target="_blank">
                    BotFrens Discord
                  </Link>{" "}
                  for detailed instructions.
                </Typography>
              </Stack>

              <Stack>
                <Typography color={headerColor} variant={"h6"}>
                  What happens if I don’t migrate my bot?
                </Typography>
                <Typography color={textColor}>
                  Your SalesBots.xyz bot will stop working in 60 days. But don’t
                  worry, you can always start fresh on BotFrens.
                </Typography>
              </Stack>

              <Stack>
                <Typography color={headerColor} variant={"h6"}>
                  Will my bot work differently?
                </Typography>
                <Typography color={textColor}>
                  Yes, but better! In addition to sales from all major
                  marketplaces, your bot can now share listings, stats, and even
                  say gm! Oh, and your bot is fully customizable so you can give
                  it the voice you want. Check out this{" "}
                  <Link href="https://youtu.be/v_7wIQqIr1I" target="_blank">
                    demo video
                  </Link>{" "}
                  to get a preview.
                </Typography>
              </Stack>

              <Stack>
                <Typography color={headerColor} variant={"h6"}>
                  Was my email or any other personal information transferred to
                  BotFrens?{" "}
                </Typography>
                <Typography color={textColor}>
                  Nope, hence the need to migrate your bot and sign up for a new
                  account. The only information we have is your SalesBot’s
                  Twitter handle and the associated NFT collection address.
                </Typography>
              </Stack>

              <Typography color={textColor}>
                For more questions, please reach out on 👉{" "}
                <Link href="https://discord.gg/EqZkPb77TV" target="_blank">
                  Discord
                </Link>{" "}
                👉{" "}
                <Link href="https://twitter.com/botfrens" target="_blank">
                  Twitter
                </Link>{" "}
                ✉️ support@botfrens.com
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Layout>
  );
};

export default SalesbotsLanding;

import React, { useContext, useState } from "react";

import {
  Badge,
  Button,
  Card,
  CardHeader,
  IconButton,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { AddCircle, Check, RemoveCircle } from "@mui/icons-material";
import { botIconFactory } from "../icons/BotIcons";
import { useGroupDeleteBot } from "../../hooks/useGroupDeleteBot";
import { UserContext } from "../../contexts/UserContext";
import { botTitle, botAvatar, botAutopilot } from "../utils/botUtils";
import { useGroupAddBot } from "../../hooks/useGroupAddBot";
import ProgressLoader from "../utils/ProgressLoader";
import { truncate } from "../utils/utils";

export const GROUP_BOT_ACTION_ADD = "add";
export const GROUP_BOT_ACTION_REMOVE = "remove";

const i18n = {
  yesButton: "yes",
  noButton: "no",
  deleteLabel: "Remove bot?",
  addLabel: "Add to Group",
};

export const GroupBotCard = ({ group, bot, action }) => {
  const [popperOpen, setPopperOpen] = useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);

  const { user: activeUser, hasUser } = useContext(UserContext);
  const { call: deleteBotFromGroup, isFetching: isDeleting } =
    useGroupDeleteBot();
  const {
    call: addBotToGroup,
    isFetching: iaAdding,
    isFetched: isAdded,
  } = useGroupAddBot();

  const handleDelete = () => {
    setPopperOpen(false);
    deleteBotFromGroup(bot.id, group.id);
  };

  const handleAdd = () => {
    addBotToGroup(bot.id, group.id);
  };

  const handleMenuOpen = (event) => {
    setPopperAnchorEl(event.currentTarget);
    setPopperOpen((prevState) => !prevState);
  };

  const isEditable = () => {
    return hasUser() && activeUser.id === group.user.id;
  };

  const renderDeletePopper = () => {
    return (
      <Popper open={popperOpen} anchorEl={popperAnchorEl}>
        <Paper sx={{ padding: 1.5 }}>
          <Typography variant="body1">{i18n.deleteLabel}</Typography>
          <Stack direction="row" spacing={1} sx={{ marginTop: 1 }}>
            <Button
              aria-label={i18n.noButton}
              onClick={handleMenuOpen}
              size="xs"
              sx={{ height: 25 }}
              variant="outlined"
            >
              {i18n.noButton}
            </Button>
            <Button
              aria-label={i18n.noButton}
              color="error"
              onClick={handleDelete}
              size="xs"
              sx={{ height: 25 }}
              variant="contained"
            >
              {i18n.yesButton}
            </Button>
          </Stack>
        </Paper>
      </Popper>
    );
  };

  return (
    <Card
      sx={{
        mb: action === GROUP_BOT_ACTION_REMOVE ? 2 : 1,
        mr: action === GROUP_BOT_ACTION_REMOVE ? 2 : 1,
        width: action === GROUP_BOT_ACTION_REMOVE ? 290 : 276,
      }}
      raised={action === GROUP_BOT_ACTION_REMOVE}
    >
      {renderDeletePopper()}
      <CardHeader
        action={
          <>
            {isEditable() && action === GROUP_BOT_ACTION_ADD && (
              <Tooltip title={i18n.addLabel}>
                <IconButton
                  aria-label={i18n.addLabel}
                  disabled={iaAdding || isAdded}
                  onClick={handleAdd}
                >
                  {iaAdding ? (
                    <ProgressLoader />
                  ) : isAdded ? (
                    <Check color="success" />
                  ) : (
                    <AddCircle color="primary" />
                  )}
                </IconButton>
              </Tooltip>
            )}

            {isEditable() && action === GROUP_BOT_ACTION_REMOVE && (
              <IconButton
                aria-label={i18n.deleteConfirm}
                disabled={isDeleting}
                onClick={handleMenuOpen}
              >
                {isDeleting ? (
                  <ProgressLoader />
                ) : (
                  <RemoveCircle color="primary" />
                )}
              </IconButton>
            )}
          </>
        }
        avatar={
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={botIconFactory(bot.type, "small")}
          >
            {botAvatar(bot.authentication)}
          </Badge>
        }
        title={truncate(botTitle(bot.authentication, bot.type), 25)}
        subheader={botAutopilot(bot.authentication, bot.autopilot)}
      />
    </Card>
  );
};

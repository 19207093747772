import React from "react";
import { Card, CardHeader, IconButton } from "@mui/material";
import { truncate } from "../utils/utils";
import { groupAvatar, groupBotsCount } from "../utils/groupUtils";
import { Group } from "../../types/types";
import AddCircle from "@mui/icons-material/AddCircle";

interface Props {
  group: Group;
  onSelect: (id: number) => void;
}

export const GroupMenuCard = ({ group, onSelect }: Props) => {
  const { id, avatars, name } = group;

  return (
    <Card sx={{ width: "100%" }} variant="outlined">
      <CardHeader
        avatar={groupAvatar(avatars)}
        title={truncate(name, 25)}
        subheader={groupBotsCount(group.bot_count)}
        action={
          <IconButton
            color="primary"
            size="small"
            sx={{ mt: 1 }}
            onClick={() => onSelect(id)}
          >
            <AddCircle fontSize="small" />
          </IconButton>
        }
      />
    </Card>
  );
};

import React, { useContext, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import moment from "moment";
import { Delete, MoreVert, Send } from "@mui/icons-material";
import ProgressLoader from "../utils/ProgressLoader";
import { red } from "@mui/material/colors";
import { behaviorIconFactory } from "../icons/BehaviorIcons";
import ContentEmbedCard from "./ContentEmbedCard";
import { UserContext } from "../../contexts/UserContext";

const i18n = {
  deleteButton: "Delete",
  shareButton: "Share",
};

const QueueCard = ({ bot, isDeleting, isSharing, onDelete, onShare, post }) => {
  const { content, definition, meta, publish_at } = post;

  const [anchorEl, setAnchorEl] = useState(null);
  const { blockchain } = meta;
  const isMenuOpen = Boolean(anchorEl);

  const { hasUser } = useContext(UserContext);
  const belongsToUser = () => {
    return hasUser() && bot.is_owner;
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderContent = () => {
    return (
      <CardContent>
        {content ? (
          <ContentEmbedCard {...content} />
        ) : (
          <Typography>{definition.description}</Typography>
        )}
      </CardContent>
    );
  };

  const renderMenu = () => {
    if (!belongsToUser()) {
      return null;
    }

    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            onShare();
          }}
        >
          <ListItemIcon>
            <Send />
          </ListItemIcon>
          {i18n.shareButton}
        </MenuItem>

        <MenuItem
          sx={{ color: red[500] }}
          onClick={() => {
            handleMenuClose();
            onDelete();
          }}
        >
          <ListItemIcon>
            <Delete sx={{ color: red[500] }} />
          </ListItemIcon>
          {i18n.deleteButton}
        </MenuItem>
      </Menu>
    );
  };

  return (
    <Card sx={{ marginRight: 1, marginBottom: 1, width: 320 }} raised>
      <CardHeader
        action={
          belongsToUser() ? (
            <IconButton aria-haspopup="true" onClick={handleMenuOpen}>
              {isDeleting || isSharing ? <ProgressLoader /> : <MoreVert />}
            </IconButton>
          ) : null
        }
        avatar={behaviorIconFactory(definition.type, blockchain)}
        title={definition.title}
        subheader={`Scheduled for ${
          moment().endOf("day").diff(publish_at, "minutes") > 0
            ? moment(publish_at).format("h:mm a")
            : moment(publish_at).format("MMM Do")
        }`}
      />
      {renderContent()}
      {renderMenu()}
    </Card>
  );
};

export default QueueCard;

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { AccessTime, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { parseExpression } from "cron-parser";
import moment from "moment";
import { editBotBehavior, unmuteBotBehavior } from "../../api/v1/bots";
import { behaviorSchedule } from "../utils/cronUtils";
import { CollectionSelectModal } from "./CollectionSelectModal";
import { CollectionEmbedCard } from "../cards/CollectionEmbedCard";
import { useCollection } from "../../hooks/useCollection";
import { GroupEmbedCard } from "../cards/GroupEmbedCard";
import { useGroup } from "../../hooks/useGroup";
import { GroupSelectModal } from "./GroupSelectModal";

const i18n = {
  behaviorStatus: (enabled) => (enabled ? "Active" : "Paused"),
  cronDayLabel: "Day of Week",
  cronTimeLabel: "Time of Day",
  disabledErrorDismiss: "Dismiss",
  disabledErrorInfo:
    "Please dismiss this notification once you've taken steps to resolve the error above.",
  errorMessage: "Unable to update behavior at this time",
  inputLabel: "OpenSea Collection Slug",
  modalTitle: "Edit Behavior",
  postsSliderLabel: (count) => `post${count === 1 ? "" : "s"}`,
  daysSliderLabel: (count) =>
    `every ${
      count === 0 ? "day" : count === 1 ? "other day" : `${count} days`
    }`,
  scheduleLabel: (bool) => `${bool ? "Manual Schedule" : "Automated Schedule"}`,
  successMessage: "Behavior updated",
  updateButtonLabel: "Update",
};

const COLLECTION_TYPES = ["ItemShare", "CollectionPromo"];
const GROUP_TYPES = ["RetweetOtherBot", "RecommendBotToFollow"];

const TIME_OPTIONS = [
  { title: "Every Day", value: "*" },
  { title: "Monday", value: "MON" },
  { title: "Tuesday", value: "TUE" },
  { title: "Wednesday", value: "WED" },
  { title: "Thursday", value: "THU" },
  { title: "Friday", value: "FRI" },
  { title: "Saturday", value: "SAT" },
  { title: "Sunday", value: "SUN" },
];

export const BehaviorEditModal = ({
  bot,
  behavior: initBehavior,
  parentQuery,
  onClose,
  open,
}) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  const [isUpdating, setUpdating] = useState(false);
  const [isEnabling, setEnabling] = useState(false);

  // Rarible
  const [blockchain, setBlockchain] = useState(
    initBehavior.meta["blockchain"] || "",
  );

  const [collectionId, setCollectionId] = useState(
    initBehavior.meta["collection_id"],
  );
  const { data: fetchedCollection } = useCollection(collectionId);
  const [collectionSelect, setCollectionSelect] = useState(false);

  const [groupId, setGroupId] = useState(initBehavior.meta["group_id"]);
  const { data: fetchedGroup } = useGroup(groupId);
  const [groupSelect, setGroupSelect] = useState(false);

  const [per_day, setPostsPerDay] = useState(initBehavior.per_day);
  const [days_between_posts, setDaysBetweenPosts] = useState(
    initBehavior.days_between_posts,
  );

  const [enabled, setEnabled] = useState(Boolean(initBehavior.enabled));
  const [behavior, setBehavior] = useState(initBehavior);

  const now = moment();
  const [tempScheduleTime, setTempScheduleTime] = useState();
  const [customSchedule, setCustomSchedule] = useState(
    initBehavior.custom_schedule || `${now.minute()} ${now.hour()} * * *`,
  );

  const isScheduleConfig = initBehavior.definition.schedule_config;
  const [isCronSchedule, setCronSchedule] = useState(
    Boolean(isScheduleConfig && initBehavior.custom_schedule),
  );

  const hasCollectionSelect = COLLECTION_TYPES.includes(
    initBehavior.shareable_class,
  );
  const hasGroupSelect = GROUP_TYPES.includes(initBehavior.shareable_class);
  const hasError = !behavior.enabled && behavior.error;

  const onSubmit = async (data) => {
    setUpdating(true);

    const payload = {
      days_between_posts,
      enabled,
      per_day,
      ...data,
    };

    // Meta container
    const meta = payload.meta || {};

    if (isScheduleConfig) {
      if (isCronSchedule) {
        // Reset manual scheduling
        payload.custom_schedule = customSchedule;
        payload.days_between_posts = null;
        payload.per_day = null;
      } else {
        // Reset custom schedule
        payload.custom_schedule = null;
      }
    }

    if (collectionId) {
      // Append collection id to the meta
      Object.assign(payload, {
        meta: { collection_id: collectionId, ...meta },
      });
    }

    if (groupId) {
      // Append group id to the meta
      Object.assign(payload, { meta: { group_id: groupId, ...meta } });
    }

    editBotBehavior(bot.id, behavior.id, { ...payload })
      .then((response) => {
        setBehavior(response.data);

        queryClient.invalidateQueries(parentQuery).then(() => {
          setUpdating(false);
          enqueueSnackbar(i18n.successMessage, { variant: "success" });
        });
      })
      .catch((err) => {
        setUpdating(false);

        const errorMsg =
          err && err.response.data.error
            ? err.response.data.error
            : i18n.errorMessage;
        enqueueSnackbar(errorMsg, { variant: "error" });
      });
  };

  const onUnmute = () => {
    setEnabling(true);

    unmuteBotBehavior(bot.id, behavior.id)
      .then((response) => {
        setBehavior(response.data);
        setEnabled(true);

        queryClient.invalidateQueries(parentQuery).then(() => {
          setEnabling(false);
          enqueueSnackbar(i18n.successMessage, { variant: "success" });
        });
      })
      .catch((err) => {
        setEnabling(false);
        const errorMsg =
          err && err.response.data.error
            ? err.response.data.error
            : i18n.errorMessage;
        enqueueSnackbar(errorMsg, { variant: "error" });
      });
  };

  const onDisable = () => {
    setEnabled(!enabled);
  };

  const onScheduleChange = () => {
    setCronSchedule(!isCronSchedule);
  };

  const fieldFactory = (field, idx) => {
    // Don't render input fields for select boxes
    if (field.name === "collection_id" || field.name === "group_id") {
      return;
    }

    const inputProps = field.inputProps ? JSON.parse(field.inputProps) : {};

    if (field.type === "select") {
      const inputLabelId = `select-${field.name}`;

      //TODO: Fix hard-coded reference to blockchain
      return (
        <FormControl key={idx} fullWidth>
          <InputLabel id={inputLabelId}>{field.label}</InputLabel>
          <Select
            {...register(`meta[${field.name}]`)}
            labelId={inputLabelId}
            value={blockchain}
            label={field.label}
            onChange={(event) => {
              setBlockchain(event.target.value);
            }}
          >
            {field.items.map((chain, idx) => (
              <MenuItem key={idx} value={chain}>
                {chain}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    if (field.type === "checkbox") {
      return (
        <FormGroup key={idx}>
          <FormControlLabel
            sx={{ mt: "-10px" }}
            control={
              <Checkbox
                {...register(`meta[${field.name}]`)}
                disabled={!enabled}
                defaultChecked={
                  initBehavior.meta.hasOwnProperty(field.name)
                    ? initBehavior.meta[field.name]
                    : field.defaultValue
                }
              />
            }
            label={field.label}
          />
          {field.helperText && (
            <FormHelperText>{field.helperText}</FormHelperText>
          )}
        </FormGroup>
      );
    }

    return (
      <Box sx={{ width: "100%" }} key={idx}>
        <TextField
          fullWidth
          {...register(`meta[${field.name}]`)}
          aria-label={field.label}
          disabled={!enabled}
          defaultValue={initBehavior.meta[field.name]}
          helperText={field.helperText}
          inputProps={inputProps}
          label={field.label}
          maxRows={5}
          multiline={field.multiline}
          required={field.required}
          type={field.type}
        />
        {field.helperExample && (
          <FormHelperText mt={1} variant="outlined">
            {field.helperExample}
          </FormHelperText>
        )}
      </Box>
    );
  };

  const renderButtons = () => {
    return (
      <Stack direction="row" mt={1} spacing={1}>
        <Button variant="outlined" disabled={isUpdating} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" loading={isUpdating} type="submit">
          {i18n.updateButtonLabel}
        </LoadingButton>
      </Stack>
    );
  };

  const renderSliders = () => {
    return (
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Box sx={{ width: "100%" }}>
          <Typography variant="body2" id="posts-slider">
            {per_day} {i18n.postsSliderLabel(per_day)}
          </Typography>
          <Slider
            aria-label={i18n.postsSliderLabel(per_day)}
            disabled={!enabled}
            max={50}
            min={0}
            onChange={(event, value) => setPostsPerDay(value)}
            step={1}
            value={per_day}
          />
        </Box>

        <Box sx={{ width: "100%" }}>
          <Typography variant="body2" id="days-slider">
            {i18n.daysSliderLabel(days_between_posts)}
          </Typography>
          <Slider
            aria-label={i18n.daysSliderLabel(days_between_posts)}
            disabled={!enabled}
            max={90}
            min={0}
            onChange={(event, value) => setDaysBetweenPosts(value)}
            step={1}
            value={days_between_posts}
          />
        </Box>
      </Stack>
    );
  };

  const renderCustomCron = () => {
    const { hour, minute } = parseExpression(customSchedule).fields;

    const dayOfWeek =
      parseExpression(customSchedule).fields.dayOfWeek.length > 1
        ? "*"
        : TIME_OPTIONS[parseExpression(customSchedule).fields.dayOfWeek[0]]
            .value;

    const utcOffset = moment().utcOffset();
    const timeValue =
      tempScheduleTime ||
      moment().hour(hour).minute(minute).add(utcOffset, "minutes");

    return (
      <Box>
        <Stack direction="row" spacing={1}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="cron-day-label">{i18n.cronDayLabel}</InputLabel>
            <Select
              disabled={!enabled}
              labelId="cron-day-label"
              value={dayOfWeek}
              label={i18n.cronDayLabel}
              onChange={(event) => {
                setCustomSchedule(
                  `${minute[0]} ${hour[0]} * * ${event.target.value}`,
                );
              }}
            >
              {TIME_OPTIONS.map((val, idx) => (
                <MenuItem key={idx} value={val.value}>
                  {val.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                label={i18n.cronTimeLabel}
                value={timeValue}
                disabled={!enabled}
                onChange={(newMoment) => {
                  setTempScheduleTime(newMoment);
                  if (newMoment.isValid()) {
                    setCustomSchedule(
                      `${newMoment.minute()} ${newMoment
                        .clone()
                        .subtract(utcOffset, "minutes")
                        .hour()} * * ${dayOfWeek}`,
                    );
                  }
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        </Stack>
      </Box>
    );
  };

  const renderError = () => {
    return (
      <Alert
        severity="error"
        mt={2}
        variant="outlined"
        action={
          <LoadingButton
            color="inherit"
            size="small"
            aria-label={i18n.disabledErrorDismiss}
            loading={isEnabling}
            onClick={onUnmute}
          >
            {" "}
            <Close />
          </LoadingButton>
        }
      >
        <AlertTitle>{behavior.error}</AlertTitle>
        {i18n.disabledErrorInfo}
      </Alert>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogContent>
          <Box>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">{behavior.name}</Typography>
              <FormControlLabel
                control={<Switch checked={enabled} onChange={onDisable} />}
                label={i18n.behaviorStatus(enabled)}
              />
            </Stack>
            <Stack
              mb={6}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0.5}
            >
              <AccessTime fontSize="small" />
              <Typography variant="body">
                {behaviorSchedule(behavior)}
              </Typography>
            </Stack>
            {hasError && renderError()}
          </Box>

          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            {hasCollectionSelect && fetchedCollection && (
              <CollectionEmbedCard
                collection={fetchedCollection.data}
                onClick={() => setCollectionSelect(true)}
              />
            )}

            {hasGroupSelect && fetchedGroup && (
              <GroupEmbedCard
                group={fetchedGroup.data}
                onClick={() => setGroupSelect(true)}
              />
            )}

            {initBehavior.definition.fields &&
              initBehavior.definition.fields.map((field, idx) => {
                return fieldFactory(field, idx);
              })}
          </Stack>

          {isScheduleConfig && (
            <Box mt={2} mb={2}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!enabled}
                    checked={isCronSchedule}
                    onChange={onScheduleChange}
                  />
                }
                label={i18n.scheduleLabel(isCronSchedule)}
              />
              <Box
                sx={{
                  border: "1px solid",
                  borderColor: theme.palette.divider,
                  borderRadius: 1,
                  padding: 2,
                }}
              >
                {isCronSchedule ? renderCustomCron() : renderSliders()}
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>{renderButtons()}</DialogActions>
      </form>

      {groupSelect && (
        <GroupSelectModal
          open={true}
          onSelect={(id) => setGroupId(id)}
          onClose={() => setGroupSelect(false)}
          fullWidth
          maxWidth="md"
        />
      )}

      {collectionSelect && (
        <CollectionSelectModal
          open={true}
          onSelect={(id) => setCollectionId(id)}
          onClose={() => setCollectionSelect(false)}
          fullWidth
          maxWidth="md"
        />
      )}
    </Dialog>
  );
};

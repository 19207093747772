import { ArrowForward, Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Bluesky } from "../../assets/icons/Bluesky";
import { BLUESKY } from "../../constants/providerTypes";
import { useAuthenticationCreate } from "../../hooks/useAuthenticationCreate";
import { useBotCreate } from "../../hooks/useBotCreate";
import { botCreatePayload } from "../utils/botUtils";

const i18n = {
  cancel: "Nevermind",
  connect: "Connect",
  connectButton: "Connect Bluesky",
  password: "App Password *",
  passwordHelper:
    "You can generate a temporary app password for BotFrens by going to Settings > App Passwords on the Bluesky App. Just make sure not to use your master password.",
  username: "Username",
  usernamePlaceholder: "@botfrens.bsky.social",
};

export const BlueskyConnect = ({
  onClick,
  onConnected,
  label = i18n.connectButton,
}) => {
  const [isInputOpen, setInputOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  const isAddingBot = useRef(false); // prevents useEffect from running twice

  const {
    call: createBot,
    data: bot,
    isError: isErrorBot,
    isFetched: isFetchedBot,
    isFetching: isFetchingBot,
  } = useBotCreate();

  const {
    call: createAuth,
    data: auth,
    isError: isErrorAuth,
    isFetched: isFetchedAuth,
    isFetching: isFetchingAuth,
  } = useAuthenticationCreate();

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  const hasAuth = auth && isFetchedAuth && !isFetchingAuth && !isErrorAuth;
  const hasBot = bot && isFetchedBot && !isFetchingBot && !isErrorBot;
  const disableButtons =
    isFetchingAuth || (isFetchedAuth && !isFetchingBot) || isFetchingBot;

  useEffect(() => {
    // Creates a new bot when auth is connected
    // Consider moving bot creation to the back-end for more stability.
    if (hasAuth && !hasBot && !isAddingBot.current) {
      isAddingBot.current = true;
      createBot(botCreatePayload(auth));
    }
    // Closes the modal once everything is done
    if (hasBot && onConnected) {
      onConnected();
    }
  }, [auth, hasBot, hasAuth, onConnected, createBot]);

  const onSubmit = async (data) => {
    createAuth({ provider: BLUESKY, ...data });
  };

  const toggleShowPassword = () => setShowPassword((show) => !show);
  const toggleInput = () => setInputOpen((prev) => !prev);

  const renderInputModal = () => {
    return (
      <Dialog
        open={isInputOpen}
        onClose={() => setInputOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{label}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogContent>
            <TextField
              {...register("username")}
              label={i18n.username}
              placeholder={i18n.usernamePlaceholder}
              sx={{ width: "100%", mb: 2 }}
              variant="outlined"
              required
            />

            <FormControl sx={{ width: "100%" }} variant="outlined">
              <InputLabel htmlFor="recover-phrase">{i18n.password}</InputLabel>
              <OutlinedInput
                {...register("secret")}
                aria-label={i18n.password}
                id="recover-phrase"
                label={i18n.password}
                required={true}
                type={showPassword ? "password" : "text"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      onMouseDown={toggleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Typography
              mt={1}
              sx={{ paddingRight: 1 }}
              color={grey[500]}
              variant="body2"
            >
              {i18n.passwordHelper}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button disabled={disableButtons} onClick={toggleInput}>
              {i18n.cancel}
            </Button>
            <LoadingButton loading={disableButtons} type="submit">
              <ArrowForward />
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  const onConnect = () => {
    onClick();
    setInputOpen(true);
  };

  return (
    <>
      <LoadingButton
        sx={{ background: "linear-gradient(45deg, #8edaff 30%, #33a3ff 90%)" }}
        variant="contained"
        onClick={() => onConnect()}
        loading={disableButtons}
        startIcon={<Bluesky width={20} height={20} inheritViewBox />}
      >
        {label}
      </LoadingButton>
      {isInputOpen && renderInputModal()}
    </>
  );
};

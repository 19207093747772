import { Email, LinkedIn, PlayCircle, Twitter } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { blue, grey } from "@mui/material/colors";
import React, { useContext } from "react";
import { FeaturedBots } from "../assets/data/FeaturedBots";
import { FeaturedPress, FeaturedTeam } from "../assets/data/FeturedTeam";
import { Bluesky } from "../assets/icons/Bluesky";
import { Discord } from "../assets/icons/Discord";
import { Farcaster } from "../assets/icons/Farcaster";
import { UserContext } from "../contexts/UserContext";

const i18n = {
  demoTitle: "Trusted by WEB3's biggest nerds ⌐◨-◨",
  mainDescrOne:
    "Build your bot to share onchain activity or custom content. Give it your unique voice :)",
  mainTitle: "No-Code Social Bots for Artists, DAOs, and Marketplaces",
  newsTitle: "News and Press",
  teamTitle: "Team of Humans and Robots",
};

const Splash = () => {
  const { hasUser, showRegisterModal } = useContext(UserContext);

  const renderExamples = () => {
    return (
      <Grid item md={12} xs={12} mt={8}>
        <Typography
          variant="h5"
          mb={9}
          sx={{ color: grey[200], textAlign: "center" }}
        >
          {i18n.demoTitle}
        </Typography>

        <Box display="flex" flexWrap="wrap" width="100%">
          {FeaturedBots.map(({ avatar, title, url, username }, idx) => {
            return (
              <Tooltip title={title} key={idx} placement="bottom">
                <Card
                  sx={{
                    cursor: "pointer",
                    mr: 1.2,
                    mb: 1,
                    width: 105,
                    height: 105,
                    display: {
                      xs: idx >= 10 ? "none" : "block",
                      md: "block",
                      lg: "block",
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    image={avatar}
                    alt={username}
                    onClick={() => window.open(url, "_blank")}
                  />
                </Card>
              </Tooltip>
            );
          })}
        </Box>
      </Grid>
    );
  };

  const renderTeam = () => {
    return (
      <Box my={10}>
        <Typography
          variant="h5"
          mb={9}
          sx={{ color: grey[200], textAlign: "center" }}
        >
          {i18n.teamTitle}
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={2}>
          {FeaturedTeam.map(
            ({ avatar, email, linkedin, name, title, twitter }, idx) => {
              return (
                <Card
                  sx={{
                    padding: 2,
                    width: 220,
                    display: {
                      xs: idx >= 3 ? "none" : "block",
                      md: "block",
                      lg: "block",
                    },
                  }}
                  key={idx}
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Avatar
                      alt={title}
                      src={avatar}
                      sx={{ width: 84, height: 84 }}
                    />
                    <Typography mt={2}>{name}</Typography>
                    <Typography sx={{ color: grey[600] }} variant="body2">
                      {title}
                    </Typography>
                    <Stack direction="row" mt={2}>
                      {twitter && (
                        <IconButton
                          aria-label="Twitter"
                          onClick={() => window.open(twitter, "_blank")}
                        >
                          <Twitter sx={{ color: blue[300] }} />
                        </IconButton>
                      )}
                      {linkedin && (
                        <IconButton
                          aria-label="LinkedIn"
                          onClick={() => window.open(linkedin, "_blank")}
                        >
                          <LinkedIn sx={{ color: blue[100] }} />
                        </IconButton>
                      )}
                      {email && (
                        <IconButton
                          aria-label="Email"
                          onClick={(event) => {
                            window.location.href = `mailto:${email}`;
                            event.preventDefault();
                          }}
                        >
                          <Email sx={{ color: blue[100] }} />
                        </IconButton>
                      )}
                    </Stack>
                  </Stack>
                </Card>
              );
            },
          )}
        </Stack>
      </Box>
    );
  };

  const renderPress = () => {
    return (
      <Box mt={10}>
        <Typography
          variant="h5"
          mb={9}
          sx={{ textAlign: "center", color: grey[200] }}
        >
          {i18n.newsTitle}
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={2}>
          {FeaturedPress.map(({ image, title, link, target }, idx) => {
            return (
              <Card
                sx={{
                  width: 220,
                  display: {
                    xs: idx >= 3 ? "none" : "block",
                    md: "block",
                    lg: "block",
                  },
                }}
                key={idx}
              >
                <CardActionArea onClick={() => window.open(link, target)}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={image}
                    alt={title}
                  />
                  <CardContent>
                    <Typography>{title}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            );
          })}
        </Stack>
      </Box>
    );
  };

  return (
    <Box>
      <Container
        maxWidth="false"
        sx={{
          pt: 20,
          backgroundColor: "#222222",
          background:
            "linear-gradient(0deg, rgba(10,10,10,1) 0%, rgba(34,34,34,1) 50%)",
        }}
      >
        <Container maxWidth="lg">
          <Stack alignItems="center">
            <Grid spacing={{ xs: 2, md: 4 }} container>
              <Grid
                item
                lg={4}
                md={4}
                sx={{
                  backgroundImage: 'url("/images/botfren.svg")',
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  display: { xs: "none", md: "block", lg: "block" },
                }}
              ></Grid>
              <Grid item lg={8} md={8} sm={12} mb={3}>
                <Typography
                  variant="h3"
                  sx={{ fontWeight: 500 }}
                  mb={8}
                  color={"#8B99D5"}
                >
                  {i18n.mainTitle}
                </Typography>

                <Typography variant="body1">{i18n.mainDescrOne}</Typography>

                <Stack
                  direction="row"
                  spacing={1}
                  mt={0.5}
                  mb={5}
                  color={grey[400]}
                >
                  <Typography variant="body1">Works on</Typography>

                  <Discord color={"#7289da"} />
                  <Twitter sx={{ color: "#1DA1F2" }} />
                  <Farcaster color={"#8A63D2"} />
                  <Bluesky color={"#1185FE"} />
                  <Typography variant="body1">
                    with data from Ethereum, Polygon, Base, and other top
                    chains.
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  {!hasUser() && (
                    <Button
                      variant="contained"
                      onClick={() => showRegisterModal(true)}
                    >
                      Start for free
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<PlayCircle />}
                    onClick={() => {
                      window.open("https://youtu.be/v_7wIQqIr1I", "_blank");
                    }}
                  >
                    watch demo
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Container>

      <Container>
        {renderExamples()}
        {renderPress()}
        {renderTeam()}
      </Container>
    </Box>
  );
};
export default Splash;

import React from "react";
import { Settings, Twitter, Telegram } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { Bluesky } from "../../assets/icons/Bluesky";
import { MastodonLogo } from "../../assets/Logos";
import { blue, grey } from "@mui/material/colors";
import { Discord } from "../../assets/icons/Discord";
import {
  BLUESKY,
  BOT_BLUESKY,
  BOT_DISCORD,
  BOT_FARCASTER,
  BOT_MASTODON,
  BOT_TELEGRAM,
  BOT_TWITTER,
  DISCORD,
  FARCASTER,
  MASTODON,
  TELEGRAM,
  TWITTER,
} from "../../constants/botTypes";
import { Farcaster } from "../../assets/icons/Farcaster";

export const botIconFactory = (type, size) => {
  const avatarSize = size === "large" ? 30 : 22;
  const iconSize = size === "large" ? 20 : 12;

  switch (type) {
    case TWITTER:
    case BOT_TWITTER:
      return (
        <Avatar
          sx={{
            width: avatarSize,
            height: avatarSize,
            bgcolor: blue[500],
            border: 2,
          }}
        >
          <Twitter sx={{ width: iconSize, height: iconSize, fill: "white" }} />
        </Avatar>
      );

    case MASTODON:
    case BOT_MASTODON:
      return (
        <Avatar
          sx={{
            width: avatarSize,
            height: avatarSize,
            bgcolor: "#563ACC",
            border: "2px solid black",
          }}
        >
          <MastodonLogo sx={{ width: iconSize, height: iconSize }} />
        </Avatar>
      );

    case FARCASTER:
    case BOT_FARCASTER:
      return (
        <Avatar
          sx={{
            width: avatarSize,
            height: avatarSize,
            bgcolor: "#8A63D2",
            border: "2px solid black",
          }}
        >
          <Farcaster width={iconSize} height={iconSize} fill={"white"} />
        </Avatar>
      );

    case DISCORD:
    case BOT_DISCORD:
      return (
        <Avatar
          sx={{
            width: avatarSize,
            height: avatarSize,
            bgcolor: "#5865F2",
            border: "2px solid black",
          }}
        >
          <Discord width={iconSize} height={iconSize} fill={"white"} />
        </Avatar>
      );

    case TELEGRAM:
    case BOT_TELEGRAM:
      return (
        <Avatar
          sx={{
            width: avatarSize,
            height: avatarSize,
            bgcolor: "#0088CC",
            border: 2,
          }}
        >
          <Telegram sx={{ width: iconSize, height: iconSize, fill: "white" }} />
        </Avatar>
      );

    case BLUESKY:
    case BOT_BLUESKY:
      return (
        <Avatar
          sx={{
            width: avatarSize,
            height: avatarSize,
            border: "2px solid black",
            bgcolor: "#0088CC",
          }}
        >
          <Bluesky width={iconSize} height={iconSize} fill={"white"} />
        </Avatar>
      );

    default:
      return (
        <Avatar
          sx={{
            width: avatarSize,
            height: avatarSize,
            bgcolor: grey[800],
            border: "2px solid black",
          }}
        >
          <Settings sx={{ width: iconSize, height: iconSize, fill: "white" }} />
        </Avatar>
      );
  }
};

import { useQuery } from "react-query";
import { getGroupBots } from "../api/v1/groups";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";

export const groupBotsQuery = (id, page = 1) => {
  return `/groups/${id}/index_bots?page=${page}`;
};

export const useGroupBots = ({ id, page, enabled }) => {
  const userContext = useContext(UserContext);
  const query = groupBotsQuery(id, page);
  const { deleteUser } = userContext;

  const { data, isFetched, isError, isFetching, remove } = useQuery(
    query,
    () => getGroupBots(id, page),
    {
      enabled,
      refetchOnWindowFocus: true,
      onError: (err) => {
        if (err.response.status === 401) {
          remove();
          deleteUser();
        }
      },
    },
  );
  return { data, isError, isFetched, isFetching, query };
};

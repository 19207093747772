import { useContext } from "react";
import { useQuery } from "react-query";
import { getAccount } from "../api/v1/accounts";
import { UserContext } from "../contexts/UserContext";

export const accountQuery = (id) => `/accounts/${id}`;

export const useAccount = ({ id }) => {
  const userContext = useContext(UserContext);
  const query = accountQuery(id);
  const { deleteUser } = userContext;

  const { data, isFetched, isError, remove } = useQuery(
    query,
    () => getAccount(id),
    {
      refetchOnWindowFocus: true,
      onError: (err) => {
        if (err.response.status === 401) {
          remove();
          deleteUser();
        }
      },
    },
  );

  return { data, isError, isFetched, query };
};

import { useQuery } from "react-query";
import { getBotStats } from "../api/v1/bots";

export const useBotStats = ({ bot, enabled = true }) => {
  const query = `bots/${bot.id}/stats`;

  const { data, isError, isFetched, isFetching, remove } = useQuery(
    query,
    () => getBotStats(bot.id),
    {
      enabled,
      refetchOnWindowFocus: false,
      onError: (err) => {
        if (err.response.status === 401) {
          remove();
        }
      },
    },
  );
  return { data, isError, isFetched, isFetching, query };
};

import TwitterIcon from "@mui/icons-material/Twitter";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { createAuthentication } from "../../api/v1/authentications";

const i18n = {
  label: "Connect Twitter",
  connectError:
    "Unable to connect to Twitter at this time. Please try again later.",
};

export const TwitterConnect = ({
  onClick,
  onConnected,
  label = i18n.label,
}) => {
  const [isConnectingTwitter, setConnectingTwitter] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onConnectTwitter = async () => {
    onClick();
    setConnectingTwitter(true);

    createAuthentication({ provider: "twitter" })
      .then((response) => {
        const { oauth_token } = response.data;

        if (oauth_token) {
          //Redirect for Twitter authentication
          window.open(
            `https://api.x.com/oauth/authenticate?oauth_token=${oauth_token}`,
            "_blank",
          );
        } else {
          enqueueSnackbar(i18n.connectError, { variant: "error" });
        }
        onConnected();
        setConnectingTwitter(false);
      })
      .catch(() => {
        enqueueSnackbar(i18n.connectError, { variant: "error" });
        setConnectingTwitter(false);
      });
  };

  return (
    <LoadingButton
      variant="contained"
      onClick={onConnectTwitter}
      startIcon={<TwitterIcon />}
      loading={isConnectingTwitter}
    >
      {label}
    </LoadingButton>
  );
};

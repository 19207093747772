import { SvgIcon } from "@mui/material";

export const Etherscan = (props) => {
  const defaultProps = {
    width: 24,
    height: 24,
    ...props,
  };

  return (
    <SvgIcon sx={defaultProps}>
      <path
        d="M4.97508 11.3929C4.9751 11.2593 5.00152 11.127 5.05281 11.0036C5.10409 10.8803 5.17924 10.7682 5.27394 10.674C5.36866 10.5797 5.48103 10.5051 5.60465 10.4544C5.72827 10.4038 5.86068 10.378 5.9943 10.3786L7.68413 10.3841C7.95354 10.3841 8.21193 10.4911 8.40244 10.6816C8.59296 10.8722 8.7 11.1305 8.7 11.4V17.7895C8.89023 17.733 9.13456 17.6728 9.4019 17.6099C9.58761 17.5663 9.75311 17.4612 9.87157 17.3116C9.99002 17.1621 10.0545 16.977 10.0544 16.7862V8.8603C10.0544 8.59085 10.1614 8.33246 10.3519 8.14191C10.5425 7.95137 10.8008 7.84429 11.0703 7.84424H12.7635C13.0329 7.84429 13.2913 7.95137 13.4818 8.14191C13.6723 8.33246 13.7793 8.59085 13.7793 8.8603V16.2163C13.7793 16.2163 14.2033 16.0448 14.6162 15.8705C14.7696 15.8056 14.9005 15.697 14.9926 15.5582C15.0846 15.4194 15.1338 15.2566 15.134 15.0901V6.32063C15.134 6.05124 15.241 5.79288 15.4314 5.60238C15.6219 5.41185 15.8802 5.30482 16.1496 5.30476H17.8428C18.1122 5.30476 18.3706 5.4118 18.5611 5.6023C18.7517 5.79281 18.8587 6.0512 18.8587 6.32063V13.5421C20.3266 12.4782 21.8142 11.1987 22.9948 9.66017C23.1661 9.43685 23.2794 9.17462 23.3247 8.89685C23.3699 8.6191 23.3457 8.33444 23.2542 8.0683C22.7077 6.49606 21.8392 5.0552 20.7039 3.83795C19.5687 2.62067 18.1918 1.6538 16.6614 0.999207C15.131 0.344613 13.4809 0.0167065 11.8165 0.0364726C10.1521 0.0562387 8.51018 0.423243 6.9958 1.114C5.4814 1.80475 4.12788 2.80404 3.02186 4.04793C1.91583 5.29182 1.08166 6.75288 0.572717 8.33767C0.0637785 9.92244 -0.108718 11.596 0.0662658 13.2513C0.241249 14.9065 0.759862 16.5071 1.58891 17.9504C1.73333 18.1994 1.94581 18.402 2.20137 18.5344C2.45693 18.6668 2.74497 18.7235 3.03167 18.6979C3.35193 18.6698 3.75069 18.6299 4.22479 18.5742C4.43117 18.5508 4.62171 18.4522 4.76011 18.2974C4.89852 18.1425 4.9751 17.9421 4.97528 17.7344L4.97508 11.3929Z"
        fill="white"
      />
      <path
        d="M4.93811 21.6411C6.72282 22.9395 8.83197 23.7189 11.0322 23.893C13.2324 24.0669 15.4378 23.629 17.4045 22.6273C19.3712 21.6256 21.0224 20.0994 22.1756 18.2176C23.3286 16.3356 23.9386 14.1714 23.938 11.9644C23.938 11.6889 23.9252 11.4165 23.9069 11.1456C19.536 17.6645 11.4655 20.7122 4.93848 21.6401"
        fill="#8B8B8B"
      />
    </SvgIcon>
  );
};

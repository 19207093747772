import { useQuery } from "react-query";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { getContent } from "../api/v1/content.api";
import { LONG_STALE } from "../api/axios.config";

export const contentQuery = (id) => `/contents/${id}`;

export const useContent = (id) => {
  const userContext = useContext(UserContext);
  const query = contentQuery(id);
  const { deleteUser } = userContext;

  const { data, isFetched, isFetching, isError, remove } = useQuery(
    query,
    () => getContent(id),
    {
      refetchOnWindowFocus: false,
      staleTime: LONG_STALE,
      onError: (err) => {
        if (err.response.status === 401) {
          remove();
          deleteUser();
        }
      },
    },
  );
  return { data, isError, isFetched, isFetching, query };
};

import { useState } from "react";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { deleteBot } from "../api/v1/bots";
import { useNavigate } from "react-router-dom";
import { botsQuery } from "./useBots";
import { invalidateQueries } from "../components/utils/reactQueryUtils";

const i18n = {
  success: "Bot deleted successfully.",
  error:
    "We are unable to delete this bot at this time. Please try again later.",
};

export const useBotDelete = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const call = (id) => {
    setLoading(true);
    deleteBot(id)
      .then(async () => {
        await invalidateQueries(queryClient, [botsQuery]);

        setLoading(true);
        enqueueSnackbar(i18n.success, { variant: "success" });
        navigate(`/home`);
      })
      .catch((err) => {
        setLoading(true);
        const errorMsg =
          err && err.response.data.error ? err.response.data.error : i18n.error;
        enqueueSnackbar(errorMsg, { variant: "error" });
      });
  };
  return { call, isLoading };
};

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useState } from "react";
import CollectionCreateModal from "../../../components/modals/CollectionCreateModal";
import SearchForm from "../../../components/forms/SearchForm";

const i18n = {
  description:
    "Collections simplify the organization and sharing of content through collection-specific behaviors.",
  error: "We are unable to load data for this page. Please try again later.",
  title: "Collections",
  tooltip: "New Collection",
};

export const Header = ({ filter = false }) => {
  const [isNewModal, setNewModal] = useState(false);

  return (
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="h5">
          {i18n.title}
          <Tooltip title={i18n.tooltip} placement="right" arrow>
            <IconButton
              color="primary"
              aria-label={i18n.tooltip}
              onClick={() => setNewModal(true)}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </Typography>
        {!filter && (
          <Typography color={grey[500]}>{i18n.description}</Typography>
        )}

        {isNewModal && (
          <CollectionCreateModal
            open={isNewModal}
            onClose={() => setNewModal(false)}
          />
        )}
      </Box>
      {filter && <SearchForm />}
    </Box>
  );
};

import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AccountCircle,
  ExitToApp,
  Favorite,
  GridView,
  Twitter,
} from "@mui/icons-material";

import { Discord } from "../assets/icons/Discord";
import { UserContext } from "../contexts/UserContext";

const i18n = {
  pageHeaderLabel: "BotFrens",
  dashboardButtonLabel: "Dashboard",
  discord: "Community Discord",
  twitter: "BotFrens Twitter",
  logoutButtonLabel: "Log Out",
  logInButtonLabel: "Sign In",
  registerButton: "Register",
};

const LayoutNav = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { hasUser, deleteUser, showLoginModal, showRegisterModal } =
    useContext(UserContext);

  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onSignOut = () => {
    handleMenuClose();
    deleteUser();
  };

  const renderHeaderNav = () => {
    if (hasUser()) {
      return (
        <IconButton
          size={"small"}
          aria-label="show more"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenuOpen}
        >
          <AccountCircle />
        </IconButton>
      );
    } else {
      return (
        <Box>
          <Button color="inherit" onClick={() => showRegisterModal(true)}>
            Register
          </Button>
          <Button color="inherit" onClick={() => showLoginModal(true)}>
            {i18n.logInButtonLabel}
          </Button>
        </Box>
      );
    }
  };

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            navigate(`/home`);
          }}
        >
          <ListItemIcon>
            <GridView fontSize={"small"} />
          </ListItemIcon>
          {i18n.dashboardButtonLabel}
        </MenuItem>
        <Divider />
        <MenuItem onClick={onSignOut}>
          <ListItemIcon>
            <ExitToApp fontSize={"small"} />
          </ListItemIcon>
          {i18n.logoutButtonLabel}
        </MenuItem>
      </Menu>
    );
  };

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Stack
          direction="row"
          justifyContent="left"
          alignItems="center"
          spacing={1}
          sx={{ flexGrow: 1, cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          <Favorite />
          <Typography>{i18n.pageHeaderLabel}</Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <IconButton
            size="small"
            aria-label={i18n.discord}
            onClick={() =>
              window.open("https://discord.gg/EqZkPb77TV", "_blank")
            }
          >
            <Discord width={24} height={24} />
          </IconButton>
          <IconButton
            size="small"
            aria-label={i18n.twitter}
            onClick={() =>
              window.open("https://twitter.com/botfrens", "_blank")
            }
          >
            <Twitter />
          </IconButton>

          {renderHeaderNav()}
        </Stack>
        {renderMenu()}
      </Toolbar>
    </AppBar>
  );
};

export default LayoutNav;

import React, { useState } from "react";
import {
  Box,
  FormControl,
  OutlinedInput,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  IconButton,
  Badge,
  InputAdornment,
  Typography,
  Tooltip,
} from "@mui/material";
import { ArrowCircleRight } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

const i18n = {
  active: "Active Subscription",
  enterprise: "upgrade with custom code",
  code: "price code",
  twitter: "twitter",
  interval: (val) => (val === "month" ? "MO" : "YR"),
};

const PlanCard = ({ plan, activeSubscription, onClick }) => {
  const { id, name, description, metadata, prices } = plan;
  const [priceId, setPriceId] = useState(null);

  const isValidCustomPrice =
    priceId && priceId.toLowerCase().includes("price") && priceId.length > 10;

  const isActivePlan = (productId, priceId) => {
    return (
      activeSubscription &&
      productId === activeSubscription.product &&
      priceId === activeSubscription.price
    );
  };

  return (
    <Card sx={{ width: 300 }}>
      <Box
        sx={{ width: "100%", height: "30px", backgroundColor: metadata.color }}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>

      <Divider />
      {name === "Enterprise" ? (
        <CardContent>
          <Typography sx={{ color: grey[500] }} variant={"overline"}>
            {i18n.enterprise}
          </Typography>

          <FormControl variant="outlined">
            <OutlinedInput
              size="small"
              type="text"
              placeholder={i18n.code}
              onChange={(event) => {
                setPriceId(event.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    size="small"
                    disabled={!isValidCustomPrice}
                    onClick={() => onClick(id, priceId)}
                  >
                    <ArrowCircleRight />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </CardContent>
      ) : (
        <CardContent>
          <Typography sx={{ color: grey[500] }} variant={"overline"}>
            subscribe with credit card
          </Typography>

          <Stack direction="row" spacing={2}>
            {prices
              .sort((a, b) => a.unit_amount - b.unit_amount)
              .map((price) => {
                return (
                  <div key={price.id}>
                    {isActivePlan(plan.id, price.id) ? (
                      <Tooltip title={i18n.active}>
                        <Badge color="success" variant="dot">
                          <Button
                            key={price.id}
                            size="small"
                            variant="outlined"
                            disabled
                          >
                            {`$${(price.unit_amount / 100).toFixed(
                              2,
                            )} / ${i18n.interval(price.recurring.interval)}`}
                          </Button>
                        </Badge>
                      </Tooltip>
                    ) : (
                      <Button
                        key={price.id}
                        size="small"
                        variant="outlined"
                        onClick={() => onClick(id, price.id)}
                      >
                        {`$${(price.unit_amount / 100).toFixed(
                          2,
                        )} / ${i18n.interval(price.recurring.interval)}`}
                      </Button>
                    )}
                  </div>
                );
              })}
          </Stack>
        </CardContent>
      )}
    </Card>
  );
};

export default PlanCard;

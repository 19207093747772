// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case "SET_ACTIVE_BOT":
      return {
        activeBot: action.payload,
      };

    case "SET_USER":
      return {
        user: action.payload,
      };
    default:
      return state;
  }
};

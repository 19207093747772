import { useQuery } from "react-query";
import { getBotPublishedPosts } from "../api/v1/bots";

export const useBotPublishedPosts = ({ bot, enabled = true, page = 1 }) => {
  const query = `bots/${bot.id}/published_posts?page=${page}`;

  const { data, isError, isFetched, isFetching, remove } = useQuery(
    query,
    () => getBotPublishedPosts(bot.id, page),
    {
      enabled,
      refetchOnWindowFocus: false,
      onError: (err) => {
        if (err.response.status === 401) {
          remove();
        }
      },
    },
  );
  return { data, isError, isFetched, isFetching, query };
};

import { useState } from "react";
import { useSnackbar } from "notistack";
import { useLoggedUser, useLoggedUserQuery } from "./useLoggedUser";
import { useQueryClient } from "react-query";
import { editUser } from "../api/v1/users";

const i18n = {
  error: "Unable to update account info at this time",
  success: "Account info updated",
};

export const useUserUpdate = () => {
  const [isFetching, setFetching] = useState(false);
  const queryClient = useQueryClient();
  const userQuery = useLoggedUserQuery();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useLoggedUser();

  const call = (payload) => {
    setFetching(true);

    editUser(user?.data?.id, payload)
      .then((result) => {
        queryClient.invalidateQueries(userQuery).then(() => {
          enqueueSnackbar(i18n.success, { variant: "success" });
          setFetching(false);
        });
      })
      .catch((err) => {
        const errorMsg =
          err && err.response.data.error ? err.response.data.error : i18n.error;
        enqueueSnackbar(errorMsg, { variant: "error" });
        setFetching(false);
      });
  };
  return { call, isFetching };
};

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useGroupCreate } from "../../hooks/useGroupCreate";

const i18n = {
  cancel: "Nevermind",
  inputHelper: "Something simple and short. You can always change it later",
  inputTitle: "Group Name",
  submit: "Create",
  title: "New Group",
};

const GroupCreateModal = ({ open, onClose }) => {
  const { call, isFetching, isFetched } = useGroupCreate();

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  const onSubmit = (payload) => {
    call(payload);
  };

  const renderButtons = () => {
    return (
      <Stack direction="row" sx={{ marginTop: 1 }} spacing={1}>
        <Button variant="outlined" disabled={isFetching} onClick={onClose}>
          {i18n.cancel}
        </Button>
        <LoadingButton variant="contained" loading={isFetching} type="submit">
          {i18n.submit}
        </LoadingButton>
      </Stack>
    );
  };

  useEffect(() => {
    if (isFetched) {
      onClose();
    }
  }, [isFetched, onClose]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.title}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <TextField
              fullWidth
              {...register("group.name")}
              aria-label={i18n.inputTitle}
              helperText={i18n.inputHelper}
              label={i18n.inputTitle}
              required={true}
              type="text"
            />
          </Box>
        </DialogContent>
        <DialogActions>{renderButtons()}</DialogActions>
      </form>
    </Dialog>
  );
};

export default GroupCreateModal;

import React from "react";
import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import ProgressLoader from "../utils/ProgressLoader";
import AuthenticationDisconnectCard from "../cards/AuthenticationDisconnectCard";
import { useLoggedUser } from "../../hooks/useLoggedUser";

const i18n = {
  title: "Social Media Accounts",
  error:
    "Error loading connected social media accounts at this time. Please try again later.",
  empty: "You don't have any connected social media accounts.",
};

const AuthenticationEditModal = ({ onClose, open }) => {
  const { user, isFetched, isError } = useLoggedUser();

  const renderContent = () => {
    if (!isFetched) {
      return <ProgressLoader />;
    }

    if (!user && isError) {
      return i18n.error;
    }

    if (user.data.authentications.length === 0) {
      return i18n.empty;
    }

    return (
      <Stack spacing={1}>
        {user.data.authentications.map((auth) => (
          <AuthenticationDisconnectCard
            key={`auth-${auth.id}`}
            authentication={auth}
          />
        ))}
      </Stack>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.title}</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
    </Dialog>
  );
};
export default AuthenticationEditModal;

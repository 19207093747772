import { useQuery } from "react-query";
import { getGroup } from "../api/v1/groups";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";

export const groupQuery = (id) => `/groups/${id}`;

export const useGroup = (id) => {
  const userContext = useContext(UserContext);
  const query = groupQuery(id);
  const { deleteUser } = userContext;

  const { data, isFetched, isFetching, isError, remove } = useQuery(
    query,
    () => getGroup(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: true,
      onError: (err) => {
        if (err.response.status === 401) {
          remove();
          deleteUser();
        }
      },
    },
  );
  return { data, isError, isFetched, isFetching, query };
};

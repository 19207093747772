import React, { FC } from "react";
import { useNavigate } from "react-router";
import { Avatar, Card, CardActionArea, CardHeader } from "@mui/material";
import CollectionsIcon from "@mui/icons-material/Collections";
import { truncate } from "../utils/utils";
import { collectionUrl } from "../utils/collectionUtils";
import { Collection } from "../../types/types";

const i18n = {
  items: (count: number) => `${count} ${count === 1 ? "item" : "items"}`,
};

interface CollectionCardProps {
  collection: Collection;
}

export const CollectionCard: FC<CollectionCardProps> = ({ collection }) => {
  const navigate = useNavigate();
  const { cover, id, items, title } = collection;

  const renderAvatar = () => {
    if (cover) {
      return <Avatar variant="rounded" src={cover} />;
    } else {
      return (
        <Avatar variant="rounded">
          <CollectionsIcon />
        </Avatar>
      );
    }
  };

  return (
    <Card sx={{ mb: 2, mr: 2, width: 290 }} raised>
      <CardActionArea onClick={() => navigate(collectionUrl(id))}>
        <CardHeader
          avatar={renderAvatar()}
          title={truncate(title, 25)}
          subheader={i18n.items(items)}
        />
      </CardActionArea>
    </Card>
  );
};

import moment from "moment";
import { parseExpression } from "cron-parser";

const i18n = {
  automated: "Runs Automatically",
  disabled: "Behavior Disabled",
};

export const behaviorSchedule = (behavior) => {
  if (!behavior.enabled) {
    return i18n.disabled;
  }

  if (!behavior.definition.schedule_config) {
    return i18n.automated;
  }
  return behavior.custom_schedule
    ? humanizeCron(behavior.custom_schedule)
    : behavior.schedule;
};

export const humanizeCron = (cron) => {
  const { hour, minute, dayOfWeek } = parseExpression(cron).fields;

  const utcOffset = moment().utcOffset();
  const timeValue = moment()
    .hour(hour)
    .minute(minute)
    .add(utcOffset, "minutes");
  const day =
    dayOfWeek.length === 8
      ? "Every day"
      : `Every ${moment().day(dayOfWeek).format("dddd")}`;

  return `${day} at ${timeValue.format("hh:mm A")}`;
};

import { useSnackbar } from "notistack";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { createContent } from "../api/v1/content.api";
import { collectionQuery } from "./useCollection";
import { collectionContentsQuery } from "./useCollectionContents";
import { collectionsQuery } from "./useCollections";

const i18n = {
  error: "Unable to create content at this time",
  success: "Content created successfully",
};

export const useContentCreate = () => {
  const [isFetching, setFetching] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [isError, setError] = useState(false);
  const [data, setData] = useState(null);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const invalidateQueries = async (queries) => {
    await Promise.all(
      queries.map((key) => {
        return queryClient.invalidateQueries(key);
      }),
    );
  };

  const call = (id, page, payload) => {
    setFetching(true);

    createContent(payload)
      .then(async (result) => {
        await invalidateQueries([
          collectionsQuery(),
          collectionContentsQuery(id, page),
          collectionQuery(id),
        ]);

        enqueueSnackbar(i18n.success, { variant: "success" });
        setFetching(false);
        setFetched(true);
        setData(result);
      })
      .catch((err) => {
        const errorMsg =
          err && err.response.data.error ? err.response.data.error : i18n.error;
        enqueueSnackbar(errorMsg, { variant: "error" });
        setFetching(false);
        setError(true);
      });
  };
  return { call, data, isFetched, isFetching, isError };
};

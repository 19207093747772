import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { useContext, useState } from "react";

import { Delete, Edit, MoreVert } from "@mui/icons-material";

import { orange, red } from "@mui/material/colors";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { deleteBotBehavior } from "../../api/v1/bots";
import {
  ARBITRUM,
  BASE,
  ETHEREUM,
  POLYGON,
} from "../../constants/blochainTypes";
import { UserContext } from "../../contexts/UserContext";
import { behaviorIconFactory } from "../icons/BehaviorIcons";
import { BehaviorEditModal } from "../modals/BehaviorEditModal";
import ProgressLoader from "../utils/ProgressLoader";
import { behaviorSchedule } from "../utils/cronUtils";
import { CollectionEmbedCard } from "./CollectionEmbedCard";
import { GroupEmbedCard } from "./GroupEmbedCard";
import { RaribleCollectionCardContained } from "./RaribleCollectionCardContaied";
import { useGroup } from "../../hooks/useGroup";

const i18n = {
  deleteButton: "Delete",
  deleteError: "Unable to delete behavior at this time",
  deleteSuccess: "Behavior deleted",
  editButton: "Edit",
};

const BehaviorCard = ({ bot, behavior, parentQuery }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [isDeleting, setDeleting] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { hasUser } = useContext(UserContext);
  const isMenuOpen = Boolean(anchorEl);
  const hasError = !behavior.enabled && behavior.error;

  const {
    blockchain,
    contract_address,
    text_template,
    search_terms,
    prompt,
    group_id,
  } = behavior.meta;

  const { data: group } = useGroup(group_id);

  const belongsToUser = () => {
    return hasUser() && bot && bot.is_owner;
  };

  // Used for previews
  const isRaribleChain = (blockchain) => {
    return Boolean(
      blockchain === ARBITRUM ||
        blockchain === BASE ||
        blockchain === ETHEREUM ||
        blockchain === POLYGON,
    );
  };
  const hasRaribleContractIdentifier = Boolean(blockchain && contract_address);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleMenuClose();
    setDeleting(true);

    deleteBotBehavior(bot.id, behavior.id)
      .then(() => {
        queryClient.invalidateQueries(parentQuery).then(() => {
          setDeleting(false);
          enqueueSnackbar(i18n.deleteSuccess, { variant: "success" });
        });
      })
      .catch((err) => {
        setDeleting(false);
        const errorMsg =
          err && err.response.data.error
            ? err.response.data.error
            : i18n.deleteError;
        enqueueSnackbar(errorMsg, { variant: "error" });
      });
  };

  const renderMenu = () => {
    if (!belongsToUser()) {
      return null;
    }

    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          disableRipple
          onClick={() => {
            handleMenuClose();
            setEditing(true);
          }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          {i18n.editButton}
        </MenuItem>
        <MenuItem onClick={handleDelete} sx={{ color: red[500] }}>
          <ListItemIcon>
            <Delete sx={{ color: red[500] }} />
          </ListItemIcon>
          {i18n.deleteButton}
        </MenuItem>
      </Menu>
    );
  };

  const renderError = () => {
    return (
      <Alert severity="error" sx={{ marginBottom: 2 }} variant="outlined">
        {behavior.error}
      </Alert>
    );
  };

  const renderContent = () => {
    const type = behavior.shareable_class;
    // Display BotFrens collection
    if (behavior.collection) {
      return <CollectionEmbedCard collection={behavior.collection} />;
    }

    // Display BotFrens group
    if (group_id && group && group.data) {
      return <GroupEmbedCard group={group.data} />;
    }

    // Display NFT collection card, exclude all nouns behaviors
    if (
      hasRaribleContractIdentifier &&
      isRaribleChain(blockchain) &&
      !type.toLowerCase().includes("noun") &&
      !type.toLowerCase().includes("highlight") &&
      type !== "StreamingEvent"
    ) {
      return (
        <RaribleCollectionCardContained
          blockchain={blockchain}
          contractAddress={contract_address}
        />
      );
    }

    if (type === "TwitterRepostSearch") {
      return search_terms;
    }

    if (type === "TextShare") {
      return text_template;
    }

    if (type === "ChatGptTextShare") {
      return prompt;
    }

    if (type === "StreamingEvent") {
      return `Shares occurrences of the ${behavior.meta.event_name} event.`;
    }

    return behavior.definition.description;
  };

  return (
    <Card sx={{ marginBottom: 1, marginRight: 1, width: 350 }} raised>
      <CardHeader
        sx={{
          "& .MuiCardHeader-subheader": {
            color: behavior.enabled ? "inherit" : orange[200],
          },
        }}
        action={
          belongsToUser() ? (
            <IconButton aria-haspopup="true" onClick={handleMenuOpen}>
              {isDeleting ? <ProgressLoader /> : <MoreVert />}
            </IconButton>
          ) : null
        }
        avatar={behaviorIconFactory(behavior.definition.type, blockchain)}
        title={behavior.name}
        subheader={behaviorSchedule(behavior)}
      />
      <CardContent>
        {hasError && renderError()}
        {renderContent()}
      </CardContent>

      {renderMenu()}

      {isEditing && belongsToUser() && (
        <BehaviorEditModal
          open={isEditing}
          onClose={() => setEditing(false)}
          behavior={behavior}
          bot={bot}
          parentQuery={parentQuery}
        />
      )}
    </Card>
  );
};

export default BehaviorCard;

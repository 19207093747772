import React from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";

import { grey } from "@mui/material/colors";

export const Header = ({ community }) => {
  const { name, description, image } = community;

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Stack
          direction="row"
          justifyContent="flex-start"
          spacing={2}
          sx={{ marginBottom: 4 }}
        >
          <Avatar sx={{ width: 60, height: 60 }} src={image} />

          <Stack direction="column" spacing={0} sx={{ paddingLeft: 1 }}>
            <Typography variant="h4">{name}</Typography>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ color: grey[500] }}
            >
              <Typography sx={{ paddingRight: 1 }}>{description}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSubscriptionDelete } from "../../hooks/useSubscriptionDelete";
import { grey } from "@mui/material/colors";

const i18n = {
  cancel: "nevermind",
  plan: "Plan",
  confirmationInfo:
    "Before cancelling, please ensure you back up your invoices, as they will become inaccessible.",
  confirmationMessage: "We'd be truly saddened to see you leave. Are you sure?",
  delete: "Cancel Subscription",
  deleted: "Subscription deleted... ",
  title: "Cancel",
};

const SubscriptionDeleteModal = ({ plan, onClose, open }) => {
  const {
    call: deleteSubscription,
    isFetching,
    isFetched,
  } = useSubscriptionDelete();

  useEffect(() => {
    if (isFetched) {
      onClose();
    }
  }, [isFetched, onClose]);

  return (
    plan && (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>
          {i18n.delete} - {plan.name} {i18n.plan}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{i18n.confirmationMessage}</Typography>
          <Typography variant="body1" color={grey[500]}>
            {i18n.confirmationInfo}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={onClose}
            aria-label={i18n.cancel}
            disabled={isFetching}
          >
            {i18n.cancel}
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            onClick={() => deleteSubscription(plan.id)}
            loading={isFetching}
            aria-label={i18n.delete}
          >
            {i18n.delete}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    )
  );
};
export default SubscriptionDeleteModal;

import { useContext, useState } from "react";
import { createAuthentication } from "../api/v1/authentications";
import { useSnackbar } from "notistack";
import { GlobalContext } from "../contexts/GlobalState";
import { capitalize } from "../components/utils/utils";
import { useQueryClient } from "react-query";
import { useLoggedUserQuery } from "./useLoggedUser";
import { authenticationsQuery } from "./useAuthentications";
import { botQuery } from "./useBot";
import { invalidateQueries } from "../components/utils/reactQueryUtils";

const i18n = {
  success: (provider) => `${capitalize(provider)} account connected!`,
  error: (provider) =>
    `Unable to connect ${capitalize(
      provider,
    )} account at this time. Please try again.`,
};

export const useAuthenticationCreate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { activeBot } = useContext(GlobalContext);
  const [data, setData] = useState(null);
  const [isFetching, setFetching] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [isError, setError] = useState(false);

  const queryClient = useQueryClient();
  const userQuery = useLoggedUserQuery();

  const dependentQueries = [userQuery, authenticationsQuery];

  if (activeBot) {
    dependentQueries.push(botQuery(activeBot));
  }

  const call = (payload) => {
    setFetching(true);

    // Add active bot to payload if exists
    const mxPayload = activeBot ? { ...payload, bot_id: activeBot } : payload;

    createAuthentication(mxPayload)
      .then(async (result) => {
        await invalidateQueries(queryClient, dependentQueries);
        setData(result.data);
        setFetching(false);
        setFetched(true);
        enqueueSnackbar(i18n.success(payload.provider), { variant: "success" });
      })
      .catch((err) => {
        const errorMsg =
          err && err.response.data.error
            ? err.response.data.error[0]
            : i18n.error(payload.provider);

        setFetching(false);
        setError(true);
        enqueueSnackbar(errorMsg, { variant: "error" });
      });
  };
  return { call, data, isFetching, isFetched, isError };
};

import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { useRaribleCollectionMeta } from "../../hooks/useRaribleCollectionMeta";
import ProgressLoader from "../utils/ProgressLoader";

const i18n = {
  error: "Collection data unavailable...",
};

export const RaribleCollectionCardContained = ({
  contractAddress,
  blockchain,
}) => {
  const { data, isFetched, isFetching, isError } = useRaribleCollectionMeta({
    contractAddress,
    blockchain,
  });

  if (isFetching && !isFetched) {
    return <ProgressLoader />;
  }

  if (isError || !data) {
    return i18n.error;
  }
  const image = (data.data.meta?.content ?? []).find(
    (itm) => itm["@type"] === "IMAGE",
  );
  const { name, meta } = data.data;

  return (
    <Card sx={{ display: "flex", width: "100%" }}>
      {image && (
        <CardMedia
          component="img"
          sx={{ width: 72, height: 72 }}
          image={image.url}
          alt={name}
        />
      )}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Typography variant="body1">
              {meta?.externalLink ? (
                <Tooltip title={meta?.externalLink} placement="right">
                  <Link
                    href={meta?.externalLink}
                    underline="none"
                    target="_blank"
                  >
                    {name}
                  </Link>
                </Tooltip>
              ) : (
                <div>{name}</div>
              )}
            </Typography>
          </Stack>
        </CardContent>
      </Box>
    </Card>
  );
};

import { ETHEREUM } from "../../constants/blochainTypes";

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const truncate = (str, n) => {
  if (!str) {
    return;
  }
  return str.length > n ? str.substring(0, n - 1) + "..." : str;
};

export const truncateAddress = (address) => {
  return `${address.substring(0, 6)}....${address.substring(address.length - 4, address.length)}`;
};

export const formatPrice = (amount, currency) => {
  return `${(amount / 100).toFixed(2)} ${currency.toUpperCase()}`;
};

export const isEmptyValue = (value) => {
  return value === undefined || value === null || value === "";
};

export const constructUrlWithParams = (params) => {
  let baseUrl = window.location.pathname;
  let queryParams = [];

  params.forEach((param) => {
    let key = Object.keys(param)[0];
    let value = param[key];

    if (value !== undefined && value !== null) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      );
    }
  });
  return queryParams.length > 0
    ? `${baseUrl}?${queryParams.join("&")}`
    : baseUrl;
};

export const getEtherscanUrl = (address) => {
  return `https://etherscan.io/address/${address}`;
};

export const getOpenseaUrl = (blockchain, address) => {
  return `https://opensea.io/assets/${blockchain.toLowerCase()}/${address.toLowerCase()}`;
};

export const getRaribleUrl = (blockchain, address) => {
  const base = "https://rarible.com/collection/";

  if (blockchain === ETHEREUM) {
    return `${base}${address.toLowerCase()}`;
  } else {
    return `${base}${blockchain.toLowerCase()}/${address.toLowerCase()}`;
  }
};

export const getLooksRareUrl = (address) => {
  return `https://looksrare.org/collections/${address}`;
};

import { useContext } from "react";
import { getUser } from "../api/v1/users";
import { useQuery } from "react-query";
import { UserContext } from "../contexts/UserContext";

export const useLoggedUserQuery = () => {
  const userContext = useContext(UserContext);
  return `users/${userContext.user.id}`;
};

export const useLoggedUser = (enabled) => {
  const userContext = useContext(UserContext);
  const query = useLoggedUserQuery();
  const { deleteUser, setUser } = userContext;

  const {
    data: user,
    isError,
    isFetched,
    remove,
  } = useQuery(query, () => getUser(userContext.user.id), {
    enabled,
    refetchOnWindowFocus: true,
    onSuccess: (response) => {
      setUser(response.data);
    },
    onError: (err) => {
      if (err.response.status === 401) {
        setUser(null);
        remove();
        deleteUser();
      }
    },
  });

  return { user, isError, isFetched, invalidateQuery: query };
};

import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { ethers } from "ethers";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { createBotQuickstart } from "../../api/v1/bots";
import { Error, CheckCircle } from "@mui/icons-material";
import { useRaribleCollectionMeta } from "../../hooks/useRaribleCollectionMeta";
import { RaribleCollectionCard } from "../cards/RaribleCollectionCard";
import ProgressLoader from "../utils/ProgressLoader";
import {
  ARBITRUM,
  BASE,
  ETHEREUM,
  POLYGON,
  TEZOS,
} from "../../constants/blochainTypes";

const i18n = {
  cancelButton: "Cancel",
  errorMessage: "Unable to configure bot behaviors at this time",
  modalTitle: "NFT Bot Quickstart",
  modalDescription:
    "Simply paste collection's contract address and start sharing sales, listings, and stats from OpenSea, Rarible, LooksRare, X2Y2, and Sudoswap.",
  successMessage: "Bot behaviors added successfully",
  updateButton: "Create Bot",
};

const QUICKSTART_TYPE = "nft";
const CHAINS = [ETHEREUM, POLYGON, ARBITRUM, BASE, TEZOS];

const BehaviorQuickstartNFTModal = ({ bot, parentQuery, onClose, open }) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [blockchain, setBlockchain] = useState(ETHEREUM);
  const [contractAddress, setContractAddress] = useState();
  const [isUpdating, setUpdating] = useState(false);

  const { data, isFetched } = useRaribleCollectionMeta({
    enabled: ethers.isAddress(contractAddress),
    contractAddress,
    blockchain,
  });

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  const isInputValid = () => {
    return ethers.isAddress(contractAddress) && data && data.data;
  };

  const renderAdornment = (value) => {
    if (value && value.length > 0) {
      if (ethers.isAddress(value)) {
        return <CheckCircle color="success" />;
      } else {
        return <Error color="error" />;
      }
    }
  };

  const onSubmit = async (data) => {
    setUpdating(true);

    createBotQuickstart(bot.id, { ...data, ...{ type: QUICKSTART_TYPE } })
      .then(() => {
        enqueueSnackbar(i18n.successMessage, { variant: "success" });
        queryClient.invalidateQueries(parentQuery).then(() => {
          onClose();
          setUpdating(false);
        });
      })
      .catch((err) => {
        setUpdating(false);

        const errorMsg =
          err && err.response.data.error
            ? err.response.data.error
            : i18n.errorMessage;
        enqueueSnackbar(errorMsg, { variant: "error" });
      });
  };

  const renderVerify = () => {
    if (ethers.isAddress(contractAddress)) {
      if (!isFetched) {
        return <ProgressLoader />;
      }

      if (data && data.data) {
        return (
          <Box width={"100%"}>
            <RaribleCollectionCard data={data.data} />
          </Box>
        );
      } else {
        return (
          <Alert variant="outlined" severity="error" sx={{ width: "100%" }}>
            Collection Not Found. Please verify contract address above.
          </Alert>
        );
      }
    }
  };

  const renderButtons = () => {
    return (
      <Stack direction="row" mt={1} spacing={1}>
        <Button
          variant="outlined"
          disabled={isUpdating}
          onClick={() => {
            setBlockchain(CHAINS[0]);
            onClose();
          }}
        >
          {i18n.cancelButton}
        </Button>
        <LoadingButton
          variant="contained"
          loading={isUpdating}
          disabled={!isInputValid()}
          type="submit"
        >
          {i18n.updateButton}
        </LoadingButton>
      </Stack>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.modalTitle}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogContent>
          <Typography variant="body">{i18n.modalDescription}</Typography>

          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={3}
            mt={4}
          >
            <Box sx={{ width: "100%" }}>
              <TextField
                {...register(`contract_address`)}
                InputProps={{ endAdornment: renderAdornment(contractAddress) }}
                aria-label={"NFT Contract"}
                fullWidth
                helperText={
                  "NFT Contract Address. Eg. 0x9c8ff314c9bc7f6e59a9d9225fb22946427edc03"
                }
                label={"NFT Contract Address"}
                maxRows={1}
                multiline={false}
                onChange={(e) => setContractAddress(e.target.value)}
                required={true}
                type={"text"}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="blockchain-select">Blockchain</InputLabel>
                <Select
                  {...register("blockchain")}
                  labelId="blockchain-select"
                  value={blockchain}
                  label="Blockchain"
                  onChange={(event) => {
                    setBlockchain(event.target.value);
                  }}
                >
                  {CHAINS.map((chain, idx) => (
                    <MenuItem key={idx} value={chain}>
                      {chain}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {renderVerify()}
          </Stack>
        </DialogContent>

        <DialogActions>{renderButtons()}</DialogActions>
      </form>
    </Dialog>
  );
};
export default BehaviorQuickstartNFTModal;

import React from "react";
import { Badge, Box, Stack, Typography } from "@mui/material";
import { truncate } from "../utils/utils";
import { botAvatar, botTitle } from "../utils/botUtils";
import { botIconFactory } from "../icons/BotIcons";
import { grey } from "@mui/material/colors";

export const BotMenuCard = ({ authentication, type }) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      {
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={botIconFactory(type, "small")}
        >
          {botAvatar(authentication)}
        </Badge>
      }
      <Box>
        {truncate(botTitle(authentication, type), 25)}
        <Typography sx={{ color: grey[600] }} variant={"body2"}>
          {authentication ? authentication.url : ""}
        </Typography>
      </Box>
    </Stack>
  );
};

import { Api } from "../axios.config";

const path = "/groups";

export const createGroup = (payload) => Api.post(path, payload);

export const updateGroup = (id, payload) => Api.put(`${path}/${id}`, payload);

export const getGroup = (id) => Api.get(`${path}/${id}`);

export const getGroupBots = (id, page) =>
  Api.get(`${path}/${id}/index_bots?page=${page}`);

export const getGroups = ({ page = 1, search, publicGroups = true }) => {
  let url = `${path}?page=${page}&public=${publicGroups}`;
  if (search) {
    url = `${url}&search=${search}`;
  }
  return Api.get(url);
};

export const deleteGroup = (id) => Api.delete(`${path}/${id}`);

import { ArrowForward } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Farcaster } from "../../assets/icons/Farcaster";
import { FARCASTER } from "../../constants/providerTypes";
import { useAuthenticationCreate } from "../../hooks/useAuthenticationCreate";
import { useBotCreate } from "../../hooks/useBotCreate";
import { botCreatePayload } from "../utils/botUtils";
import { NNConnect } from "./NNConnect";

const i18n = {
  cancel: "Nevermind",
  channel: "Channel",
  channelHelper:
    "E.g., use 'nouns' for the Nouns channel - you can copy it from the channel URL https://warpcast.com/~/channel/nouns",
  connect: "Connect",
  label: "Connect Farcaster",
  success:
    "Farcaster Account Authorized! If you want to share posts to a specific channel, specify the name below. Otherwise, leave it blank and save the connection.",
};

export const FarcasterConnect = ({
  onClick,
  onConnected,
  label = i18n.label,
}) => {
  const [farcasterAuth, setFarcasterAuth] = useState(null);
  const [isInputOpen, setInputOpen] = useState(false);
  const isAddingBot = useRef(false); // prevents useEffect from running twice

  const {
    call: createBot,
    data: bot,
    isError: isErrorBot,
    isFetched: isFetchedBot,
    isFetching: isFetchingBot,
  } = useBotCreate();

  const {
    call: createAuth,
    data: auth,
    isError: isErrorAuth,
    isFetched: isFetchedAuth,
    isFetching: isFetchingAuth,
  } = useAuthenticationCreate();

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  const hasAuth = auth && isFetchedAuth && !isFetchingAuth && !isErrorAuth;
  const hasBot = bot && isFetchedBot && !isFetchingBot && !isErrorBot;
  const disableButtons =
    isFetchingAuth || (isFetchedAuth && !isFetchingBot) || isFetchingBot;

  useEffect(() => {
    // Creates a new bot when auth is connected.
    // Consider moving bot creation to the back-end for more stability.
    if (hasAuth && !hasBot && !isAddingBot.current) {
      isAddingBot.current = true;
      createBot(botCreatePayload(auth));
    }

    // Closes the modal once everything is done
    if (hasBot && onConnected) {
      onConnected();
    }
  }, [auth, hasBot, hasAuth, onConnected, createBot]);

  const onSubmit = async (data) => {
    if (!farcasterAuth) return;
    createAuth({
      provider: FARCASTER,
      token: farcasterAuth.token,
      fid: farcasterAuth.fid,
      ...data,
    });
  };

  const toggleInput = () => setInputOpen((prev) => !prev);

  const renderInputModal = () => {
    return (
      <Dialog
        open={isInputOpen}
        onClose={() => setInputOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{label}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogContent>
            {farcasterAuth ? (
              <Alert severity="success">{i18n.success}</Alert>
            ) : (
              <NNConnect onConnect={setFarcasterAuth} />
            )}
            {farcasterAuth && (
              <>
                <TextField
                  sx={{ mt: 2 }}
                  {...register("channel_name")}
                  label={i18n.channel}
                  type="text"
                  disabled={!farcasterAuth}
                  variant="outlined"
                  fullWidth
                />
                <Typography
                  mt={1}
                  sx={{ paddingRight: 1.2 }}
                  color={grey[400]}
                  variant="body2"
                >
                  {i18n.channelHelper}
                </Typography>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button disabled={disableButtons} onClick={toggleInput}>
              {i18n.cancel}
            </Button>
            <LoadingButton
              disabled={!farcasterAuth}
              loading={disableButtons}
              type={"submit"}
            >
              <ArrowForward />
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  const onConnect = () => {
    onClick();
    setInputOpen(true);
  };

  return (
    <>
      <LoadingButton
        sx={{ backgroundColor: "#8A63D2" }}
        variant="contained"
        onClick={() => onConnect()}
        startIcon={<Farcaster width={20} height={20} inheritViewBox />}
        loading={disableButtons}
      >
        {label}
      </LoadingButton>
      {isInputOpen && renderInputModal()}
    </>
  );
};

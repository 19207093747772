import React from "react";
import { useNavigate } from "react-router";

import { Button, Card, CardHeader, IconButton } from "@mui/material";

import { truncate } from "../utils/utils";
import { useTheme } from "@mui/material/styles";
import { groupAvatar, groupBotsCount, groupUrl } from "../utils/groupUtils";

import { Group } from "../../types/types";
import { Edit, OpenInNew } from "@mui/icons-material";

interface Props {
  group: Group;
  onClick?: () => void;
}

export const GroupEmbedCard = ({ group, onClick }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { avatars, name, bot_count, id } = group;

  const renderAction = () => {
    if (onClick) {
      return (
        <Button
          color="primary"
          variant="outlined"
          sx={{ mt: 1 }}
          onClick={onClick}
          endIcon={<Edit fontSize="small" />}
        >
          Edit
        </Button>
      );
    } else {
      return (
        <IconButton
          color="primary"
          size="small"
          sx={{ mt: 1 }}
          onClick={() => navigate(groupUrl(id))}
        >
          <OpenInNew fontSize="small" />
        </IconButton>
      );
    }
  };

  return (
    <Card
      sx={{ width: "100%", backgroundColor: theme.palette.grey[800] }}
      variant="outlined"
    >
      <CardHeader
        avatar={groupAvatar(avatars)}
        title={truncate(name, 25)}
        subheader={groupBotsCount(bot_count)}
        action={renderAction()}
      />
    </Card>
  );
};

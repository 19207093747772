import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useAccountCreate } from "../../hooks/useAccountCreate";
import { UserContext } from "../../contexts/UserContext";
import { useLocation, useNavigate } from "react-router-dom";

const i18n = {
  buttonCancel: "nevermind",
  buttonRegister: "Register",
  emailLabel: "Email Address",
  modalTitle: "Welcome to the Fam",
  passwordConfirmLabel: "Password Confirmation",
  passwordLabel: "Password",
  registrationSuccess: "Thanks for signing up!",
  registrationError: "Unable to register your account at this time",
};

const RegisterModal = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { hasUser } = useContext(UserContext);
  const { call: createAndSignIn, isFetching, isFetched } = useAccountCreate();

  const location = useLocation();
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  useEffect(() => {
    if (isFetched && hasUser()) {
      // Redirect to dashboard if login initiated from the home page
      if (location.pathname === "/") {
        navigate("/home");
      }
      onClose();
    }
  }, [isFetched, hasUser, onClose, location, navigate]);

  const onSubmit = ({ email, password, password_confirmation }) => {
    if (password !== password_confirmation) {
      enqueueSnackbar("Password confirmation must match.", {
        variant: "error",
      });
    } else {
      createAndSignIn({ user: { email, password, password_confirmation } });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.modalTitle}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={2} sx={{ marginBottom: 5 }}>
            <TextField
              {...register("email")}
              autoComplete="email"
              label={i18n.emailLabel}
              required
              type="email"
              variant="outlined"
              fullWidth
            />

            <TextField
              {...register("password")}
              label={i18n.passwordLabel}
              required
              type="password"
              variant="outlined"
              fullWidth
            />

            <TextField
              {...register("password_confirmation")}
              label={i18n.passwordConfirmLabel}
              required
              type="password"
              variant="outlined"
              fullWidth
            />
          </Stack>
          <Divider />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{i18n.buttonCancel}</Button>
          <LoadingButton type="submit" variant="contained" loading={isFetching}>
            {i18n.buttonRegister}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RegisterModal;

import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { useBotDelete } from "../../hooks/useBotDelete";

const i18n = {
  cancel: "nevermind",
  confirmationMessage:
    "Are you sure you want to permanently delete this bot? Doing so will also erase all behaviors, historical post data, and remove the bot from any associated group.",
  submit: "delete",
  title: "Delete Bot?",
};

export const BotDeleteModal = ({ bot, open, onClose }) => {
  const { isLoading, call } = useBotDelete();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.title}</DialogTitle>

      <DialogContent sx={{ marginBottom: 2 }}>
        <Typography>{i18n.confirmationMessage}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          aria-label={i18n.cancel}
          disabled={isLoading}
        >
          {i18n.cancel}
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          onClick={() => call(bot.id)}
          loading={isLoading}
          aria-label={i18n.title}
        >
          {i18n.submit}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

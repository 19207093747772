import { editBot } from "../api/v1/bots";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { botQuery } from "./useBot";
import { botsQuery } from "./useBots";

const i18n = {
  success: "Bot updated successfully!",
  error: "Unable unable to update bot at this time.",
};

export const useBotUpdate = () => {
  const [isFetching, setIsFetching] = useState(false);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const invalidateQueries = async (queries) => {
    await Promise.all(
      queries.map((key) => {
        return queryClient.invalidateQueries(key);
      }),
    );
  };

  const call = (id, payload) => {
    setIsFetching(true);

    editBot(id, payload)
      .then(async () => {
        await invalidateQueries([botQuery(id), botsQuery]);
        setIsFetching(false);
        enqueueSnackbar(i18n.success, { variant: "success" });
      })
      .catch((err) => {
        const errorMsg =
          err && err.response.data.error
            ? err.response.data.error[0]
            : i18n.error;

        setIsFetching(false);
        enqueueSnackbar(errorMsg, { variant: "error" });
      });
  };
  return { call, isLoading: isFetching };
};

import { SvgIcon } from "@mui/material";

export const Arbitrum = (props) => {
  const defaultProps = {
    width: 24,
    height: 24,
    ...props,
  };

  return (
    <SvgIcon sx={defaultProps}>
      <path d="M7.97992 8.37799C8.4494 7.61185 9.47044 7.36559 10.4195 7.37855L11.5327 7.40735L4.97152 17.9288L5.74486 18.3738L12.3852 7.41743L15.3202 7.40735L8.6971 18.6403L11.4578 20.2287L11.7876 20.4188C11.9302 20.475 12.0871 20.4778 12.2326 20.4274L14.8435 18.9139L12.0554 14.5388L13.7577 11.6514L17.4184 17.4219L15.6514 18.4458L19.5354 16.1949L18.1385 17.0043L14.2775 10.7585L15.7609 8.24118L19.75 14.4538V15.7456C19.7471 15.9199 19.6679 16.084 19.5354 16.1964L18.1385 17.0057L17.4184 17.4234L14.8435 18.9153L12.2326 20.4289C12.0886 20.4793 11.9302 20.4764 11.7876 20.4202L8.69854 18.6431L8.06777 19.7131L10.8443 21.3117C10.9365 21.3635 11.0186 21.4096 11.0848 21.4485C11.1885 21.5061 11.2591 21.5464 11.285 21.5579C11.5168 21.6602 11.7689 21.712 12.0223 21.7091C12.2556 21.7091 12.486 21.6659 12.7021 21.581L20.2871 17.1886C20.7206 16.8516 20.9813 16.3404 21.0014 15.7917V7.39295C20.9741 6.77802 20.6457 6.21638 20.1244 5.89091L12.8259 1.69442C12.2974 1.43519 11.6796 1.43519 11.1525 1.69442C11.092 1.72466 4.05704 5.8045 4.05704 5.8045C3.95912 5.85203 3.86551 5.90675 3.77766 5.97012C3.31539 6.2999 3.0288 6.82267 3 7.38863V16.0783" />
    </SvgIcon>
  );
};

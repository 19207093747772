import React, { useState } from "react";

import ProgressLoader from "../../components/utils/ProgressLoader";
import { BotBehaviors } from "./BotBehaviors";
import { BotQueue } from "./BotQueue";
import { BotShared } from "./BotShared";
import { Error } from "./components/Error";
import { Header } from "./components/Header";
import { Routes, Route, useParams } from "react-router";
import { Tab, Tabs, Box, Badge } from "@mui/material";
import { useBot } from "../../hooks/useBot";
import { useNavigate } from "react-router-dom";
import { BotStats } from "./BotStats";
import Layout from "../../components/Layout";
import { useLocation } from "react-router-dom";

const i18n = {
  behaviorsTabLabel: "Behaviors",
  error:
    "Error loading bot... This bot either does not exist or is set to private.",
  queueTabLabel: "Queue",
  sharedTabLabel: "Shared",
  statsTabLabel: "Analytics",
};

const PATH_BEHAVIORS = "behaviors";
const PATH_QUEUE = "queue";
const PATH_SHARED = "shared";
const PATH_ANALYTICS = "analytics";

const Bot = () => {
  const { id } = useParams();
  const location =
    useLocation().pathname.split("/").slice(-1)[0] || PATH_BEHAVIORS;
  const [path, setPath] = useState(location);
  const navigate = useNavigate();
  const { data: bot, isFetched, isError, query } = useBot({ id });

  if (!isFetched) {
    return (
      <Layout>
        <ProgressLoader />
      </Layout>
    );
  }

  if (isError || !bot) {
    return <Layout>{i18n.error}</Layout>;
  }

  const onTabsChange = (event, value) => {
    navigate(value ? `${query}/${value}` : query);
    setPath(value);
  };

  const getRoutes = () => {
    const tabProps = {
      bot: bot.data,
      parentQuery: query,
    };

    return [
      {
        badge: false,
        element: <BotBehaviors bot={bot.data} invalidateQuery={query} />,
        label: `${i18n.behaviorsTabLabel} (${bot.data.stats.behaviors})`,
        path: PATH_BEHAVIORS,
      },
      {
        badge: false,
        element: <BotQueue {...tabProps} />,
        label: `${i18n.queueTabLabel} (${bot.data.stats.queue})`,
        path: PATH_QUEUE,
      },
      {
        badge: false,
        element: <BotShared {...tabProps} />,
        label: `${i18n.sharedTabLabel} (${bot.data.stats.shared})`,
        path: PATH_SHARED,
      },
      {
        badge: false,
        element: <BotStats {...tabProps} />,
        label: `${i18n.statsTabLabel}`,
        path: PATH_ANALYTICS,
      },
    ];
  };

  const renderTabLabel = (label, badge) => {
    if (badge) {
      return (
        <Badge color="error" variant="dot">
          {label}
        </Badge>
      );
    }
    return label;
  };

  return (
    <Layout>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}>
        <Header bot={bot.data} />
        <Error bot={bot.data} invalidateQuery={query} />
        <Tabs onChange={onTabsChange} value={path}>
          {getRoutes().map(({ path, label, badge }) => {
            return (
              <Tab
                value={path}
                label={renderTabLabel(label, badge)}
                key={path}
              />
            );
          })}
        </Tabs>
      </Box>

      <Routes>
        {getRoutes().map((item, idx) => (
          <Route exact key={idx} {...item} />
        ))}
      </Routes>
    </Layout>
  );
};

export default Bot;

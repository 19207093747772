import { useState } from "react";
import { useSnackbar } from "notistack";
import { capitalize } from "../components/utils/utils";
import { useQueryClient } from "react-query";
import { deleteAuthentication } from "../api/v1/authentications";
import { useLoggedUserQuery } from "./useLoggedUser";
import { botsQuery } from "./useBots";
import { botQuery } from "./useBot";
import { invalidateQueries } from "../components/utils/reactQueryUtils";

const i18n = {
  success: (provider) => `${capitalize(provider)} account disconnected.`,
  error: (provider) =>
    `Unable to disconnect ${capitalize(
      provider,
    )} account at this time. Please try again.`,
};

export const useAuthenticationDelete = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [isFetching, setFetching] = useState(false);

  const userQuery = useLoggedUserQuery();

  const call = (id, botId = null) => {
    setFetching(true);
    deleteAuthentication(id)
      .then(async () => {
        const invalidQueries = [botsQuery, userQuery];
        if (botId) {
          invalidQueries.push(botQuery(botId));
        }
        await invalidateQueries(queryClient, invalidQueries);

        setFetching(true);
        enqueueSnackbar(i18n.success("provider"), { variant: "success" });
      })
      .catch((err) => {
        setFetching(false);
        const errorMsg =
          err && err.response.data.error
            ? err.response.data.error
            : i18n.error("provider");
        enqueueSnackbar(errorMsg, { variant: "error" });
      });
  };
  return { call, isFetching };
};

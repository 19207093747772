import { ArrowBack } from "@mui/icons-material";
import { IconButton, Pagination } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { CollectionCard } from "../../components/cards/CollectionCard";
import { CardListing } from "../../components/layout/CardListing";
import ProgressLoader from "../../components/utils/ProgressLoader";
import { useCollections } from "../../hooks/useCollections";
import { Header } from "./components/Header";

const i18n = {
  error: "We are unable to load data for this page. Please try again later.",
};

const Collections = () => {
  const [page, setPage] = useState(
    Number(new URLSearchParams(window.location.search).get("page")) || 1,
  );
  const search =
    new URLSearchParams(window.location.search).get("search") || null;

  const navigate = useNavigate();
  const {
    data: collections,
    isError,
    isFetched,
  } = useCollections({ enabled: true, page, search });

  const renderPagination = () => {
    const { total_pages } = collections.data;
    if (total_pages > 1) {
      return (
        <Pagination
          page={page}
          count={total_pages}
          onChange={(event, page) => {
            setPage(page);
            navigate(`?page=${page}`);
          }}
        />
      );
    }
  };

  if (!isFetched) {
    return (
      <Layout>
        <ProgressLoader />
      </Layout>
    );
  }

  if (isError || !collections) {
    return <Layout>{i18n.error}</Layout>;
  }

  return (
    <Layout>
      <IconButton
        sx={{ marginTop: -5 }}
        size="small"
        onClick={() => navigate(-1)}
      >
        <ArrowBack />
      </IconButton>
      <Header filter={true} />

      <CardListing>
        {collections.data.data.map((collection) => (
          <CollectionCard key={collection.id} collection={collection} />
        ))}
      </CardListing>
      {renderPagination()}
    </Layout>
  );
};
export default Collections;

import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import { LocalOffer, CheckCircle } from "@mui/icons-material";
import ProgressLoader from "../utils/ProgressLoader";
import { useSubscriptionInfo } from "../../hooks/useSubscriptionInfo";
import { STRIPE_SUBSCRIPTION } from "../../constants/subscriptionTypes";
import moment from "moment";
import { grey } from "@mui/material/colors";
import { formatPrice } from "../utils/utils";

const i18n = {
  colCharge: "Charge",
  colDate: "Date",
  colPayment: "Payment Method",
  colReceipt: "Receipt",
  colStatus: "Status",
  paymentStatus: (status, refunded) => {
    if (refunded) {
      return "Refunded";
    }
    return status ? "Successful" : "Error";
  },
  planInterval: (interval) => (interval === "year" ? "annual" : "monthly"),
  planTitle: (subscription) =>
    `You are subscribed to the ${i18n.planInterval(
      subscription.details.interval,
    )} plan priced at ${formatPrice(
      subscription.details.amount,
      subscription.details.currency,
    )}.`,
  planDescription: ({ card, currency, date, discount, amount }) =>
    `The next payment is due on ${date}, and your ${card} will be charged ${formatPrice(
      amount,
      currency,
    )}${
      discount ? ` after a ${discount.percent_off}% discount is applied` : ""
    }.`,
  planDiscount: (discount) =>
    `Discount coupon (${discount.coupon}) applies ${
      discount.end_date ? `until ${discount.end_date}.` : "indefinitely."
    }`,
  planFetchError: "Unable to load plan details at this time.",
  planExpiration: (exp) => `Expires on ${exp.format("MMM Do, YYYY")}`,
  viewLink: "View",
};

const SubscriptionInfoModal = ({ plan, onClose, open }) => {
  const isStripe = plan.type === STRIPE_SUBSCRIPTION;

  const {
    isFetched,
    isError,
    data: sub,
  } = useSubscriptionInfo({ enabled: isStripe, id: plan.id });

  const expDate = moment(plan.expiration_date);

  const renderContent = () => {
    return isStripe ? renderStripePlan() : renderCustomPlan();
  };

  const renderCustomPlan = () => {
    return (
      <Stack direction="column" spacing={0.5}>
        <Typography>{plan.description}</Typography>
        {expDate.isValid() && (
          <Typography color={grey[600]}>
            {i18n.planExpiration(expDate)}
          </Typography>
        )}
      </Stack>
    );
  };

  const renderStripePlan = () => {
    if (!isFetched) {
      return <ProgressLoader />;
    }

    if ((isFetched && !sub) || isError) {
      return i18n.planFetchError;
    }

    const { next_payment_date, discount } = sub.data.details;
    let next_payment_amount = sub.data.details.amount;

    // Apply discount to the final amount
    if (discount) {
      next_payment_amount =
        next_payment_amount -
        next_payment_amount * (discount.percent_off / 100);
    }

    return (
      <Box>
        <Typography variant="body1" mb={2}>
          {i18n.planTitle(sub.data)}
        </Typography>
        <Typography color={grey[400]} variant="body1">
          {i18n.planDescription({
            card: plan.payment_method,
            currency: sub.data.details.currency,
            date: next_payment_date,
            discount,
            amount: next_payment_amount,
          })}
        </Typography>

        {discount && (
          <Stack
            color={grey[400]}
            sx={{ marginTop: 2 }}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <LocalOffer fontSize="small" />
            <Typography variant="body1">
              {i18n.planDiscount(discount)}
            </Typography>
          </Stack>
        )}
        <Divider sx={{ marginTop: 4 }} />
        {renderPaymentHistory()}
      </Box>
    );
  };

  const renderPaymentHistory = () => {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{i18n.colStatus}</TableCell>
              <TableCell>{i18n.colDate}</TableCell>
              <TableCell>{i18n.colCharge}</TableCell>
              <TableCell>{i18n.colPayment}</TableCell>
              <TableCell>{i18n.colReceipt}</TableCell>
            </TableRow>
            {sub.data.payment_history.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    {renderStatusIcon(row.paid, row.refunded)}
                  </TableCell>
                  <TableCell>{row.created}</TableCell>
                  <TableCell>{formatPrice(row.amount, row.currency)}</TableCell>
                  <TableCell>{`${row.card_brand} ${row.card_last4}`}</TableCell>
                  <TableCell>
                    <Link
                      href={row.receipt_url}
                      target="_blank"
                      underline="hover"
                    >
                      {i18n.viewLink}
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody></TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderStatusIcon = (status, refunded) => {
    let color = status ? "success" : "error";
    if (refunded) {
      color = "info";
    }
    return (
      <Tooltip title={i18n.paymentStatus(status, refunded)}>
        <CheckCircle color={color} />
      </Tooltip>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle id="dialog-title">{plan.name} Plan</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
    </Dialog>
  );
};
export default SubscriptionInfoModal;

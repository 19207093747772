import { Collections, Favorite, People, Visibility } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import humanize from "humanize-number";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import { CardListing } from "../../components/layout/CardListing";
import ProgressLoader from "../../components/utils/ProgressLoader";
import { useAccountStats } from "../../hooks/useAccountStats";

const i18n = {
  pageHeader: "90-day analytics",
  statsEmptyError: "No stats collected for this account...",
  statsLoadError: "Unable to load stats at this time...",
};

export const AccountStats = ({ account }) => {
  const { data: stats, isError, isFetched } = useAccountStats({ account });

  if (isError) {
    return i18n.postLoadError;
  }

  if (!isFetched) {
    return <ProgressLoader />;
  }

  const renderTooltipContent = (o) => {
    const { payload } = o;

    if (!payload || payload.length === 0) {
      return;
    }

    const valHash = {
      daily_shares: "Shares",
      daily_likes: "Likes",
      daily_comments: "Comments",
      daily_impressions: "Impressions",
      post_count: "Posts",
      value: "Followers",
    };

    const { stat_date } = payload[0].payload;

    return (
      <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.3)", p: 0.5 }}>
        <Typography variant={"body2"}>{stat_date}</Typography>

        {payload.map((entry, index) => (
          <Typography variant={"body2"} key={`item-${index}`}>
            {`${valHash[entry.name]}: ${humanize(entry.value)}`}
          </Typography>
        ))}
      </Box>
    );
  };

  const { all_engagement: engagement, follower_counts } = stats.data;

  const hasFollowerData = follower_counts.length > 0;
  const newFollowers = hasFollowerData
    ? follower_counts[follower_counts.length - 1]["total_followers"] -
      follower_counts[0]["total_followers"]
    : 0;

  if (engagement.length === 0) {
    return i18n.statsEmptyError;
  }

  const likes = engagement.reduce((accum, item) => item.daily_likes + accum, 0);
  const shares = engagement.reduce(
    (accum, item) => item.daily_shares + accum,
    0,
  );
  const impressions = engagement.reduce(
    (accum, item) => item.daily_impressions + accum,
    0,
  );
  const posts = engagement.reduce((accum, item) => item.post_count + accum, 0);

  return (
    <Box>
      {i18n.pageHeader}

      <CardListing mb={10}>
        <Card sx={{ marginRight: 1, marginBottom: 1, width: 320 }} raised>
          <CardHeader
            title={`${humanize(likes + shares)} Likes + Retweets`}
            subheader={"in the last 90 days"}
            avatar={
              <Avatar>
                <Favorite />
              </Avatar>
            }
          />
          <CardContent>
            <Box width={"100%"} height={"100px"}>
              <ResponsiveContainer>
                <AreaChart data={engagement} syncId="anyId">
                  <Tooltip content={renderTooltipContent} />
                  <Area
                    type="monotone"
                    dataKey="daily_likes"
                    stackId="1"
                    stroke={blue[900]}
                    fill={blue[900]}
                  />
                  <Area
                    type="monotone"
                    dataKey="daily_shares"
                    stackId="1"
                    stroke={blue[500]}
                    fill={blue[400]}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card>

        <Card sx={{ marginRight: 1, marginBottom: 1, width: 320 }} raised>
          <CardHeader
            title={`${humanize(impressions)} Impressions`}
            subheader={"in the last 90 days"}
            avatar={
              <Avatar>
                <Visibility />
              </Avatar>
            }
          />
          <CardContent>
            <Box width={"100%"} height={"100px"}>
              <ResponsiveContainer>
                <AreaChart syncId="anyId" data={engagement}>
                  <Tooltip content={renderTooltipContent} />
                  <Area
                    type="monotone"
                    dataKey="daily_impressions"
                    stackId="1"
                    stroke={blue[500]}
                    fill={blue[400]}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card>

        {/* <Card sx={{ marginRight: 1, marginBottom: 1, width: 320 }} raised>
          <CardHeader
            title={"Likes By Post Type"}
            subheader={"in the last 90 days"}
            avatar={
              <Avatar>
                <Visibility />
              </Avatar>
            }
          />
          <CardContent>
            <Box width={"100%"} height={"100px"}>
              <ResponsiveContainer>
                <AreaChart syncId="anyId" data={post_type_data}>
                  <Tooltip content={renderTooltipContent} />
                  {Array.from(post_types).forEach((post_type, index) => {
                    <Area
                      type="monotone"
                      dataKey={post_type}
                      stackId={index + 1}
                      stroke={blue[900]}
                      fill={blue[30 * index]}
                    />;
                  })}
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card> */}

        <Card sx={{ marginRight: 1, marginBottom: 1, width: 320 }} raised>
          <CardHeader
            title={`${humanize(posts)} Posts`}
            subheader={"in the last 90 days"}
            avatar={
              <Avatar>
                <Collections />
              </Avatar>
            }
          />
          <CardContent>
            <Box width={"100%"} height={"100px"}>
              <ResponsiveContainer>
                <AreaChart syncId="anyId" data={engagement}>
                  <Tooltip content={renderTooltipContent} />
                  <Area
                    type="monotone"
                    dataKey="post_count"
                    stackId="1"
                    stroke={blue[500]}
                    fill={blue[400]}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card>
        {hasFollowerData && (
          <Card sx={{ marginRight: 1, marginBottom: 1, width: 320 }} raised>
            <CardHeader
              title={`${humanize(newFollowers)} New followers`}
              subheader={"in the last 90 days"}
              avatar={
                <Avatar>
                  <People />
                </Avatar>
              }
            />
            <CardContent>
              <Box width={"100%"} height={"100px"}>
                <ResponsiveContainer>
                  <AreaChart syncId="anyId" data={follower_counts}>
                    <Tooltip content={renderTooltipContent} />
                    <Area
                      type="monotone"
                      dataKey="total_followers"
                      stackId="1"
                      stroke={blue[500]}
                      fill={blue[400]}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        )}
      </CardListing>
    </Box>
  );
};

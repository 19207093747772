import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import ProgressLoader from "../utils/ProgressLoader";
import { CardListing } from "../layout/CardListing";
import { GROUP_BOT_ACTION_ADD, GroupBotCard } from "../cards/GroupBotCard";
import { useBots } from "../../hooks/useBots";
import { grey } from "@mui/material/colors";

const i18n = {
  modalTitle: "Search Bots",
  error: "Error fetching bots...",
  placeholder: "Search Public Bots",
  success: (items) =>
    items.length === 0
      ? "No bots matched your search criteria"
      : `Found ${items.length} bot${items.length === 1 ? "" : "s"}`,
};

export const GroupBotAddModal = ({ onClose, open, group }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const {
    data: bots,
    isError: isErrorDefault,
    isFetching: isFetchingDefault,
  } = useBots({ enabled: true, search: searchTerm });

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  const onSubmit = ({ keyword }) => {
    if (keyword === null || keyword === undefined || keyword === "") {
      keyword = null;
    }
    setSearchTerm(keyword);
  };

  const renderBots = () => {
    if (isErrorDefault) {
      return i18n.error;
    }

    if (isFetchingDefault || !bots) {
      return (
        <Box mt={2}>
          <ProgressLoader />
        </Box>
      );
    }

    return (
      <Box mt={2}>
        <>
          <Typography color={grey[500]}>
            {i18n.success(bots.data.data)}
          </Typography>
          <CardListing>
            {bots.data.data.map((bot) => (
              <GroupBotCard
                key={`group-${bot.id}`}
                action={GROUP_BOT_ACTION_ADD}
                group={group}
                bot={bot}
              />
            ))}
          </CardListing>
        </>
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle id="dialog-title">{i18n.modalTitle}</DialogTitle>
      <DialogContent sx={{ minHeight: 500 }}>
        <Box sx={{ width: "100%" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              sx={{ width: "100%" }}
              {...register("keyword")}
              defaultValue={searchTerm}
              variant="outlined"
              size="small"
            />
          </form>
        </Box>
        {renderBots()}
      </DialogContent>
    </Dialog>
  );
};

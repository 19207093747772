import { useAuthenticationDelete } from "../../hooks/useAuthenticationDelete";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { Card, CardHeader, Link } from "@mui/material";
import { authTitle, authURL, authAvatar } from "../utils/authenticationUtils";
import { truncate } from "../utils/utils";

const i18n = {
  disconnect: "disconnect",
};

const AuthenticationDisconnectCard = ({ authentication, bot }) => {
  const { call, isFetching } = useAuthenticationDelete();

  return (
    <Card variant="outlined" sx={{ background: "none" }}>
      <CardHeader
        avatar={authAvatar(authentication)}
        title={authTitle(authentication)}
        subheader={
          <Link href={authURL(authentication)} target="_blank">
            {truncate(authURL(authentication), 35)}
          </Link>
        }
        action={
          <LoadingButton
            aria-label={i18n.disconnect}
            loading={isFetching}
            onClick={() => call(authentication.id, bot ? bot.id : null)}
            variant="outlined"
            color="warning"
          >
            {i18n.disconnect}
          </LoadingButton>
        }
      />
    </Card>
  );
};

export default AuthenticationDisconnectCard;

import React from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useCollectionUpdate } from "../../hooks/useCollectionUpdate";
import { collectionVisibility } from "../utils/collectionUtils";

const i18n = {
  cancel: "Nevermind",
  collectionTitle: "Collection Title",
  title: "Update Collection",
  submit: "Update",
  publicOn:
    "Anyone can view or use this collection, but only you can manage its contents.",
  publicOff: "Only you can view or edit this collection.",
};

export const CollectionUpdateModal = ({ collection, open, onClose }) => {
  const { call, isFetching } = useCollectionUpdate();

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  const onSubmit = (payload) => {
    call(collection.id, payload);
  };

  const onPublicUpdate = () => {
    call(collection.id, { public: !collection.public });
  };

  const renderButtons = () => {
    return (
      <Stack direction="row" sx={{ marginTop: 1 }} spacing={1}>
        <Button variant="outlined" disabled={isFetching} onClick={onClose}>
          {i18n.cancel}
        </Button>
        <LoadingButton variant="contained" loading={isFetching} type="submit">
          {i18n.submit}
        </LoadingButton>
      </Stack>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.title}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <Box sx={{ width: "100%" }}>
              <TextField
                fullWidth
                {...register("title")}
                aria-label={i18n.collectionTitle}
                defaultValue={collection ? collection.title : null}
                label={i18n.collectionTitle}
                required={true}
                type="text"
              />
            </Box>

            <Card variant={"outlined"} sx={{ background: "none" }}>
              <CardHeader
                title={
                  <Typography variant={"body1"}>
                    {collectionVisibility(collection.public)}
                  </Typography>
                }
                subheader={collection.public ? i18n.publicOn : i18n.publicOff}
                action={
                  <Switch
                    disabled={isFetching}
                    checked={collection.public}
                    onChange={onPublicUpdate}
                  />
                }
              />
            </Card>
          </Stack>
        </DialogContent>
        <DialogActions>{renderButtons()}</DialogActions>
      </form>
    </Dialog>
  );
};

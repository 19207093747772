import React from "react";
import Box from "@mui/material/Box";

export const CardListing = ({ children, ...props }) => (
  <Box
    display="flex"
    flexWrap="wrap"
    justifyContent="flex-start"
    alignItems="top"
    width="100%"
    mt={3}
    mb={1}
    {...props}
  >
    {children}
  </Box>
);

import { useQuery } from "react-query";
import { getBot } from "../api/v1/bots";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";

export const botQuery = (id) => `/bots/${id}`;

export const useBot = ({ id }) => {
  const userContext = useContext(UserContext);
  const query = botQuery(id);
  const { deleteUser } = userContext;

  const { data, isFetched, isError, remove } = useQuery(
    query,
    () => getBot(id),
    {
      refetchOnWindowFocus: true,
      onError: (err) => {
        if (err.response.status === 401) {
          remove();
          deleteUser();
        }
      },
    },
  );

  return { data, isError, isFetched, query };
};

import { useQuery } from "react-query";
import { LONG_STALE } from "../api/axios.config";
import { getBehaviorTemplate } from "../api/v1/behavior_templates";

export const behaviorTemplateQuery = (id) => `/behavior_templates/${id}`;

export const useBehaviorTemplate = (id) => {
  const query = behaviorTemplateQuery(id);

  const { data, isFetched, isFetching, isError, remove } = useQuery(
    query,
    () => getBehaviorTemplate(id),
    {
      refetchOnWindowFocus: false,
      staleTime: LONG_STALE,
      onError: (err) => {
        if (err.response.status === 401) {
          remove();
        }
      },
    },
  );
  return { data, isError, isFetched, isFetching, query };
};

import React, { useContext, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import BehaviorCard from "../../components/cards/BehaviorCard";
import BehaviorQuickstartBuilderModal from "../../components/modals/BehaviorQuickstartBuilderModal";
import BehaviorQuickstartNFTModal from "../../components/modals/BehaviorQuickstartNFTModal";
import { BehaviorAddModal } from "../../components/modals/BehaviorAddModal";
import { BotFrensBlue } from "../../assets/Logos";
import { CardListing } from "../../components/layout/CardListing";
import { Dashboard, DashboardCustomize } from "@mui/icons-material";
import { Builder } from "../../assets/icons/Builder";
import { UserContext } from "../../contexts/UserContext";

const i18n = {
  behaviorsButton: "Add Behaviors",
  quickstartButton: "Bot Quickstart",
  quickstartNftBot: "NFT Bot",
  quickstartNftBotDescription:
    "Sales, listings, stats, from all major marketplaces",
  quickstartNounsBot: "Builder DAO Bot",
  quickstartNounsBotDescription: "Works with Nouns and Builder DAO contracts",
};

export const BotBehaviors = ({ bot, parentQuery }) => {
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isBuilderQuickstartOpen, setBuilderQuickstartOpen] = useState(false);
  const [isNFTQuickstartOpen, setNFTQuickstartOpen] = useState(false);
  const { hasUser } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [botMenuOpen, setBotMenuOpen] = useState(false);

  const { behaviors } = bot;

  const belongsToUser = () => {
    return hasUser() && bot.is_owner;
  };

  const modalProps = {
    bot,
    parentQuery,
    open: isAddModalOpen,
    onClose: () => setAddModalOpen(false),
  };

  const quickstartBuilderModalProps = {
    bot,
    parentQuery,
    open: isBuilderQuickstartOpen,
    onClose: () => setBuilderQuickstartOpen(false),
  };

  const quickstartNFTModalProps = {
    bot,
    open: isNFTQuickstartOpen,
    onClose: () => setNFTQuickstartOpen(false),
  };

  const onBotMenuClick = (event) => {
    setBotMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const onBotMenuClose = () => {
    setBotMenuOpen(false);
  };

  return (
    <Box>
      {belongsToUser() && (
        <>
          <Button
            size="small"
            aria-label={i18n.quickstartButton}
            onClick={onBotMenuClick}
            aria-controls={botMenuOpen ? "bot-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={botMenuOpen ? "true" : undefined}
          >
            <Dashboard sx={{ mr: 1 }} />
            {i18n.quickstartButton}
          </Button>

          <Button
            size="small"
            aria-label={i18n.behaviorsButton}
            color="primary"
            onClick={() => setAddModalOpen(true)}
          >
            <DashboardCustomize sx={{ mr: 1 }} />
            {i18n.behaviorsButton}
          </Button>

          <Menu
            anchorEl={anchorEl}
            id="bot-menu"
            open={botMenuOpen}
            onClose={onBotMenuClose}
            onClick={onBotMenuClose}
          >
            <MenuItem onClick={() => setNFTQuickstartOpen(true)}>
              <ListItemIcon>
                <Avatar variant="rounded" sx={{ width: 38, height: 38, mr: 1 }}>
                  <BotFrensBlue />
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={i18n.quickstartNftBot}
                secondary={i18n.quickstartNftBotDescription}
              />
            </MenuItem>
            <MenuItem onClick={() => setBuilderQuickstartOpen(true)}>
              <ListItemIcon>
                <Avatar
                  variant="rounded"
                  sx={{ mr: 1, backgroundColor: "#ffc229" }}
                >
                  <Builder width={36} height={36} />
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={i18n.quickstartNounsBot}
                secondary={i18n.quickstartNounsBotDescription}
              />
            </MenuItem>
          </Menu>
        </>
      )}

      <CardListing>
        {behaviors.map((behavior, idx) => (
          <BehaviorCard
            key={`behavior-${idx}`}
            {...{ bot, behavior, parentQuery }}
          />
        ))}
      </CardListing>

      {isAddModalOpen && belongsToUser() && (
        <BehaviorAddModal {...modalProps} />
      )}

      {isBuilderQuickstartOpen && belongsToUser() && (
        <BehaviorQuickstartBuilderModal {...quickstartBuilderModalProps} />
      )}

      {quickstartNFTModalProps && belongsToUser() && (
        <BehaviorQuickstartNFTModal {...quickstartNFTModalProps} />
      )}
    </Box>
  );
};

import { ScheduleSend } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useState } from "react";
import { useBehaviorTemplate } from "../../hooks/useBehaviorTemplate";
import { useBehaviorTemplateStats } from "../../hooks/useBehaviorTemplateStats";
import { AuthenticationListModal } from "../modals/AuthenticationListModal";
import { BehaviorQuickstartModal } from "../modals/BehaviorQuickstartModal";
import ProgressLoader from "../utils/ProgressLoader";

const i18n = {
  submit: "Start Sharing",
  error: "Loading error...",
  manualSchedule: "select post count and timing",
  autoSchedule: "triggered by onchain events",
  bots: (count) => `Shared by ${count} account${count === 1 ? "" : "s"}`,
  behaviors: (count) => `${count} behavior${count === 1 ? "" : "s"}`,
};

export const BehaviorTemplateCard = ({ id }) => {
  const { data, isFetched } = useBehaviorTemplate(id);
  const { data: stats, isFetched: isFetchedStats } =
    useBehaviorTemplateStats(id);

  const [isOpen, setOpen] = useState(false);
  const [isViewOpen, setViewOpen] = useState(false);

  // Replicate with total posts total_posts
  const hasStats = Boolean(
    isFetchedStats && stats?.data && stats?.data?.bots?.length > 0,
  );

  if (!isFetched) {
    return (
      <CardWrapper>
        <ProgressLoader />
      </CardWrapper>
    );
  }

  if (isFetched && !data) {
    return <CardWrapper>{i18n.error}</CardWrapper>;
  }

  const template = data?.data;

  const renderAvatars = () => {
    return (
      <AvatarGroup spacing={"small"}>
        {stats.data.bots.map((bot, idx) => {
          if (bot.authentication && idx < 4) {
            return (
              <Avatar
                key={`avatar-${idx}`}
                sx={{ width: 24, height: 24 }}
                src={bot.authentication.avatar.sm}
              />
            );
          }
          return null;
        })}
      </AvatarGroup>
    );
  };

  return (
    <Card sx={{ marginBottom: 1, marginRight: 1, width: 290 }} raised>
      <CardHeader
        title={template.name}
        subheader={<Box>{template.description}</Box>}
      />

      <CardContent>
        {hasStats && (
          <Stack
            sx={{ cursor: "pointer" }}
            spacing={1}
            direction="row"
            onClick={() => setViewOpen(true)}
          >
            {renderAvatars()}
            <Typography variant="overline" color={grey[600]}>
              {i18n.bots(stats.data.bots.length)}
            </Typography>
          </Stack>
        )}

        <Button
          sx={{ mt: 2 }}
          size="large"
          fullWidth
          variant="outlined"
          startIcon={<ScheduleSend />}
          onClick={() => setOpen(true)}
        >
          {i18n.submit}
        </Button>
      </CardContent>

      {isOpen && (
        <BehaviorQuickstartModal
          template={template}
          open={isOpen}
          onClose={() => setOpen(false)}
        />
      )}

      {hasStats && isViewOpen && (
        <AuthenticationListModal
          open={isViewOpen}
          onClose={() => setViewOpen(false)}
          authentications={stats.data.bots.map((b) => b.authentication)}
        />
      )}
    </Card>
  );
};

const CardWrapper = ({ children }) => {
  return (
    <Card sx={{ marginBottom: 1, marginRight: 1, width: 290 }} raised>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

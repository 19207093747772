import { ArrowBack } from "@mui/icons-material";
import { IconButton, Pagination } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { BotCard } from "../../components/cards/BotCard";
import Layout from "../../components/Layout";
import { CardListing } from "../../components/layout/CardListing";
import ProgressLoader from "../../components/utils/ProgressLoader";
import { constructUrlWithParams } from "../../components/utils/utils";
import { useBots } from "../../hooks/useBots";
import { Header } from "./components/Header";

const i18n = {
  noBotsMessage: "No bots were found matching that search criteria.",
  error: "We are unable to load data for this page. Please try again later.",
};

const Bots = () => {
  const type = new URLSearchParams(window.location.search).get("type") || null;
  const autopilot =
    new URLSearchParams(window.location.search).get("autopilot") || null;
  const [page, setPage] = useState(
    Number(new URLSearchParams(window.location.search).get("page")) || 1,
  );
  const order =
    new URLSearchParams(window.location.search).get("order_by") || null;
  const search =
    new URLSearchParams(window.location.search).get("search") || null;

  const navigate = useNavigate();
  const {
    data: bots,
    isFetched,
    isError,
  } = useBots({
    autopilot,
    enabled: true,
    order,
    page,
    search,
    type,
  });

  const renderPagination = () => {
    const { total_pages } = bots.data;
    if (total_pages > 1) {
      return (
        <Pagination
          page={page}
          count={total_pages}
          onChange={(event, page) => {
            setPage(page);
            navigate(
              constructUrlWithParams([
                { autopilot },
                { order_by: order },
                { page },
                { type },
              ]),
            );
          }}
        />
      );
    }
  };

  if (!isFetched) {
    return (
      <Layout>
        <ProgressLoader />
      </Layout>
    );
  }

  if (isError || !bots) {
    return <Layout>{i18n.error}</Layout>;
  }

  return (
    <Layout>
      <IconButton
        sx={{ marginTop: -5 }}
        size="small"
        onClick={() => navigate(-1)}
      >
        <ArrowBack />
      </IconButton>
      <Header filter={true} />

      <CardListing>
        {bots.data.data.length === 0 && i18n.noBotsMessage}
        {bots.data.data.map((bot) => (
          <BotCard key={bot.id} {...bot} />
        ))}
      </CardListing>
      {renderPagination()}
    </Layout>
  );
};

export default Bots;

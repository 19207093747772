import React from "react";
import { Dialog, DialogContent, Stack } from "@mui/material";
import { AuthenticationCard } from "../cards/AuthenticationCard";

export const AuthenticationListModal = ({ authentications, onClose, open }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Stack spacing={1}>
          {authentications.map((auth) => {
            return auth ? (
              <AuthenticationCard
                key={`auth-${auth.id}`}
                authentication={auth}
              />
            ) : null;
          })}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

import { useSnackbar } from "notistack";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { deleteContent } from "../api/v1/content.api";
import { collectionQuery } from "./useCollection";
import { collectionContentsQuery } from "./useCollectionContents";
import { collectionsQuery } from "./useCollections";

const i18n = {
  success: "Content deleted successfully.",
  error:
    "We are unable to delete this content at this time. Please try again later.",
};

export const useContentDelete = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [isFetching, setIsFetching] = useState(false);

  const invalidateQueries = async (queries) => {
    await Promise.all(
      queries.map((key) => {
        return queryClient.invalidateQueries(key);
      }),
    );
  };

  const call = (contentId, collectionId, page) => {
    setIsFetching(true);
    deleteContent(contentId)
      .then(async () => {
        await invalidateQueries([
          collectionsQuery(),
          collectionContentsQuery(collectionId, page),
          collectionQuery(collectionId),
        ]);

        setIsFetching(true);
        enqueueSnackbar(i18n.success, { variant: "success" });
      })
      .catch((err) => {
        setIsFetching(true);
        const errorMsg =
          err && err.response.data.error ? err.response.data.error : i18n.error;
        enqueueSnackbar(errorMsg, { variant: "error" });
      });
  };
  return { call, isFetching };
};

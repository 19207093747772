export const FeaturedBots = [
  {
    avatar: "images/home_rareprotocol.jpeg",
    title: "Rare Protocol",
    url: "https://twitter.com/rareprotocolbot",
    username: "rareprotocolbot",
  },
  {
    avatar: "images/home_braindrops.jpeg",
    title: "BrainDrops Marketplace",
    url: "https://twitter.com/braindrops_bot",
    username: "braindrops_bot",
  },
  {
    avatar: "images/home_nyancat.jpeg",
    title: "Nyan Cat",
    url: "https://twitter.com/NyanCatSales",
    username: "NyanCatSales",
  },
  {
    avatar: "images/home_rarible.png",
    title: "Rarible Marketplace",
    url: "https://twitter.com/rarible_bot",
    username: "rarible_bot",
  },
  {
    avatar: "images/home_highlight.png",
    title: "Highlight Marketplace",
    url: "https://twitter.com/highlight_pulse",
    username: "highlight_pulse",
  },
  {
    avatar: "images/home_nouns.jpeg",
    title: "Nouns DAO",
    url: "https://twitter.com/nouns_bot",
    username: "nouns_bot",
  },
  {
    avatar: "images/home_artblocks.jpeg",
    title: "ArtBlocks Marketplace",
    url: "https://warpcast.com/artblocks-bot",
    username: "artblocks-bot",
  },
  {
    avatar: "images/home_builder.jpeg",
    title: "Nouns Builder DAO",
    url: "https://twitter.com/builder_dao_bot",
    username: "builder_dao_bot",
  },
  {
    avatar: "images/home_cryptoadz.jpeg",
    title: "CrypToadz by GREMPLIN",
    url: "https://twitter.com/cryptoadzBot",
    username: "cryptoadzBot",
  },
  {
    avatar: "images/home_brinkman.jpeg",
    title: "Bryan Brinkman",
    url: "https://twitter.com/BrinkmanBot",
    username: "BrinkmanBot",
  },
];

import React, { createContext, useState } from "react";
import { deleteSession } from "../api/v1/sessions";
import LoginModal from "../components/modals/LoginModal";
import RegisterModal from "../components/modals/RegisterModal";

export const UserContext = createContext(null);

const UserContextProvider = (props) => {
  const LOCAL_STORAGE_KEY = "botfren";
  const saved = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

  const [isLogin, showLoginModal] = useState(false);
  const [isRegister, showRegisterModal] = useState(false);
  const [user, setStateUser] = useState(saved);

  const hasUser = () => {
    return user && user.hasOwnProperty("id");
  };

  const setUser = (user) => {
    if (user) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(user));
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
    setStateUser(user);
  };

  const deleteUser = () => {
    if (hasUser()) {
      deleteSession().then(() => {
        setUser(null);
      });
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        deleteUser,
        hasUser,
        showLoginModal,
        showRegisterModal,
      }}
    >
      <LoginModal open={isLogin} onClose={() => showLoginModal(false)} />
      <RegisterModal
        open={isRegister}
        onClose={() => showRegisterModal(false)}
      />
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;

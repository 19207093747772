import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { grey } from "@mui/material/colors";
import {
  ArrowBack,
  CancelScheduleSend,
  Delete,
  Edit,
  OpenInNew,
  Public,
  PublicOff,
  Send,
} from "@mui/icons-material";
import {
  botAutopilot,
  botTitle,
  botAvatar,
  botVisibility,
} from "../../../components/utils/botUtils";
import { UserContext } from "../../../contexts/UserContext";
import { BotDeleteModal } from "../../../components/modals/BotDeleteModal";
import { BotUpdateModal } from "../../../components/modals/BotUpdateModal";

export const Header = ({ bot }) => {
  const navigate = useNavigate();

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const { hasUser } = useContext(UserContext);
  const { private: isPrivate, autopilot, authentication } = bot;

  const belongsToUser = () => {
    return hasUser() && bot && bot.is_owner;
  };

  return (
    <Box>
      {hasUser() && (
        <IconButton
          sx={{ marginTop: -5 }}
          size="small"
          onClick={() => navigate(-1)}
        >
          <ArrowBack />
        </IconButton>
      )}

      <Box display="flex" justifyContent="space-between">
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ marginBottom: 4 }}
        >
          {botAvatar(
            bot.authentication,
            bot.type,
            { width: 60, height: 60 },
            "large",
          )}

          <Stack direction="column" spacing={0} sx={{ paddingLeft: 1 }}>
            <Typography variant="h4">
              {botTitle(bot.authentication, bot.type)}
              {bot.authentication && bot.authentication.url && (
                <Tooltip title={bot.authentication.url} placement="right">
                  <Link
                    href={bot.authentication.url}
                    underline="none"
                    target="_blank"
                  >
                    <OpenInNew sx={{ ml: 1 }} />
                  </Link>
                </Tooltip>
              )}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ color: grey[600] }}
            >
              {isPrivate ? (
                <PublicOff fontSize="small" />
              ) : (
                <Public fontSize="small" />
              )}
              <Typography sx={{ paddingRight: 1 }} variant="body2">
                {botVisibility(isPrivate)}
              </Typography>
              {autopilot ? (
                <Send fontSize="small" />
              ) : (
                <CancelScheduleSend fontSize="small" />
              )}
              <Typography variant="body2">
                {botAutopilot(authentication, autopilot)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {belongsToUser() && (
          <Box>
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              onClick={() => setIsEditOpen(true)}
            >
              <Edit />
            </Button>
            <Button variant="outlined" onClick={() => setIsDeleteOpen(true)}>
              <Delete />
            </Button>
          </Box>
        )}
      </Box>

      {belongsToUser() && (
        <>
          <BotDeleteModal
            open={isDeleteOpen}
            bot={bot}
            onClose={() => setIsDeleteOpen(false)}
          />
          <BotUpdateModal
            open={isEditOpen}
            bot={bot}
            onClose={() => setIsEditOpen(false)}
          />
        </>
      )}
    </Box>
  );
};

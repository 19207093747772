import { Avatar, Badge } from "@mui/material";
import React from "react";
import {
  BOT_BLUESKY,
  BOT_DISCORD,
  BOT_FARCASTER,
  BOT_TELEGRAM,
  BOT_TWITTER,
} from "../../constants/botTypes";
import {
  BLUESKY,
  DISCORD,
  FARCASTER,
  TELEGRAM,
  TWITTER,
} from "../../constants/providerTypes";
import { botIconFactory } from "../icons/BotIcons";
import { isEmptyValue } from "./utils";

export const authAvatar = (auth) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={
        <Avatar sx={{ width: 22, height: 22, border: 2 }}>
          {authIcon(auth)}
        </Avatar>
      }
    >
      <Avatar src={auth.avatar.md} />
    </Badge>
  );
};

export const authIcon = (auth) => {
  switch (auth.provider) {
    case FARCASTER:
      return botIconFactory(BOT_FARCASTER, "small");

    case TWITTER:
      return botIconFactory(BOT_TWITTER, "small");

    case DISCORD:
      return botIconFactory(BOT_DISCORD, "small");

    case BLUESKY:
      return botIconFactory(BOT_BLUESKY, "small");

    case TELEGRAM:
      return botIconFactory(BOT_TELEGRAM, "small");

    default:
      return "Not Defined";
  }
};
export const authURL = (auth) => {
  switch (auth.provider) {
    case DISCORD:
      return `${auth.url}/${auth.channel_id}`;

    case TWITTER:
      return `${auth.url}`;

    case BLUESKY:
      return `${auth.url}`;

    default:
      return auth.url;
  }
};

export const authTitle = (auth) => {
  switch (auth.provider) {
    case TELEGRAM:
      return `${auth.channel_name}`;

    case FARCASTER:
      return !isEmptyValue(auth.channel_name)
        ? `@${auth.username}: ${auth.channel_name}`
        : `@${auth.username}`;

    case DISCORD:
      return `${auth.username}: ${auth.channel_name}`;

    default:
      return `@${auth.username}`;
  }
};

import {
  AutoMode,
  ErrorOutline,
  PauseCircleOutline,
  Public,
  PublicOff,
} from "@mui/icons-material";

import {
  Badge,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";

import { grey, red, yellow } from "@mui/material/colors";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLoggedUser } from "../../hooks/useLoggedUser";
import { botIconFactory } from "../icons/BotIcons";
import {
  botAutopilot,
  botAvatar,
  botTitle,
  botVisibility,
} from "../utils/botUtils";

const i18n = {
  behaviorsLabel: (count) => {
    if (count === 0) {
      return "No behaviors";
    }
    return count === 1 ? `${count} behavior` : `${count} behaviors`;
  },
  postLimitReached: "Daily post limit reached.",
};

export const BotCard = ({
  authentication,
  autopilot,
  error,
  id,
  is_owner,
  private: isPrivate,
  stats,
  type,
}) => {
  const navigate = useNavigate();
  const { user } = useLoggedUser();

  const renderContent = () => {
    // Account limit reached
    if (
      is_owner &&
      user?.data?.posts_limit > -1 &&
      user?.data?.posts_shared >= user?.data?.posts_limit
    ) {
      return (
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <PauseCircleOutline fontSize="small" sx={{ color: yellow[800] }} />
          <Typography
            sx={{ paddingRight: 1 }}
            color={grey[600]}
            variant="body2"
          >
            {i18n.postLimitReached}
          </Typography>
        </Stack>
      );
    }

    // Bot error
    if (error) {
      return (
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <ErrorOutline fontSize="small" sx={{ color: red[600] }} />
          <Typography
            sx={{ paddingRight: 1 }}
            color={grey[600]}
            variant="body2"
          >
            {error}
          </Typography>
        </Stack>
      );
    }

    // Standard stats
    return (
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <AutoMode fontSize="small" sx={{ color: grey[600] }} />
        <Typography sx={{ paddingRight: 1 }} color={grey[600]} variant="body2">
          {i18n.behaviorsLabel(stats.behaviors)}
        </Typography>
        {isPrivate ? (
          <PublicOff fontSize="small" sx={{ color: grey[600] }} />
        ) : (
          <Public fontSize="small" sx={{ color: grey[600] }} />
        )}
        <Typography sx={{ paddingRight: 1 }} color={grey[600]} variant="body2">
          {botVisibility(isPrivate)}
        </Typography>
      </Stack>
    );
  };

  return (
    <Card sx={{ mb: 2, mr: 2, width: 290 }} raised>
      <CardActionArea onClick={() => navigate(`/bots/${id}/behaviors`)}>
        <CardHeader
          avatar={
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={botIconFactory(type, "small")}
            >
              {botAvatar(authentication)}
            </Badge>
          }
          title={botTitle(authentication, type)}
          subheader={botAutopilot(authentication, autopilot)}
        />
        <CardContent>{renderContent()}</CardContent>
      </CardActionArea>
    </Card>
  );
};

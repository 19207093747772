import { GroupWork, Public, PublicOff } from "@mui/icons-material";

import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useNavigate } from "react-router";
import {
  groupAvatar,
  groupBotsCount,
  groupUrl,
  groupVisibility,
} from "../utils/groupUtils";
import { truncate } from "../utils/utils";

export const GroupCard = ({
  avatars,
  bot_count,
  id,
  name,
  public: isPublic,
}) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ mb: 2, mr: 2, width: 290 }} raised>
      <CardActionArea onClick={() => navigate(groupUrl(id))}>
        <CardHeader avatar={groupAvatar(avatars)} title={truncate(name, 25)} />
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            spacing={0.5}
            sx={{ color: grey[600] }}
          >
            <GroupWork fontSize="small" />
            <Typography variant={"body2"} sx={{ paddingRight: 1 }}>
              {groupBotsCount(bot_count)}
            </Typography>

            {isPublic ? (
              <Public fontSize="small" />
            ) : (
              <PublicOff fontSize="small" />
            )}
            <Typography variant={"body2"}>
              {groupVisibility(isPublic)}
            </Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

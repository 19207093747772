import { SvgIcon } from "@mui/material";

export const Ethereum = (props) => {
  const defaultProps = {
    width: 24,
    height: 24,
    ...props,
  };

  return (
    <SvgIcon sx={defaultProps}>
      <path d="M12.2857 15.6667L18.5714 11.8146L12.2857 1L6 11.8146L12.2857 15.6667Z" />
      <path d="M12.284 23L18.5714 13.5714L12.284 17.5227L6 13.5714L12.284 23Z" />
    </SvgIcon>
  );
};

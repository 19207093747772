import { useQuery } from "react-query";
import { getCollections } from "../api/v1/collections";

export const collectionsQuery = (page = 1, includePublic = false, search) => {
  return `collections?page=${page}&public=${includePublic}&search=${search}`;
};

export const useCollections = ({
  enabled = true,
  page = 1,
  search,
  includePublic = false,
}) => {
  const query = collectionsQuery(page, includePublic, search);

  const { data, isError, isFetched, remove } = useQuery(
    query,
    () => getCollections({ page, search }),
    {
      enabled,
      refetchOnWindowFocus: false,
      onError: (err) => {
        if (err.response.status === 401) {
          remove();
        }
      },
    },
  );
  return { data, isError, isFetched, query };
};

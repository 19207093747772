import { Box, Grid, Stack, Typography } from "@mui/material";
import { AccountCard } from "../components/cards/AccountCard";
import { BotCard } from "../components/cards/BotCard";
import { BotViewAllCard } from "../components/cards/BotViewAllCard";
import { CollectionCard } from "../components/cards/CollectionCard";
import { CollectionViewAllCard } from "../components/cards/CollectionViewAllCard";
import { GroupCard } from "../components/cards/GroupCard";
import { GroupViewAllCard } from "../components/cards/GroupViewAllCard";

import Layout from "../components/Layout";
import { CardListing } from "../components/layout/CardListing";
import ProgressLoader from "../components/utils/ProgressLoader";
import { useBots } from "../hooks/useBots";
import { useCollections } from "../hooks/useCollections";
import { useGroups } from "../hooks/useGroups";
import { Header as BotsHeader } from "./bots/components/Header";
import { Header as CollectionsHeader } from "./collections/components/Header";
import { Header as GroupsHeader } from "./groups/components/Header";

const i18n = {
  account: "Account",
  error: "We are unable to load data for this page. Please try again later.",
};

const Home = () => {
  const {
    data: bots,
    isFetched: isBotsFetched,
    isError: isBotsError,
  } = useBots({ enabled: true });
  const { data: groups, isFetched: isGroupsFetched } = useGroups({
    enabled: true,
  });
  const { data: collections, isFetched: isCollectionsFetched } = useCollections(
    { enabled: true },
  );

  if (!isBotsFetched || !isGroupsFetched || !isCollectionsFetched) {
    return (
      <Layout>
        <ProgressLoader />
      </Layout>
    );
  }

  if (isBotsError || !bots) {
    return i18n.error;
  }

  const renderBots = () => {
    const limit = 5;
    const hasMore = bots.data.data.length > limit;

    return (
      <Box>
        <BotsHeader />
        <CardListing>
          {bots.data.data.map((bot, idx) => {
            if (idx < limit) {
              return <BotCard key={bot.id} {...bot} />;
            }
            return null;
          })}
          {hasMore && <BotViewAllCard count={bots.data.total_items} />}
        </CardListing>
      </Box>
    );
  };

  const renderGroups = () => {
    const limit = 5;
    const hasMore = groups.data.data.length > limit;

    return (
      <Box>
        <GroupsHeader />
        <CardListing>
          {groups.data.data.map((group, idx) => {
            if (idx < limit) {
              return <GroupCard key={`group-${group.id}`} {...group} />;
            }
            return null;
          })}
          {hasMore && <GroupViewAllCard count={groups.data.total_items} />}
        </CardListing>
      </Box>
    );
  };

  const renderCollections = () => {
    const limit = 8;
    const hasMoreContent = collections.data.data.length > limit;

    return (
      <Box>
        <CollectionsHeader />
        <CardListing>
          {collections.data.data.map((collection, idx) => {
            if (idx < limit) {
              return (
                <CollectionCard key={collection.id} collection={collection} />
              );
            }
            return null;
          })}
          {hasMoreContent && (
            <CollectionViewAllCard count={collections.data.total_items} />
          )}
        </CardListing>
      </Box>
    );
  };

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item md={9}>
          <Stack spacing={4}>
            {renderBots()}
            {renderCollections()}
            {renderGroups()}
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Stack spacing={2}>
            <Typography variant="h5" mb={2}>
              {i18n.account}
            </Typography>
            <AccountCard />
          </Stack>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Home;

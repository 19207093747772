import { ARBITRUM, BASE, ETHEREUM, POLYGON } from "../constants/blochainTypes";
import Account from "../pages/account/Account";

import PasswordReset from "../pages/account/PasswordReset";
import Auth from "../pages/auth/Auth";
import Bot from "../pages/bot/Bot";
import Bots from "../pages/bots/Bots";
import Collection from "../pages/collection/Collection";
import Collections from "../pages/collections/Collections";
import { Community } from "../pages/community/Community";
import Group from "../pages/group/Group";
import Groups from "../pages/groups/Groups";
import Home from "../pages/Home";
import NftCollection from "../pages/nftcollection/NftCollection";
import NotFound from "../pages/NotFound";
import SalesbotsLanding from "../pages/SalesbotsLanding";
import Splash from "../pages/Splash";
import PrivateRoute from "./PrivateRoute";

export const routes = [
  {
    path: "/community/:id/*",
    element: <Community />,
  },
  {
    path: "/bots",
    element: (
      <PrivateRoute>
        <Bots />
      </PrivateRoute>
    ),
  },
  {
    path: "/bots/:id/*",
    element: <Bot />,
  },
  {
    path: "/accounts/:id/*",
    element: (
      <PrivateRoute>
        <Account />
      </PrivateRoute>
    ),
  },
  {
    path: "/groups",
    element: (
      <PrivateRoute>
        <Groups />
      </PrivateRoute>
    ),
  },
  {
    path: "/groups/:id/*",
    element: <Group />,
  },
  {
    path: "/collections",
    element: (
      <PrivateRoute>
        <Collections />
      </PrivateRoute>
    ),
  },
  {
    path: "/collections/ethereum/:id",
    element: <NftCollection chain={ETHEREUM} />,
  },
  {
    path: "/collections/base/:id",
    element: <NftCollection chain={BASE} />,
  },
  {
    path: "/collections/polygon/:id",
    element: <NftCollection chain={POLYGON} />,
  },
  {
    path: "/collections/arbitrum/:id",
    element: <NftCollection chain={ARBITRUM} />,
  },
  {
    path: "/collections/:id/*",
    element: <Collection />,
  },
  {
    path: "/collections/:id/contents/:contentId/*",
    element: <Collection />,
  },
  {
    path: "/auth/:provider",
    element: <Auth />,
  },
  {
    path: "/home",
    element: (
      <PrivateRoute>
        <Home />
      </PrivateRoute>
    ),
  },
  {
    path: "/settings/password_reset",
    element: <PasswordReset />,
  },
  {
    path: "/salesbots",
    element: <SalesbotsLanding />,
  },
  {
    path: "/",
    element: <Splash />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

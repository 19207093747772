import { Api } from "../axios.config";

const path = "/collections";

export const getCollections = ({ page, search }) => {
  let url = `${path}?page=${page}`;
  if (search) {
    url = `${url}&search=${search}`;
  }
  return Api.get(url);
};

export const getCollection = (id) => Api.get(`${path}/${id}`);

export const createCollection = (payload) =>
  Api.post(path, { collection: payload });

export const updateCollection = (id, payload) =>
  Api.put(`${path}/${id}`, { collection: payload });

export const deleteCollection = (id) => Api.delete(`${path}/${id}`);

export const getCollectionContents = (id, page) =>
  Api.get(`${path}/${id}/contents?page=${page}`);

import { useQuery } from "react-query";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { getAuthentications } from "../api/v1/authentications";

export const authenticationsQuery = "/authentications";

export const useAuthentications = () => {
  const userContext = useContext(UserContext);
  const { deleteUser } = userContext;
  const query = authenticationsQuery;

  const { data, isFetched, isFetching, isError, remove } = useQuery(
    authenticationsQuery,
    () => getAuthentications(),
    {
      refetchOnWindowFocus: true,
      onError: (err) => {
        if (err.response.status === 401) {
          remove();
          deleteUser();
        }
      },
    },
  );
  return { data, isError, isFetched, isFetching, query };
};

import { SvgIcon } from "@mui/material";

export const Polygon = (props) => {
  const defaultProps = {
    width: 24,
    height: 24,
    ...props,
  };

  return (
    <SvgIcon sx={defaultProps}>
      <path d="M16.5774 8.49661C16.2463 8.26977 15.8145 8.26977 15.4474 8.49661L12.8565 10.2483L11.0968 11.3909L8.5058 13.1426C8.17473 13.3694 7.7429 13.3694 7.37585 13.1426L5.31747 11.7732C4.98641 11.5463 4.7525 11.1263 4.7525 10.6684V7.96732C4.7525 7.50945 4.95042 7.09358 5.31747 6.86254L7.34346 5.53092C7.67453 5.30408 8.10636 5.30408 8.47341 5.53092L10.4994 6.86254C10.8305 7.08938 11.0644 7.50945 11.0644 7.96732V9.71902L12.8241 8.53862V6.78693C12.8241 6.32905 12.6262 5.91318 12.2591 5.68215L8.5094 3.17013C8.17833 2.94329 7.7465 2.94329 7.37945 3.17013L3.56497 5.68215C3.19792 5.90898 3 6.32905 3 6.78693V11.8488C3 12.3067 3.19792 12.7225 3.56497 12.9536L7.38305 15.4656C7.71411 15.6924 8.14594 15.6924 8.51299 15.4656L11.104 13.7517L12.8637 12.5713L15.4546 10.8574C15.7857 10.6306 16.2175 10.6306 16.5846 10.8574L18.6106 12.189C18.9416 12.4159 19.1755 12.8359 19.1755 13.2938V15.9949C19.1755 16.4527 18.9776 16.8686 18.6106 17.0997L16.5846 18.4691C16.2535 18.6959 15.8217 18.6959 15.4546 18.4691L13.4286 17.1375C13.0976 16.9106 12.8637 16.4906 12.8637 16.0327V14.281L11.104 15.4614V17.2131C11.104 17.6709 11.3019 18.0868 11.6689 18.3179L15.487 20.8299C15.8181 21.0567 16.2499 21.0567 16.617 20.8299L20.435 18.3179C20.7661 18.091 21 17.6709 21 17.2131V12.1512C21 11.6934 20.8021 11.2775 20.435 11.0464L16.5774 8.49661Z" />
    </SvgIcon>
  );
};

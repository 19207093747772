import React, { useState } from "react";
import { Avatar, Card, CardHeader, Link } from "@mui/material";

import { truncate } from "../utils/utils";
import ContentModal from "../modals/ContentModal";
import { useTheme } from "@mui/material/styles";

const ContentEmbedCard = ({ id, media, title, url }) => {
  const [isZoomed, setZoomed] = useState(false);
  const theme = useTheme();
  const bgColor =
    theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : theme.palette.grey[200];

  const renderAvatar = () => {
    if (media) {
      return (
        <Avatar
          variant="rounded"
          sx={{ cursor: "pointer" }}
          src={media.xs}
          onClick={() => setZoomed(true)}
        />
      );
    }
  };

  return (
    <Card sx={{ width: "100%", backgroundColor: bgColor }} variant="outlined">
      <CardHeader
        avatar={renderAvatar()}
        title={truncate(title, 27)}
        subheader={
          <Link href={url} rel="noreferrer" target="_blank">
            {truncate(url, 27)}
          </Link>
        }
      />

      {media && (
        <ContentModal
          id={id}
          onClose={() => setZoomed(false)}
          open={isZoomed}
        />
      )}
    </Card>
  );
};

export default ContentEmbedCard;

import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";

import { Add, Close, Visibility } from "@mui/icons-material";

import { LoadingButton } from "@mui/lab";
import { behaviorIconFactory } from "../icons/BehaviorIcons";
import { useForm } from "react-hook-form";
import { ethers } from "ethers";
import {
  ARBITRUM,
  BASE,
  ETHEREUM,
  OPTIMISM,
  POLYGON,
} from "../../constants/blochainTypes";
import { CollectionSelectModal } from "../modals/CollectionSelectModal";
import { useCollection } from "../../hooks/useCollection";
import { CollectionEmbedCard } from "./CollectionEmbedCard";
import { GroupSelectModal } from "../modals/GroupSelectModal";
import { useGroup } from "../../hooks/useGroup";
import { GroupEmbedCard } from "./GroupEmbedCard";

const COLLECTION_TYPES = ["ItemShare", "CollectionPromo"];
const GROUP_TYPES = ["RetweetOtherBot", "RecommendBotToFollow"];
const CONTRACT_ADDRESSES = [
  "contract_address",
  "treasury_contract_address",
  "dao_contract_address",
  "auction_house_contract_address",
  "token_contract_address",
];
const EVM_CHAINS = [ETHEREUM, POLYGON, OPTIMISM, BASE, ARBITRUM];

const i18n = {
  add: "Add",
  added: "Added...",
  tooltip: "See example",
};

const BehaviorAddCard = ({
  description,
  example,
  fields,
  isAdded,
  isSaving,
  onClick,
  title,
  type,
}) => {
  const [blockchain, setBlockchain] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [formData, setFormData] = useState({ blockchain });

  const hasCollectionSelect = COLLECTION_TYPES.includes(type);
  const [collectionSelect, setCollectionSelect] = useState(false);

  const hasGroupSelect = GROUP_TYPES.includes(type);
  const [groupSelect, setGroupSelect] = useState(false);

  const { data: selectedCollection } = useCollection(formData.collection_id);
  const { data: selectedGroup } = useGroup(formData.group_id);

  const { handleSubmit } = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  // Basic validation for all fields
  const isInputValid = () => {
    const invalidFields = fields.find((field) => {
      if (field.required) {
        // Check for empty value
        if (!formData[field.name] || formData[field.name].length === 0) {
          return true;
        }
        // Check for valid evm contract address
        if (
          CONTRACT_ADDRESSES.includes(field.name) &&
          EVM_CHAINS.includes(blockchain) &&
          !ethers.isAddress(formData[field.name])
        ) {
          return true;
        }
      }
      return null;
    });
    return !invalidFields;
  };

  const onSubmit = async () => {
    if (isInputValid()) {
      onClick(formData);
    }
  };

  const onCollectionSelect = (collectionId) => {
    setFormData((prevState) => {
      return { ...prevState, collection_id: collectionId };
    });
  };

  const onGroupSelect = (groupId) => {
    setFormData((prevState) => {
      return { ...prevState, group_id: groupId };
    });
  };

  const onExpand = () => {
    setExpanded(!expanded);
  };

  const renderInput = () => {
    if (isAdded) {
      return i18n.added;
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Stack direction="column" spacing={2}>
          {fields &&
            fields.map((field, idx) => {
              //Display only required fields at this level
              if (
                !field.required ||
                field.name === "collection_id" ||
                field.name === "group_id"
              ) {
                return null;
              }

              const inputProps = field.inputProps
                ? JSON.parse(field.inputProps)
                : {};

              if (field.type === "select") {
                const inputLabelId = `select-${field.name}`;

                return (
                  <FormControl key={idx} fullWidth>
                    <InputLabel id={inputLabelId}>{field.label}</InputLabel>
                    <Select
                      labelId={inputLabelId}
                      value={blockchain}
                      label={field.label}
                      name={field.name}
                      onChange={(e) => {
                        setBlockchain(e.target.value);

                        setFormData((prevState) => {
                          return {
                            ...prevState,
                            ...{ [e.target.name]: e.target.value },
                          };
                        });
                      }}
                    >
                      {field.items.map((chain, idx) => (
                        <MenuItem key={idx} value={chain}>
                          {chain}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              }

              return (
                <TextField
                  disabled={isSaving}
                  fullWidth
                  helperText={field.helperText}
                  inputProps={inputProps}
                  key={idx}
                  label={field.label}
                  name={field.name}
                  required={field.required}
                  variant="outlined"
                  onChange={(e) =>
                    setFormData((prevState) => {
                      return {
                        ...prevState,
                        ...{ [e.target.name]: e.target.value },
                      };
                    })
                  }
                />
              );
            })}
          <LoadingButton
            variant="outlined"
            loading={isSaving}
            disabled={!isInputValid()}
            type="submit"
          >
            {i18n.add}
          </LoadingButton>
        </Stack>
      </form>
    );
  };

  const renderExpandButton = () => {
    return (
      <IconButton onClick={onExpand}>
        {expanded ? <Close /> : <Add />}
      </IconButton>
    );
  };

  return (
    <Card>
      <CardHeader
        avatar={behaviorIconFactory(type)}
        title={
          <Box>
            {title}
            {example && (
              <Tooltip title={i18n.tooltip} arrow placement="right">
                <IconButton
                  href={example}
                  target="_blank"
                  aria-label="preview"
                  color="primary"
                  size="small"
                >
                  <Visibility fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        }
        subheader={description}
        action={renderExpandButton()}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {hasGroupSelect && (
            <Box sx={{ marginBottom: 4 }}>
              {selectedGroup && selectedGroup.data ? (
                <GroupEmbedCard
                  group={selectedGroup.data}
                  onClick={() => setGroupSelect(true)}
                />
              ) : (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => setGroupSelect(true)}
                >
                  Select Group
                </Button>
              )}
            </Box>
          )}

          {hasCollectionSelect && (
            <Box sx={{ marginBottom: 4 }}>
              {selectedCollection && selectedCollection.data ? (
                <CollectionEmbedCard
                  collection={selectedCollection.data}
                  onClick={() => setCollectionSelect(true)}
                />
              ) : (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => setCollectionSelect(true)}
                >
                  Select Collection
                </Button>
              )}
            </Box>
          )}
          {renderInput()}
        </CardContent>
      </Collapse>

      {groupSelect && (
        <GroupSelectModal
          open={true}
          onSelect={(id) => onGroupSelect(id)}
          onClose={() => setGroupSelect(false)}
          fullWidth
          maxWidth="md"
        />
      )}

      {collectionSelect && (
        <CollectionSelectModal
          open={true}
          onSelect={(id) => onCollectionSelect(id)}
          onClose={() => setCollectionSelect(false)}
          fullWidth
          maxWidth="md"
        />
      )}
    </Card>
  );
};
export default BehaviorAddCard;

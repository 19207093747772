import {
  BOT_BLUESKY,
  BOT_DISCORD,
  BOT_FARCASTER,
  BOT_TWITTER,
  BOT_TELEGRAM,
} from "../../constants/botTypes";
import { Avatar, Badge } from "@mui/material";
import { LinkOff } from "@mui/icons-material";
import React from "react";
import { botIconFactory } from "../icons/BotIcons";
import jstz from "jstz";

const BOT_TYPES = {
  bluesky: BOT_BLUESKY,
  discord: BOT_DISCORD,
  farcaster: BOT_FARCASTER,
  telegram: BOT_TELEGRAM,
  twitter: BOT_TWITTER,
};

export const botCreatePayload = (auth) => {
  const timezone = jstz.determine().name();
  const type = BOT_TYPES[auth.provider];

  return { authentication_id: auth.id, type, timezone };
};

export const botAvatar = (auth, type, props = {}, badgeSize) => {
  return auth ? (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={botIconFactory(type, badgeSize)}
    >
      <Avatar sx={{ ...props }} src={auth.avatar.md} />
    </Badge>
  ) : (
    <Avatar sx={{ ...props }}>
      <LinkOff />
    </Avatar>
  );
};

export const botTitle = (auth, type) => {
  if (!auth) {
    return "Account Disconnected";
  }
  switch (type) {
    case BOT_BLUESKY:
    case BOT_TWITTER:
    case BOT_FARCASTER:
      return `@${auth.username}${
        auth.channel_name ? `: ${auth.channel_name}` : ""
      }`;

    case BOT_TELEGRAM:
      return `@${auth.channel_name}`;

    case BOT_DISCORD:
      return `${auth.username}: ${auth.channel_name}`;

    default:
      return auth.username;
  }
};

export const botQueue = (count) => {
  if (count === 0) {
    return "Queue empty";
  }
  return count === 1 ? `${count} post queued` : `${count} posts queued`;
};

export const botVisibility = (bool) => (bool ? "Private Bot" : "Public Bot");

export const botAutopilot = (auth, bool) => {
  return bool && auth ? "Posting Live" : "Posting Paused";
};

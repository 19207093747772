import { useSnackbar } from "notistack";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { createCollection } from "../api/v1/collections";
import { collectionsQuery } from "./useCollections";

const i18n = {
  error: "Unable to create collection at this time",
  success: "Collection created successfully",
};

export const useCollectionCreate = () => {
  const [isFetching, setFetching] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [isError, setError] = useState(false);

  const [data, setData] = useState(null);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const call = (payload) => {
    setFetching(true);

    createCollection(payload)
      .then((result) => {
        queryClient.invalidateQueries(collectionsQuery()).then(() => {
          enqueueSnackbar(i18n.success, { variant: "success" });
          setFetching(false);
          setFetched(true);
          setData(result);
        });
      })
      .catch((err) => {
        const errorMsg =
          err && err.response.data.error ? err.response.data.error : i18n.error;
        enqueueSnackbar(errorMsg, { variant: "error" });
        setFetching(false);
        setError(true);
      });
  };
  return { call, data, isFetched, isFetching, isError };
};

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { STRIPE_SUBSCRIPTION } from "../../constants/subscriptionTypes";
import { useGetSubscriptionPlans } from "../../hooks/useGetSubscriptionPlans";
import { useLoggedUser } from "../../hooks/useLoggedUser";
import { useSubscriptionCreate } from "../../hooks/useSubscriptionCreate";
import { useSubscriptionInfo } from "../../hooks/useSubscriptionInfo";

import PlanCard from "../cards/PlanCard";
import ProgressLoader from "../utils/ProgressLoader";

const i18n = {
  cancelButton: "nevermind",
  discountCoupon: "Discount Coupon",
  modalTitle: "Premium Plans",
  plansFetchError: "Unable to load plans at this time...",
  upgradeButton: "Upgrade",
  upgradeCrypto:
    "Paying in crypto? Please reach out via Discord for instructions.",
  upgradeTitle:
    "Why upgrade? Higher post limits 🚀 faster blockchain events ✨ support",
};

const SubscriptionCreateModal = ({ onClose, open }) => {
  const { enqueueSnackbar } = useSnackbar();
  const elements = useElements();
  const stripe = useStripe();
  const theme = useTheme();
  const { user } = useLoggedUser();

  const [planId, setPlanId] = useState(null);
  const [priceId, setPriceId] = useState(null);

  const {
    data: plans,
    isFetching: isFetchingPlans,
    isError: isErrorStripePlans,
    isFetched: isFetchedStripePlans,
  } = useGetSubscriptionPlans();

  const activePlan = user?.data?.subscription_plan;
  const isStripe = Boolean(
    activePlan && activePlan?.type === STRIPE_SUBSCRIPTION,
  );

  const { data: activeSubscriptionDetails } = useSubscriptionInfo({
    enabled: isStripe,
    id: activePlan?.id,
  });

  const {
    call: createSubscription,
    isFetching: isCreatingNewSub,
    isFetched: isCreatedNewSub,
  } = useSubscriptionCreate();

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    shouldUseNativeValidation: false,
  });

  useEffect(() => {
    if (isCreatedNewSub) {
      onClose();
    }
  }, [isCreatedNewSub, onClose]);

  const onPlanUpgrade = (planId, priceId) => {
    setPlanId(planId);
    setPriceId(priceId);
  };

  const onSubmit = ({ coupon, plan_price }) => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    stripe.createToken(cardElement).then((result) => {
      // Validate credit card
      if (result.error) {
        enqueueSnackbar(result.error.message, { variant: "error" });
        return;
      }

      const payment_token = result.token.id;
      createSubscription({ plan_price, payment_token, coupon });
    });
  };
  const renderUpgradeForm = () => {
    const plan = plans.data.find((plan) => plan.id === planId);

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Typography variant="body1">
            <strong>{plan.name}</strong>
          </Typography>
          <Typography variant="body1" mb={2}>
            {plan.description}
          </Typography>
          <input {...register("plan_price")} type="hidden" value={priceId} />

          <Stack spacing={2}>
            <Box
              sx={{
                padding: 2,
                border: 1,
                borderColor: theme.palette.action.disabled,
                borderRadius: 1,
              }}
            >
              <CardElement
                options={{
                  style: {
                    base: {
                      color: theme.palette.text.primary,
                      fontWeight: theme.typography.fontWeightLight,
                      fontSize: `${theme.typography.htmlFontSize}px`,
                      "::placeholder": {
                        color: theme.palette.text.secondary,
                      },
                    },
                  },
                }}
              />
            </Box>
            <TextField
              {...register("coupon")}
              label={i18n.discountCoupon}
              variant="outlined"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            disabled={isCreatingNewSub}
            onClick={() => setPlanId(null)}
          >
            {i18n.cancelButton}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isCreatingNewSub}
          >
            {i18n.upgradeButton}
          </LoadingButton>
        </DialogActions>
      </form>
    );
  };

  const renderPlans = () => {
    if (isFetchingPlans && !isFetchedStripePlans) {
      return (
        <DialogContent>
          <ProgressLoader />
        </DialogContent>
      );
    }

    if (isErrorStripePlans) {
      return (
        <DialogContent>Unable to load plans at this time...</DialogContent>
      );
    }

    return (
      <Box>
        <DialogContent>
          <Stack spacing={2} direction="row" sx={{ marginBottom: 3 }}>
            {plans.data
              .sort((a, b) => a.metadata.sort_order - b.metadata.sort_order)
              .map((plan) => (
                <PlanCard
                  key={plan.id}
                  plan={plan}
                  activeSubscription={
                    isStripe ? activeSubscriptionDetails?.data?.details : null
                  }
                  onClick={onPlanUpgrade}
                />
              ))}
          </Stack>
          <Typography variant="body2" color={grey[500]}>
            {i18n.upgradeCrypto}
          </Typography>
        </DialogContent>
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{i18n.modalTitle}</DialogTitle>
      {planId ? renderUpgradeForm() : renderPlans()}
    </Dialog>
  );
};
export default SubscriptionCreateModal;

import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import { truncate } from "../../../components/utils/utils";

const NFTContentCard = ({ data }) => {
  const original = data?.meta?.content?.find((itm) => itm["@type"] === "IMAGE");
  const preview = data?.meta?.content?.find(
    (itm) => itm["@type"] === "IMAGE" && itm["representation"] === "PREVIEW",
  );

  return (
    <Card
      sx={{
        marginRight: { sm: 2, xs: 0 },
        marginBottom: 2,
        width: { sm: 180, xs: "100%" },
      }}
      raised
    >
      {(original || preview) && (
        <CardMedia
          sx={{ height: 180 }}
          component="img"
          image={preview ? preview.url : original.url}
        />
      )}
      <CardContent>
        <Typography variant="body2">
          {truncate(data?.meta?.name, 50)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NFTContentCard;

import { SvgIcon } from "@mui/material";

export const Looks = (props) => {
  const defaultProps = {
    width: 24,
    height: 24,
    ...props,
  };

  return (
    <SvgIcon sx={defaultProps}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 13.4594C10.4557 13.4594 9.20271 12.2077 9.20271 10.6621C9.20271 9.11661 10.4557 7.86484 12 7.86484C13.5443 7.86484 14.7973 9.11661 14.7973 10.6621C14.7973 12.2077 13.5443 13.4594 12 13.4594ZM10.7838 10.6621C10.7838 11.3341 11.3286 11.8784 12 11.8784C12.6714 11.8784 13.2162 11.3341 13.2162 10.6621C13.2162 9.99017 12.6714 9.44593 12 9.44593C11.3286 9.44593 10.7838 9.99017 10.7838 10.6621Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 10.6658L8.35134 5.31079H15.6487L21 10.6658L12 19.6622L3 10.6658ZM16.1351 8.71617C13.8614 6.43243 10.1386 6.43245 7.86486 8.71618L5.91893 10.6622L7.86486 12.6081C10.1386 14.8919 13.8614 14.8918 16.1351 12.6081L18.0811 10.6622L16.1351 8.71617Z"
        fill="black"
      />
    </SvgIcon>
  );
};

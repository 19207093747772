import React, { useEffect } from "react";

declare global {
  interface Window {
    onSignInSuccess?: (data: { signer_uuid: string; fid: string }) => void;
  }
}

interface NNConnectProps {
  onConnect: (data: { token: string; fid: string }) => void;
}

export const NNConnect = ({ onConnect }: NNConnectProps) => {
  useEffect(() => {
    // Identify or create the script element
    let script = document.getElementById(
      "siwn-script",
    ) as HTMLScriptElement | null;

    if (!script) {
      script = document.createElement("script");
      script.id = "siwn-script";
      document.body.appendChild(script);
    }

    // Set attributes and source of the script
    script.src = "https://neynarxyz.github.io/siwn/raw/1.2.0/index.js";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      // Remove the script from the body
      if (script) {
        document.body.removeChild(script);
      }

      // Remove the button if it exists
      let button = document.getElementById("siwn-button");
      if (button && button.parentElement) {
        button.parentElement.removeChild(button);
      }
    };
  }, []);

  useEffect(() => {
    window.onSignInSuccess = (data: any) => {
      if (data?.signer_uuid && data?.fid) {
        onConnect({ token: data.signer_uuid, fid: data.fid });
      }
    };
    return () => {
      delete window.onSignInSuccess;
    };
  }, [onConnect]);

  return (
    <>
      <div
        className="neynar_signin"
        data-background_color={"#8A63D2"}
        data-client_id={process.env.REACT_APP_NEYNAR_CLIENT_ID}
        data-success-callback="onSignInSuccess"
        data-theme={"dark"}
        data-variant={"farcaster"}
        data-width={"100%"}
      ></div>
    </>
  );
};

import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useAccountSignIn } from "../../hooks/useAccountSignIn";
import { useAccountPasswordReset } from "../../hooks/useAccountPasswordReset";
import { UserContext } from "../../contexts/UserContext";
import { useLocation, useNavigate } from "react-router-dom";

const i18n = {
  back: "Back to Sign In",
  email: "Email Address",
  forgot: "Forgot password?",
  password: "Password",
  reset: "Reset Password",
  resetHelper: "Password reset instructions will be sent to your email.",
  title: "Sign In",
};

const LoginModal = ({ open, onClose }) => {
  const [isResetting, setResetPassword] = useState(false);
  const { hasUser } = useContext(UserContext);
  const {
    call: signIn,
    isFetching: isFetchingSignIn,
    isFetched: isSignedIn,
  } = useAccountSignIn();
  const { call: resetPass, isFetching: isFetchingReset } =
    useAccountPasswordReset();

  const location = useLocation();
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  useEffect(() => {
    if (isSignedIn && hasUser()) {
      // Redirect to dashboard if login initiated from the home page
      if (location.pathname === "/") {
        navigate("/home");
      }
      onClose();
    }
  }, [isSignedIn, hasUser, onClose, location, navigate]);

  const onSubmit = ({ email, password }) => {
    signIn({ email, password });
  };

  const onReset = ({ email }) => {
    resetPass({ email });
  };

  const renderLogin = () => {
    return (
      <Box>
        <DialogTitle id="dialog-title">{i18n.title}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack spacing={2} sx={{ marginBottom: 5 }}>
              <TextField
                {...register("email")}
                autoComplete="email"
                label={i18n.email}
                required
                type="email"
                variant="outlined"
                fullWidth
              />

              <TextField
                {...register("password")}
                label={i18n.password}
                required
                type="password"
                variant="outlined"
                fullWidth
              />
              <Typography variant="body2">
                <Link
                  underline="none"
                  onClick={() => {
                    setResetPassword(true);
                  }}
                >
                  {i18n.forgot}
                </Link>
              </Typography>
            </Stack>
            <Divider />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isFetchingSignIn}
            >
              {i18n.title}
            </LoadingButton>
          </DialogActions>
        </form>
      </Box>
    );
  };

  const renderReset = () => {
    return (
      <Box>
        <DialogTitle id="dialog-title">{i18n.reset}</DialogTitle>
        <form onSubmit={handleSubmit(onReset)}>
          <DialogContent>
            <Stack spacing={2} sx={{ marginBottom: 5 }}>
              <TextField
                {...register("email")}
                autoComplete="email"
                label={i18n.email}
                required
                type="email"
                variant="outlined"
                fullWidth
              />
              <Typography variant="body2">{i18n.resetHelper}</Typography>
            </Stack>
            <Divider />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setResetPassword(false);
              }}
            >
              {i18n.back}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isFetchingReset}
            >
              {i18n.reset}
            </LoadingButton>
          </DialogActions>
        </form>
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      {isResetting ? renderReset() : renderLogin()}
    </Dialog>
  );
};

export default LoginModal;

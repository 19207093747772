import React, { useContext, useState } from "react";
import ProgressLoader from "../../components/utils/ProgressLoader";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { Box, IconButton, Pagination, Tooltip } from "@mui/material";
import {
  createBotQueue,
  deleteBotPost,
  deleteBotQueue,
  shareBotPost,
} from "../../api/v1/bots";

import QueueCard from "../../components/cards/QueueCard";
import { CardListing } from "../../components/layout/CardListing";

import { PlaylistAdd, PlaylistRemove } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useBotPendingPosts } from "../../hooks/useBotPendingPosts";
import { UserContext } from "../../contexts/UserContext";

const i18n = {
  emptyQueue: "Empty Queue",
  emptyQueueError: "Unable to delete queued posts at this time",
  emptyQueueSuccess: "Queued posts deleted successfully",
  fillQueue: "Queue Posts",
  fillQueueError: "Unable to queue posts at this time",
  fillQueueSuccess: "Posts queued successfully",
  notFoundError:
    "Ooops. We are unable to load data for this page. Please try again later.",
  pageDescription: "You must add behaviors before posts can be queued",
  pageDescriptionEmpty:
    "There are no queued posts! Click on the + icon to fill your queue",
  postDeleteSuccess: "Queued post deleted",
  postDeletedError: "Unable to delete post at this time",
  postEditError: "Unable to edit post at this time",
  postEditSuccess: "Post edited successfully",
  postShareEmpty: "There wasn't anything new to share at this time...",
  postShareError: "Unable to share post at this time",
  postShareSuccess: "Post shared successfully",
};

export const BotQueue = ({ bot, parentQuery }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [page, setPage] = useState(
    new URLSearchParams(window.location.search).get("page") || 1,
  );

  const { hasUser } = useContext(UserContext);

  const {
    data: postsData,
    query: postsQuery,
    isError,
    isFetched,
  } = useBotPendingPosts({
    bot,
    enabled: Number(bot.stats.queue) > 0,
    page,
  });

  const [isQueueing, setQueueing] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const [deletingActionId, setDeletingAction] = useState();
  const [sharingActionId, setSharingAction] = useState();

  const { stats } = bot;

  const belongsToUser = () => {
    return hasUser() && bot.is_owner;
  };

  const reloadPageData = () => {
    return queryClient
      .invalidateQueries(parentQuery)
      .then(() => queryClient.invalidateQueries(postsQuery));
  };

  const onQueueCreate = () => {
    setQueueing(true);

    createBotQueue(bot.id)
      .then(() => {
        reloadPageData().then(() => {
          setQueueing(false);
          enqueueSnackbar(i18n.fillQueueSuccess, { variant: "success" });
        });
      })

      .catch(() => {
        setQueueing(false);
        enqueueSnackbar(i18n.fillQueueError, { variant: "error" });
      });
  };

  const onQueueDelete = () => {
    setDeleting(true);

    deleteBotQueue(bot.id)
      .then(() => {
        reloadPageData().then(() => {
          setDeleting(false);
          enqueueSnackbar(i18n.emptyQueueSuccess, { variant: "success" });
        });
      })
      .catch(() => {
        setDeleting(false);
        enqueueSnackbar(i18n.emptyQueueError, { variant: "error" });
      });
  };

  const onActionDelete = (id) => {
    setDeletingAction(id);

    deleteBotPost(bot.id, id)
      .then(() => {
        reloadPageData().then(() => {
          setDeletingAction(null);
          enqueueSnackbar(i18n.postDeleteSuccess, { variant: "success" });
        });
      })
      .catch(() => {
        setDeletingAction(null);
        enqueueSnackbar(i18n.postDeletedError, { variant: "error" });
      });
  };

  const onActionShare = (id) => {
    setSharingAction(id);

    shareBotPost(bot.id, id)
      .then((response) => {
        if (response.status === 204) {
          reloadPageData().then(() => {
            enqueueSnackbar(i18n.postShareEmpty, { variant: "info" });
          });
        } else {
          reloadPageData().then(() => {
            setSharingAction(null);
            enqueueSnackbar(i18n.postShareSuccess, { variant: "success" });
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(i18n.postShareError, { variant: "error" });
      });
  };

  const renderHeader = () => {
    if (!belongsToUser()) {
      return null;
    }

    if (Number(stats.behaviors) === 0 && Number(stats.queue === 0)) {
      return i18n.pageDescription;
    }

    return (
      <Box>
        <Tooltip title={i18n.fillQueue} arrow>
          <IconButton
            color="primary"
            disabled={isQueueing}
            aria-label={i18n.fillQueue}
            onClick={onQueueCreate}
          >
            {isQueueing ? <ProgressLoader /> : <PlaylistAdd />}
          </IconButton>
        </Tooltip>

        {Number(bot.stats.queue) === 0 && i18n.pageDescriptionEmpty}

        {bot.stats.queue > 0 && (
          <Tooltip title={i18n.emptyQueue} arrow>
            <IconButton
              color="primary"
              disabled={isDeleting}
              aria-label={i18n.emptyQueue}
              onClick={onQueueDelete}
            >
              {isDeleting ? <ProgressLoader /> : <PlaylistRemove />}
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  };

  const renderQueue = () => {
    if (Number(bot.stats.queue) === 0) {
      return;
    }

    if (!isFetched) {
      return <ProgressLoader />;
    }

    if (isError || !postsData.data) {
      return i18n.notFoundError;
    }

    const hasPagination = postsData.data.total_pages > 1;

    return (
      <Box>
        <CardListing>
          {postsData.data.data.map((queue, idx) => (
            <QueueCard
              key={`queue-${idx}`}
              isDeleting={deletingActionId === queue.id}
              isSharing={sharingActionId === queue.id}
              onDelete={() => {
                onActionDelete(queue.id);
              }}
              onShare={() => {
                onActionShare(queue.id);
              }}
              post={queue}
              bot={bot}
            />
          ))}
        </CardListing>

        {hasPagination && (
          <Pagination
            page={page}
            count={postsData.data.total_pages}
            onChange={(event, page) => {
              setPage(page);
              navigate(`?page=${page}`);
            }}
          />
        )}
      </Box>
    );
  };

  return (
    <Box>
      {renderHeader()}
      {renderQueue()}
    </Box>
  );
};

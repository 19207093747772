import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React, { useState } from "react";
import { grey } from "@mui/material/colors";
import SearchForm from "../../../components/forms/SearchForm";
import GroupCreateModal from "../../../components/modals/GroupCreateModal";

const i18n = {
  description:
    "Groups enable bots to interact with each other through group-specific behaviors.",
  error: "We are unable to load data for this page. Please try again later.",
  title: "Groups",
  tooltip: "New Group",
};

export const Header = ({ filter = false }) => {
  const [isNewModal, setNewModal] = useState(false);

  return (
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="h5">
          {i18n.title}
          <Tooltip title={i18n.tooltip} placement="right" arrow>
            <IconButton
              color="primary"
              aria-label={i18n.tooltip}
              onClick={() => setNewModal(true)}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </Typography>
        <Typography color={grey[500]}>{i18n.description}</Typography>

        {isNewModal && (
          <GroupCreateModal
            open={isNewModal}
            onClose={() => setNewModal(false)}
          />
        )}
      </Box>
      {filter && <SearchForm />}
    </Box>
  );
};

import {
  ArrowBack,
  Category,
  Collections,
  Equalizer,
  RocketLaunch,
  Sell,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { grey } from "@mui/material/colors";
import humanize from "humanize-number";
import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Etherscan } from "../../../assets/icons/Etherscan";
import { Looks } from "../../../assets/icons/Looks";
import { Opensea } from "../../../assets/icons/Opensea";
import { Rarible } from "../../../assets/icons/Rarible";
import Layout from "../../../components/Layout";
import { BehaviorQuickstartModal } from "../../../components/modals/BehaviorQuickstartModal";
import ProgressLoader from "../../../components/utils/ProgressLoader";
import {
  getEtherscanUrl,
  getLooksRareUrl,
  getOpenseaUrl,
  getRaribleUrl,
} from "../../../components/utils/utils";
import { ETHEREUM } from "../../../constants/blochainTypes";
import { UserContext } from "../../../contexts/UserContext";
import { useBehaviorTemplate } from "../../../hooks/useBehaviorTemplate";
import { useRaribleCollectionMeta } from "../../../hooks/useRaribleCollectionMeta";
import { useRaribleCollectionStats } from "../../../hooks/useRaribleCollectionStats";

const i18n = {
  action: "Launch a bot for this collection!",
  etherscan: "View on Etherscan",
  looks: "View on LooksRare",
  opensea: "View on OpenSea",
  rarible: "View on Rarible",
  share: "LAUNCH",
};

const BEHAVIOR_TEMPLATE_ID = process.env.NODE_ENV === "development" ? 7 : 43;

export const Header = ({ chain }) => {
  const navigate = useNavigate();

  const [isShareOpen, setIsShareOpen] = useState(false);
  const { hasUser } = useContext(UserContext);

  let { id } = useParams();

  const { data: collection, isFetching: isFetchingCollection } =
    useRaribleCollectionMeta({
      enabled: true,
      blockchain: chain,
      contractAddress: id,
    });

  const { data: stats, isFetching: isFetchingStats } =
    useRaribleCollectionStats({
      enabled: true,
      blockchain: chain,
      contractAddress: id,
    });

  const { data: template, isFetched: isTemplateFetched } =
    useBehaviorTemplate(BEHAVIOR_TEMPLATE_ID);

  if (isFetchingCollection || isFetchingStats)
    return (
      <Layout>
        <ProgressLoader />
      </Layout>
    );

  const renderAvatar = () => {
    const image = collection?.data?.meta?.content?.find(
      (itm) => itm["@type"] === "IMAGE",
    );

    if (image) {
      return (
        <Avatar
          sx={{ width: 65, height: 65 }}
          variant="rounded"
          src={image.url}
        />
      );
    } else {
      return (
        <Avatar sx={{ width: 60, height: 60 }} variant="rounded">
          <Collections />
        </Avatar>
      );
    }
  };

  const renderStats = () => {
    if (!stats) {
      return null;
    }

    const { floorPrice, items, volume } = stats.data;

    return (
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ color: grey[600] }}
      >
        <Tooltip title={i18n.rarible}>
          <Avatar
            variant="rounded"
            sx={{
              width: 23,
              height: 23,
              bgcolor: "#FEDA03",
              cursor: "pointer",
            }}
            onClick={() => window.open(getRaribleUrl(chain, id), "_blank")}
          >
            <Rarible />
          </Avatar>
        </Tooltip>

        <Tooltip title={i18n.opensea}>
          <Avatar
            variant="rounded"
            sx={{
              width: 23,
              height: 23,
              bgcolor: "#2081E2",
              cursor: "pointer",
            }}
            onClick={() => window.open(getOpenseaUrl(chain, id), "_blank")}
          >
            <Opensea />
          </Avatar>
        </Tooltip>

        {chain === ETHEREUM && (
          <>
            <Tooltip title={i18n.looks}>
              <Avatar
                variant="rounded"
                sx={{
                  width: 23,
                  height: 23,
                  bgcolor: "#04CD58",
                  cursor: "pointer",
                }}
                onClick={() => window.open(getLooksRareUrl(id), "_blank")}
              >
                <Looks />
              </Avatar>
            </Tooltip>

            <Tooltip title={i18n.etherscan}>
              <Avatar
                variant="rounded"
                sx={{
                  width: 23,
                  height: 23,
                  bgcolor: "#21325B",
                  mr: 4,
                  cursor: "pointer",
                }}
                onClick={() => window.open(getEtherscanUrl(id), "_blank")}
              >
                <Etherscan width={18} height={18} />
              </Avatar>
            </Tooltip>
          </>
        )}

        {floorPrice && (
          <Box display="flex">
            <Sell fontSize="small" sx={{ mr: 0.5 }} />
            <Typography sx={{ paddingRight: 1 }} variant="body2">
              Floor{" "}
              <span style={{ color: grey[300] }}>
                {humanize(floorPrice.toFixed(2))}Ξ
              </span>
            </Typography>
          </Box>
        )}

        {volume && (
          <Box display="flex">
            <Equalizer fontSize="small" sx={{ mr: 0.5 }} />
            <Typography variant="body2">
              Volume{" "}
              <span style={{ color: grey[300] }}>
                {humanize(volume.toFixed(2))}Ξ
              </span>
            </Typography>
          </Box>
        )}

        <Box display="flex">
          <Category fontSize="small" sx={{ mr: 0.5 }} />
          <Typography variant="body2">
            Items <span style={{ color: grey[300] }}>{humanize(items)}</span>
          </Typography>
        </Box>
      </Stack>
    );
  };

  return (
    <Box>
      {hasUser() && (
        <IconButton
          sx={{ marginTop: -5 }}
          size="small"
          onClick={() => navigate("/home")}
        >
          <ArrowBack />
        </IconButton>
      )}

      <Box display="flex" justifyContent="space-between">
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ marginBottom: 4 }}
        >
          {renderAvatar()}

          <Stack direction="column" spacing={0} sx={{ paddingLeft: 1 }}>
            <Typography variant="h4" mb={0.2}>
              {collection?.data?.name}
            </Typography>
            {renderStats()}
          </Stack>
        </Stack>
        {isTemplateFetched && (
          <Box>
            <Paper
              square={false}
              sx={{
                background: "linear-gradient(45deg, #8d10eb 30%, #5d099c 90%)",
                display: { xs: "block" },
                mb: 2,
                pr: 2,
                pl: 2,
                pt: 1,
                pb: 1,
              }}
            >
              {i18n.action}
              <Button
                sx={{ color: "white", ml: 2 }}
                variant="outlined"
                startIcon={<RocketLaunch />}
                onClick={() => setIsShareOpen(true)}
              >
                {i18n.share}
              </Button>
            </Paper>
          </Box>
        )}
      </Box>

      {isShareOpen && (
        <BehaviorQuickstartModal
          template={template?.data}
          meta={{ blockchain: chain, contract_address: id }}
          open={isShareOpen}
          onClose={() => setIsShareOpen(false)}
        />
      )}
    </Box>
  );
};

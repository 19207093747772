import { Box, Typography } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import React from "react";

import { useParams } from "react-router";
import { BehaviorTemplateCard } from "../../components/cards/BehaviorTemplateCard";
import Layout from "../../components/Layout";
import { CardListing } from "../../components/layout/CardListing";
import { Header } from "./components/Header";

const i18n = {
  error: "The community you are looking for does not exist.",
};

const LIVE_COMMUNITIES = ["nouns", "builder", "highlight", "telefrens"];
const COMMUNITY_IDS = {
  nouns: {
    image: "/images/news_nouns.jpg",
    name: "Nouns Social Boost",
    templates: [5, 6, 2, 4, 3, 7, 8],
  },
  builder: {
    image: "/images/news_builder.jpeg",
    name: "BuilderDAO",
    templates: [13, 14, 15, 16],
  },
  highlight: {
    image: "/images/news_highlight.png",
    name: "Highlight.xyz",
    templates: [28, 30, 31],
  },
  telefrens: {
    image: "/images/community_telefrens.jpg",
    name: "TeleFrens",
    templates: [37, 38, 39, 41],
  },
};

export const Community = () => {
  const { id } = useParams();

  const community = COMMUNITY_IDS[id];
  const isActiveCommunity = LIVE_COMMUNITIES.includes(id);

  if (!isActiveCommunity) {
    return <Layout>{i18n.error}</Layout>;
  }

  return (
    <Layout>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}>
        <Header
          community={{
            name: community.name,
            description: (
              <Typography color={grey[400]}>
                Choose a message template and auto{" "}
                <span style={{ fontWeight: "bold", color: green[500] }}>
                  SHARE
                </span>{" "}
                it on your socials. It's free :)
              </Typography>
            ),
            image: community.image,
          }}
        />
      </Box>
      <Box></Box>
      <CardListing>
        {community.templates.map((id) => (
          <BehaviorTemplateCard key={`template-${id}`} id={id} />
        ))}
      </CardListing>
    </Layout>
  );
};

import { useQuery } from "react-query";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { getCollection } from "../api/v1/collections";

export const collectionQuery = (id) => `/collections/${id}`;

export const useCollection = (id) => {
  const userContext = useContext(UserContext);
  const query = collectionQuery(id);
  const { deleteUser } = userContext;

  const { data, isFetched, isFetching, isError, remove } = useQuery(
    query,
    () => getCollection(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: true,
      onError: (err) => {
        if (err.response.status === 401) {
          remove();
          deleteUser();
        }
      },
    },
  );
  return { data, isError, isFetched, isFetching, query };
};

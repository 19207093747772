import React, { createContext, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

export const ColorModeContext = createContext(null);

const ColorModeContextProvider = (props) => {
  const LOCAL_STORAGE_KEY = "colormode";
  const DARK = "dark";
  const LIGHT = "light";

  // defaulting to the dark mode
  // const saved = localStorage.getItem(LOCAL_STORAGE_KEY);
  const [mode, setMode] = useState(DARK);

  const toggleColorMode = () => {
    setMode((prevMove) => {
      const newMode = prevMove === LIGHT ? DARK : LIGHT;
      localStorage.setItem(LOCAL_STORAGE_KEY, newMode);
      return newMode;
    });
  };

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={{ mode, toggleColorMode }}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default ColorModeContextProvider;

import React from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useUserUpdate } from "../../hooks/useUserUpdate";

const i18n = {
  buttonCancel: "nevermind",
  buttonSubmit: "update",
  emailLabel: "Existing Email Address",
  modalTitle: "Update Email Address",
  newEmail: "New Email Address",
  passwordLabel: "Password",
};

const AccountEditModal = ({ open, onClose }) => {
  const { call: updateUser, isFetching } = useUserUpdate();

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  const onSubmit = (payload) => {
    updateUser(payload);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.modalTitle}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={2} sx={{ marginBottom: 5 }}>
            <TextField
              {...register("current_email")}
              autoComplete="email"
              label={i18n.emailLabel}
              required
              type="email"
              variant="outlined"
              fullWidth
            />

            <TextField
              {...register("password")}
              label={i18n.passwordLabel}
              required
              type="password"
              variant="outlined"
              fullWidth
            />

            <TextField
              {...register("email")}
              label={i18n.newEmail}
              required
              type="email"
              variant="outlined"
              fullWidth
            />
          </Stack>
          <Divider />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{i18n.buttonCancel}</Button>
          <LoadingButton type="submit" variant="contained" loading={isFetching}>
            {i18n.buttonSubmit}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AccountEditModal;

import React from "react";
import { DialogContent, DialogActions, Typography } from "@mui/material";

import { Button, Dialog, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useCollectionDelete } from "../../hooks/useCollectionDelete";

const i18n = {
  cancel: "nevermind",
  confirmation:
    "Are you sure you want to permanently delete this collection? This will also delete all contents and bot behaviors associated with it.",
  submit: "delete",
  title: "Delete Collection?",
};

export const CollectionDeleteModal = ({ open, collection, onClose }) => {
  const { call, isFetching } = useCollectionDelete();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.title}</DialogTitle>

      <DialogContent sx={{ marginBottom: 2 }}>
        <Typography>{i18n.confirmation}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          aria-label={i18n.cancel}
          disabled={isFetching}
        >
          {i18n.cancel}
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          onClick={() => call(collection.id)}
          loading={isFetching}
          aria-label={i18n.title}
        >
          {i18n.submit}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

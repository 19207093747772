import { useQuery } from "react-query";
import { getGroups } from "../api/v1/groups";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";

export const groupsQuery = (page = 1, includePublic = false, search) => {
  return `groups?page=${page}&public=${includePublic}&search=${search}`;
};

export const useGroups = ({
  enabled = true,
  page = 1,
  search,
  publicGroups: includePublic = false,
}) => {
  const userContext = useContext(UserContext);
  const query = groupsQuery(page, includePublic, search);
  const { deleteUser } = userContext;

  const { data, isFetched, isFetching, isError, remove } = useQuery(
    query,
    () => getGroups({ page, search, publicGroups: includePublic }),
    {
      enabled,
      refetchOnWindowFocus: true,
      onError: (err) => {
        if (err.response.status === 401) {
          remove();
          deleteUser();
        }
      },
    },
  );
  return { data, isError, isFetched, isFetching, query };
};

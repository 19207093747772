import { SvgIcon } from "@mui/material";

export const Builder = (props) => {
  const defaultProps = {
    width: 24,
    height: 24,
    ...props,
  };

  return (
    <SvgIcon sx={defaultProps}>
      <g>
        <rect x="5" y="5" width="2" height="14" fill="black" />
        <rect x="9" y="5" width="3" height="14" fill="black" />
        <rect x="14" y="5" width="5" height="14" fill="black" />
      </g>
    </SvgIcon>
  );
};

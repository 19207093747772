import React from "react";
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./routes/routes";

import { CssBaseline } from "@mui/material";

import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";

import LayoutNav from "./components/LayoutNav";

import UserContextProvider from "./contexts/UserContext";
import ColorModeContextProvider from "./contexts/ColorModeContext";
import { GlobalProvider } from "./contexts/GlobalState";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import RouteTracker from "./routes/RouteTracker";

ReactGA.initialize("G-61KHR78F7D", {
  debug: process.env.NODE_ENV === "development",
});

const queryClient = new QueryClient();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const AppWrapper = () => {
  return (
    <ColorModeContextProvider>
      <SnackbarProvider preventDuplicate>
        <CssBaseline>
          <Elements stripe={stripePromise}>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </Elements>
        </CssBaseline>
      </SnackbarProvider>
    </ColorModeContextProvider>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <RouteTracker />
      <UserContextProvider>
        <GlobalProvider>
          <LayoutNav />
          <Routes>
            {routes.map((route, idx) => (
              <Route key={idx} {...route} />
            ))}
          </Routes>
        </GlobalProvider>
      </UserContextProvider>
    </BrowserRouter>
  );
};

export default AppWrapper;

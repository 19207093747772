import { useQuery } from "react-query";
import { getBots } from "../api/v1/bots";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";

export const botsQuery = (page = 1, type, autopilot, order, search) => {
  return `/bots?page=${page}&type=${type}&autopilot=${autopilot}&order_by=${order}&search=${search}`;
};

export const useBots = ({
  autopilot,
  enabled = true,
  order,
  page = 1,
  search,
  type,
}) => {
  const userContext = useContext(UserContext);
  const { deleteUser } = userContext;
  const { data, isFetched, isError, remove } = useQuery(
    botsQuery(page, type, autopilot, order, search),
    () =>
      getBots({
        autopilot,
        order,
        page,
        search,
        type,
      }),
    {
      enabled,
      refetchOnWindowFocus: true,
      onError: (err) => {
        if (err.response.status === 401) {
          remove();
          deleteUser();
        }
      },
    },
  );
  return { data, isError, isFetched, useBotsQuery: botsQuery };
};

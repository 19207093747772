import { useState } from "react";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { deleteGroup } from "../api/v1/groups";
import { groupsQuery } from "./useGroups";

const i18n = {
  success: "Group deleted successfully.",
  error:
    "We are unable to delete this group at this time. Please try again later.",
};

export const useGroupDelete = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);

  const invalidateQueries = async (queries) => {
    await Promise.all(
      queries.map((key) => {
        return queryClient.invalidateQueries(key);
      }),
    );
  };

  const call = (id) => {
    setIsFetching(true);
    deleteGroup(id)
      .then(async () => {
        await invalidateQueries([groupsQuery()]);

        setIsFetching(true);
        enqueueSnackbar(i18n.success, { variant: "success" });
        navigate(`/home`);
      })
      .catch((err) => {
        setIsFetching(true);
        const errorMsg =
          err && err.response.data.error ? err.response.data.error : i18n.error;
        enqueueSnackbar(errorMsg, { variant: "error" });
      });
  };
  return { call, isFetching };
};

import { SvgIcon } from "@mui/material";

export const Optimism = (props) => {
  const defaultProps = {
    width: 24,
    height: 24,
    ...props,
  };

  return (
    <SvgIcon sx={defaultProps}>
      <path d="M6.31534 16.8C5.1649 16.8 4.22235 16.5511 3.4876 16.0534C2.76253 15.5466 2.39999 14.8267 2.39999 13.8933C2.39999 13.6977 2.42417 13.4578 2.47248 13.1733C2.59823 12.5334 2.77707 11.7644 3.00906 10.8667C3.66644 8.42222 5.36313 7.2 8.09905 7.2C8.8435 7.2 9.51053 7.31554 10.1003 7.54662C10.69 7.76883 11.1539 8.10663 11.4923 8.55999C11.8307 9.00443 12 9.53772 12 10.16C12 10.3466 11.9757 10.5823 11.9273 10.8667C11.7825 11.6578 11.6084 12.4266 11.4053 13.1733C11.0671 14.3911 10.4821 15.3023 9.65075 15.9067C8.81921 16.5022 7.70754 16.8 6.31534 16.8ZM6.51838 14.88C7.05982 14.88 7.51897 14.7334 7.89604 14.44C8.28282 14.1468 8.55829 13.6977 8.72261 13.0934C8.94489 12.2578 9.11418 11.5289 9.2301 10.9066C9.26874 10.72 9.28807 10.529 9.28807 10.3332C9.28807 9.52435 8.82896 9.11999 7.91055 9.11999C7.36911 9.11999 6.90509 9.26668 6.51838 9.55988C6.14138 9.85343 5.87065 10.3023 5.70635 10.9066C5.53231 11.5022 5.35825 12.2312 5.18429 13.0934C5.14558 13.271 5.12626 13.4578 5.12626 13.6532C5.12626 14.4711 5.59035 14.88 6.51838 14.88Z" />
      <path d="M12.2901 16.8C12.1869 16.8 12.1068 16.768 12.0505 16.7039C12.0034 16.6308 11.9892 16.5485 12.008 16.4572L13.9547 7.54285C13.9735 7.44229 14.0252 7.35999 14.1099 7.29597C14.1944 7.23202 14.2837 7.2 14.3778 7.2H18.13C19.1738 7.2 20.0106 7.41028 20.6406 7.83083C21.2802 8.25145 21.6 8.8594 21.6 9.65485C21.6 9.88334 21.5717 10.1212 21.5153 10.368C21.2802 11.4194 20.8053 12.1966 20.0906 12.6994C19.3854 13.2023 18.4167 13.4537 17.1849 13.4537H15.2807L14.6318 16.4572C14.6129 16.5578 14.5611 16.6399 14.4766 16.7039C14.392 16.768 14.3025 16.8 14.2085 16.8H12.2901ZM17.2837 11.5611C17.6786 11.5611 18.0219 11.456 18.3132 11.2458C18.6143 11.0354 18.8117 10.7338 18.9057 10.3405C18.934 10.1851 18.9481 10.048 18.9481 9.92911C18.9481 9.6639 18.8681 9.4629 18.7083 9.32577C18.5484 9.17942 18.2757 9.10633 17.8901 9.10633H16.1974L15.6614 11.5611H17.2837Z" />
    </SvgIcon>
  );
};

import { useState } from "react";
import { useSnackbar } from "notistack";
import { createSubscription } from "../api/v1/subscriptions.api";
import { useLoggedUserQuery } from "./useLoggedUser";
import { useQueryClient } from "react-query";

const i18n = {
  error: "Unable to upgrade your account at this time",
  success: "Account upgraded successfully",
};

export const useSubscriptionCreate = () => {
  const [isFetching, setFetching] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [isError, setError] = useState(false);
  const [data, setData] = useState(null);
  const queryClient = useQueryClient();
  const userQuery = useLoggedUserQuery();
  const { enqueueSnackbar } = useSnackbar();

  const call = (payload) => {
    setFetching(true);

    createSubscription(payload)
      .then((result) => {
        queryClient.invalidateQueries(userQuery).then(() => {
          enqueueSnackbar(i18n.success, { variant: "success" });
          setFetching(false);
          setFetched(true);
          setData(result);
        });
      })
      .catch((err) => {
        const errorMsg =
          err && err.response.data.error ? err.response.data.error : i18n.error;
        enqueueSnackbar(errorMsg, { variant: "error" });
        setFetching(false);
        setError(true);
      });
  };
  return { call, data, isFetched, isFetching, isError };
};

import { useQuery } from "react-query";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { getCollectionContents } from "../api/v1/collections";

export const collectionContentsQuery = (id, page = 1) => {
  return `/collection/${id}/contents?page=${page}`;
};

export const useCollectionContents = ({ id, page, enabled }) => {
  const userContext = useContext(UserContext);
  const query = collectionContentsQuery(id, page);
  const { deleteUser } = userContext;

  const { data, isFetched, isError, isFetching, remove } = useQuery(
    query,
    () => getCollectionContents(id, page),
    {
      refetchOnWindowFocus: false,
      enabled,
      onError: (err) => {
        if (err.response.status === 401) {
          remove();
          deleteUser();
        }
      },
    },
  );
  return { data, isError, isFetched, isFetching, query };
};

import React, { useState } from "react";
import { Box, Pagination } from "@mui/material";

import ProgressLoader from "../../components/utils/ProgressLoader";
import QueueSharedCard from "../../components/cards/QueueSharedCard";
import { CardListing } from "../../components/layout/CardListing";
import { deleteBotPost } from "../../api/v1/bots";
import { useBotPublishedPosts } from "../../hooks/useBotPublishedPosts";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

const i18n = {
  pageDescription: "This bot haven't shared anything yet...",
  postDeleteSuccess: "Post deleted",
  postDeletedError: "Unable to delete post at this time",
  postLoadError: "Unable to load published posts at this time...",
};

export const BotShared = ({ bot, parentQuery }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [page, setPage] = useState(
    new URLSearchParams(window.location.search).get("page") || 1,
  );

  const [deletingActionId, setDeletingAction] = useState();

  const {
    data: postsData,
    query: postsQuery,
    isError,
    isFetched,
  } = useBotPublishedPosts({
    bot,
    enabled: Number(bot.stats.shared) > 0,
    page,
  });

  const reloadPageData = () => {
    return queryClient
      .invalidateQueries(parentQuery)
      .then(() => queryClient.invalidateQueries(postsQuery));
  };

  const onPostDelete = (id) => {
    setDeletingAction(id);
    deleteBotPost(bot.id, id)
      .then(() => {
        reloadPageData().then(() => {
          setDeletingAction(null);
          enqueueSnackbar(i18n.postDeleteSuccess, { variant: "success" });
        });
      })
      .catch(() => {
        setDeletingAction(null);
        enqueueSnackbar(i18n.postDeletedError, { variant: "error" });
      });
  };

  const renderPagination = () => {
    const hasPagination = postsData.data.total_pages > 1;

    if (hasPagination) {
      return (
        <Pagination
          page={page}
          count={postsData.data.total_pages}
          onChange={(event, page) => {
            setPage(page);
            navigate(`?page=${page}`);
          }}
        />
      );
    }
  };

  if (Number(bot.stats.shared) === 0) {
    return i18n.pageDescription;
  }

  if (isError) {
    return i18n.postLoadError;
  }

  if (!isFetched) {
    return <ProgressLoader />;
  }

  return (
    <Box>
      {Number(postsData.data.total_pages) > 1 &&
        `Page ${postsData.data.page} of ${postsData.data.total_pages}`}

      <CardListing>
        {postsData.data.data.map((post) => (
          <QueueSharedCard
            key={post.id}
            isDeleting={deletingActionId === post.id}
            onDelete={() => {
              onPostDelete(post.id);
            }}
            post={post}
            bot={bot}
          />
        ))}
      </CardListing>
      {renderPagination()}
    </Box>
  );
};

import {
  ArrowUpward,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Twitter,
} from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Bluesky } from "../../../assets/icons/Bluesky";
import { Discord } from "../../../assets/icons/Discord";
import { Farcaster } from "../../../assets/icons/Farcaster";
import BotCreateModal from "../../../components/modals/BotCreateModal";
import { constructUrlWithParams } from "../../../components/utils/utils";
import {
  BOT_BLUESKY,
  BOT_DISCORD,
  BOT_FARCASTER,
  BOT_TWITTER,
} from "../../../constants/botTypes";

const i18n = {
  description:
    "Bots automate social media management. Create a bot to start automating.",
  title: "Bots",
  tooltip: "New Bot",
  botFilterTooltip: (type) => `${type.substring(3)} Bots`,
  filterActive: "Active Bots",
  filterDisabled: "Disabled Bots",
  filterFollowersUp: "Most Followers",
};

export const Header = ({ filter = false }) => {
  const type = new URLSearchParams(window.location.search).get("type") || null;
  const autopilot =
    new URLSearchParams(window.location.search).get("autopilot") || null;
  const page =
    Number(new URLSearchParams(window.location.search).get("page")) || 1;
  const order =
    new URLSearchParams(window.location.search).get("order_by") || null;
  const search =
    new URLSearchParams(window.location.search).get("search") || null;

  const navigate = useNavigate();
  const [isNewModal, setNewModal] = useState(false);

  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  const onSubmit = ({ keyword }) => {
    if (keyword === null || keyword === undefined || keyword === "") {
      keyword = null;
    }

    const path = constructUrlWithParams([
      { search: keyword },
      { order_by: order },
      { type },
      { autopilot },
    ]);

    window.location.href = path;
  };

  function reloadPageWithUpdatedParams(path) {
    if (page && page > 1) {
      // Hard refresh to avoid stale pagination data
      window.location.href = path;
    } else {
      navigate(path);
    }
  }

  const onTypeFilter = (filter) => {
    if (filter === type) {
      filter = null;
    }

    const path = constructUrlWithParams([
      { autopilot },
      { order_by: order },
      { page: 1 },
      { type: filter },
      { search },
    ]);

    reloadPageWithUpdatedParams(path);
  };

  const onAutopilotFilter = (filter) => {
    if (filter === null || filter === autopilot) {
      filter = null;
    }
    const path = constructUrlWithParams([
      { autopilot: filter },
      { order_by: order },
      { page: 1 },
      { type },
      { search },
    ]);
    reloadPageWithUpdatedParams(path);
  };

  const onOrderByFilter = (filter) => {
    if (filter === null || filter === order) {
      filter = null;
    }
    const path = constructUrlWithParams([
      { autopilot },
      { order_by: filter },
      { page: 1 },
      { type },
      { search },
    ]);
    reloadPageWithUpdatedParams(path);
  };

  const renderFilter = () => {
    return (
      <Stack
        direction="row"
        spacing={2}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Typography variant="overline" sx={{ mr: 2 }} color={grey[600]}>
          search
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register("keyword")}
            defaultValue={search}
            variant="outlined"
            size="small"
          />
        </form>

        <Typography variant="overline" sx={{ mr: 2 }} color={grey[600]}>
          filters
        </Typography>

        <ButtonGroup variant="outlined" size="small">
          <Tooltip title={i18n.filterFollowersUp}>
            <Button
              color={
                order !== null && order === "followers_count"
                  ? "secondary"
                  : "primary"
              }
              onClick={() => onOrderByFilter("followers_count")}
            >
              <ArrowUpward />
            </Button>
          </Tooltip>
        </ButtonGroup>

        <ButtonGroup variant="outlined" size="small">
          <Tooltip title={i18n.filterActive}>
            <Button
              color={
                autopilot !== null && autopilot === "true"
                  ? "secondary"
                  : "primary"
              }
              onClick={() => onAutopilotFilter("true")}
            >
              <RadioButtonChecked />
            </Button>
          </Tooltip>
          <Tooltip title={i18n.filterDisabled}>
            <Button
              color={
                autopilot !== null && autopilot === "false"
                  ? "secondary"
                  : "primary"
              }
              onClick={() => onAutopilotFilter("false")}
            >
              <RadioButtonUnchecked />
            </Button>
          </Tooltip>
        </ButtonGroup>
        <ButtonGroup variant="outlined" size="small">
          <Tooltip title={i18n.botFilterTooltip(BOT_TWITTER)}>
            <Button
              color={type === BOT_TWITTER ? "secondary" : "primary"}
              onClick={() => onTypeFilter(BOT_TWITTER)}
            >
              <Twitter />
            </Button>
          </Tooltip>
          <Tooltip title={i18n.botFilterTooltip(BOT_DISCORD)}>
            <Button
              color={type === BOT_DISCORD ? "secondary" : "primary"}
              onClick={() => onTypeFilter(BOT_DISCORD)}
            >
              <Discord />
            </Button>
          </Tooltip>
          <Tooltip title={i18n.botFilterTooltip(BOT_FARCASTER)}>
            <Button
              color={type === BOT_FARCASTER ? "secondary" : "primary"}
              onClick={() => onTypeFilter(BOT_FARCASTER)}
            >
              <Farcaster />
            </Button>
          </Tooltip>
          <Tooltip title={i18n.botFilterTooltip(BOT_BLUESKY)}>
            <Button
              color={type === BOT_BLUESKY ? "secondary" : "primary"}
              onClick={() => onTypeFilter(BOT_BLUESKY)}
            >
              <Bluesky />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Stack>
    );
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="h5">
          {i18n.title}
          <Tooltip title={i18n.tooltip} placement="right" arrow>
            <IconButton
              color="primary"
              aria-label={i18n.tooltip}
              onClick={() => setNewModal(true)}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </Typography>
        {!filter && (
          <Typography color={grey[500]}>{i18n.description}</Typography>
        )}

        {isNewModal && (
          <BotCreateModal
            open={isNewModal}
            onClose={() => setNewModal(false)}
          />
        )}
      </Box>

      {filter && renderFilter()}
    </Box>
  );
};

import { SvgIcon } from "@mui/material";

export const Opensea = (props) => {
  const defaultProps = {
    width: 24,
    height: 24,
    ...props,
  };

  return (
    <SvgIcon sx={defaultProps}>
      <path
        d="M16.8048 14.1528C16.3884 14.7108 15.5124 15.6372 15.0252 15.6372H12.7608V14.1516H14.5644C14.8224 14.1516 15.0684 14.0472 15.246 13.8636C16.1724 12.9036 16.7076 11.7612 16.7076 10.536C16.7076 8.44561 15.1452 6.59761 12.7596 5.48401V4.48561C12.7596 4.05601 12.4116 3.70801 11.982 3.70801C11.5524 3.70801 11.2044 4.05601 11.2044 4.48561V4.89961C10.536 4.70401 9.828 4.55521 9.0888 4.47001C10.3128 5.79961 11.0604 7.57801 11.0604 9.52801C11.0604 11.2764 10.4628 12.882 9.45841 14.154H11.2044V15.642H8.6484C8.3028 15.642 8.022 15.3624 8.022 15.0156V14.3652C8.022 14.2512 7.92961 14.1576 7.81441 14.1576H4.4448C4.3788 14.1576 4.32241 14.2116 4.32241 14.2776C4.32001 16.9392 6.426 18.9372 8.9448 18.9372H16.0548C17.7588 18.9372 18.5208 16.7532 19.5192 15.3636C19.9068 14.826 20.8368 14.394 21.1188 14.274C21.1704 14.2524 21.2004 14.2044 21.2004 14.148V13.284C21.2004 13.1964 21.114 13.1304 21.0288 13.1544C21.0288 13.1544 16.9188 14.0988 16.8708 14.112C16.8228 14.1264 16.8048 14.154 16.8048 14.154V14.1528Z"
        fill="white"
      />
      <path
        d="M9.744 9.522C9.744 8.1816 9.2844 6.948 8.5164 5.9724L4.6488 12.6648H8.8092C9.4008 11.7624 9.7452 10.6824 9.7452 9.5232L9.744 9.522Z"
        fill="white"
      />
      <path
        d="M12.1042 -0.000240418C5.39376 -0.0554404 -0.0554404 5.39376 -0.000240418 12.1042C0.0561596 18.6202 5.37936 23.9446 11.8942 23.9986C18.6046 24.0562 24.0562 18.6046 23.9986 11.8942C23.9446 5.38056 18.6202 0.0561596 12.1042 -0.000240418ZM8.51616 5.97216C9.28416 6.94776 9.74376 8.18256 9.74376 9.52176C9.74376 10.6822 9.39936 11.7622 8.80776 12.6634H4.64736L8.51496 5.97096L8.51616 5.97216ZM21.2002 13.2826V14.1466C21.2002 14.203 21.1702 14.251 21.1186 14.2726C20.8366 14.3926 19.9066 14.8246 19.519 15.3622C18.5206 16.7518 17.7586 18.9358 16.0546 18.9358H8.94456C6.42696 18.9358 4.32096 16.9378 4.32216 14.2762C4.32216 14.2102 4.37856 14.1562 4.44456 14.1562H7.81416C7.93056 14.1562 8.02176 14.2498 8.02176 14.3638V15.0142C8.02176 15.3598 8.30136 15.6406 8.64816 15.6406H11.2042V14.1526H9.45816C10.4626 12.8806 11.0602 11.275 11.0602 9.52656C11.0602 7.57656 10.3126 5.79816 9.08856 4.46856C9.82896 4.55496 10.537 4.70256 11.2042 4.89816V4.48416C11.2042 4.05456 11.5522 3.70656 11.9818 3.70656C12.4114 3.70656 12.7594 4.05456 12.7594 4.48416V5.48256C15.145 6.59616 16.7074 8.44416 16.7074 10.5346C16.7074 11.761 16.171 12.9022 15.2458 13.8622C15.0682 14.0458 14.8222 14.1502 14.5642 14.1502H12.7606V15.6358H15.025C15.5134 15.6358 16.3894 14.7094 16.8046 14.1514C16.8046 14.1514 16.8226 14.1238 16.8706 14.1094C16.9186 14.095 21.0286 13.1518 21.0286 13.1518C21.115 13.1278 21.2002 13.1938 21.2002 13.2814V13.2826Z"
        fill="#0086FF"
      />
    </SvgIcon>
  );
};

import { useQuery } from "react-query";
import { Api, LONG_STALE } from "../api/axios.config";

const RARIBLE_API_PATH = "https://api.rarible.org/v0.1";

export const useRaribleCollectionItems = ({
  enabled = true,
  contractAddress,
  blockchain,
}) => {
  const query = `${RARIBLE_API_PATH}/items/byCollection?collection=${blockchain}:${contractAddress}`;
  const { data, isError, isFetched, isFetching } = useQuery(
    query,
    () =>
      Api.get(query, {
        headers: {
          "x-api-key": process.env.REACT_APP_RARIBLE_KEY,
        },
      }),
    {
      enabled,
      staleTime: LONG_STALE,
      refetchOnWindowFocus: false,
    },
  );
  return { data, isError, isFetched, isFetching, query };
};

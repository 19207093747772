import { blueGrey } from "@mui/material/colors";
import { Box, Button, Card } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";

export const BotViewAllCard = ({ count }) => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{ mb: 2, mr: 2, width: 290, backgroundColor: blueGrey[900] }}
      raised
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Button
          size="small"
          onClick={() => {
            navigate("/bots");
            window.scrollTo(0, 0);
          }}
        >
          {`view all ${count} bots`}
        </Button>
      </Box>
    </Card>
  );
};

import { SvgIcon } from "@mui/material";

export const Bluesky = (props) => {
  const defaultProps = {
    width: 24,
    height: 24,
    ...props,
  };

  return (
    <SvgIcon sx={defaultProps}>
      <path d="M11.9906 10.8739C10.9921 8.89442 8.2834 5.19734 5.76199 3.37754C3.34667 1.63436 2.42298 1.93447 1.82383 2.21542C1.12482 2.54107 1 3.63934 1 4.28425C1 4.92916 1.34326 9.58403 1.57418 10.3566C2.32312 12.9235 4.9756 13.7919 7.42213 13.511C7.54695 13.4918 7.67177 13.4727 7.80284 13.4599C7.67801 13.479 7.54695 13.4982 7.42213 13.511C3.83972 14.0537 0.650497 15.3882 4.83206 20.1453C9.42553 25.0109 11.1294 19.1045 12.0031 16.1034C12.8769 19.0981 13.8817 24.7938 19.0868 20.1453C23 16.1034 20.1603 14.0537 16.5779 13.511C16.453 13.4982 16.322 13.479 16.1972 13.4599C16.3282 13.479 16.453 13.4918 16.5779 13.511C19.0244 13.7919 21.6831 12.9235 22.4258 10.3566C22.6505 9.57764 23 4.92916 23 4.28425C23 3.63934 22.8752 2.54107 22.1762 2.21542C21.5708 1.93447 20.6533 1.63436 18.238 3.37754C15.6979 5.20373 12.9892 8.89442 11.9906 10.8739Z" />
    </SvgIcon>
  );
};

import React from "react";
import { useNavigate } from "react-router";

import { Avatar, Button, Card, CardHeader, IconButton } from "@mui/material";

import CollectionsIcon from "@mui/icons-material/Collections";
import { Edit, OpenInNew } from "@mui/icons-material";

import { truncate } from "../utils/utils";
import { useTheme } from "@mui/material/styles";
import { collectionItemCount, collectionUrl } from "../utils/collectionUtils";
import { Collection } from "../../types/types";

interface Props {
  collection: Collection;
  onClick?: () => void;
}

export const CollectionEmbedCard = ({ collection, onClick }: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { cover, items, title, id } = collection;

  const renderAvatar = () => {
    if (cover) {
      return <Avatar variant="rounded" src={cover} />;
    } else {
      return (
        <Avatar variant="rounded">
          <CollectionsIcon />
        </Avatar>
      );
    }
  };

  const renderAction = () => {
    if (onClick) {
      return (
        <Button
          color="primary"
          variant="outlined"
          sx={{ mt: 1 }}
          onClick={onClick}
          endIcon={<Edit fontSize="small" />}
        >
          Edit
        </Button>
      );
    } else {
      return (
        <IconButton
          color="primary"
          size="small"
          sx={{ mt: 1 }}
          onClick={() => navigate(collectionUrl(id))}
        >
          <OpenInNew fontSize="small" />
        </IconButton>
      );
    }
  };

  return (
    <Card
      sx={{ width: "100%", backgroundColor: theme.palette.grey[800] }}
      variant="outlined"
    >
      <CardHeader
        avatar={renderAvatar()}
        title={truncate(title, 25)}
        subheader={collectionItemCount(items)}
        action={renderAction()}
      />
    </Card>
  );
};

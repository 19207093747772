import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useBotUpdate } from "../../hooks/useBotUpdate";
import jstz from "jstz";

import { grey } from "@mui/material/colors";
import { botVisibility, botAutopilot } from "../utils/botUtils";
import AuthenticationConnectCard from "../cards/AuthenticationConnectCard";
import AuthenticationDisconnectCard from "../cards/AuthenticationDisconnectCard";

const i18n = {
  autopilotOffDescription:
    "The bot is currently paused, and it will not automatically share any new posts.",
  autopilotOnDescription:
    "The bot actively shares content and queues new posts every day at midnight.",
  timezoneAgnostic:
    "The bot schedules posts regularly throughout the 24-hour day.",
  timezoneLabel: (agnostic) =>
    agnostic ? "All-Hours Scheduling" : "Timezone-Optimized Post Scheduling",
  timezoneNormal: (timezone) =>
    `The bot actively optimizes post scheduling to maximize engagement within the ${timezone} timezone.`,
  timezoneReset: "Reset Timezone",
  timezoneDetect: (timezone) => `Detected timezone ${timezone}`,
  publicOn: "Anyone can view or use this bot, but only you can manage it.",
  publicOff: "Only you can view this bot.",
};

export const BotUpdateModal = ({ bot, open, onClose }) => {
  const { call, isFetching } = useBotUpdate(bot);
  const currentTimezone = jstz.determine().name();

  const onAutopilotUpdate = () => {
    call(bot.id, { autopilot: !bot.autopilot });
  };

  const onTimezoneUpdate = () => {
    call(bot.id, { timezone_agnostic: !bot.timezone_agnostic });
  };

  const onTimezoneReset = () => {
    call(bot.id, { timezone: currentTimezone });
  };

  const onPublicUpdate = () => {
    call(bot.id, { private: !bot.private });
  };

  const renderAuth = () => {
    if (bot.authentication) {
      return (
        <AuthenticationDisconnectCard
          authentication={bot.authentication}
          bot={bot}
        />
      );
    } else {
      return <AuthenticationConnectCard bot={bot} />;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.title}</DialogTitle>

      <DialogContent>
        <Stack direction="column" spacing={2}>
          {renderAuth()}
          <Card variant="outlined" sx={{ background: "none" }}>
            <CardHeader
              title={
                <Typography variant={"body1"}>
                  {botAutopilot(bot.authentication, bot.autopilot)}
                </Typography>
              }
              subheader={
                bot.autopilot
                  ? i18n.autopilotOnDescription
                  : i18n.autopilotOffDescription
              }
              action={
                <Switch
                  disabled={isFetching}
                  checked={bot.autopilot}
                  onChange={onAutopilotUpdate}
                />
              }
            />
          </Card>
          <Card variant={"outlined"} sx={{ background: "none" }}>
            <CardHeader
              subheader={
                bot.timezone_agnostic
                  ? i18n.timezoneAgnostic
                  : i18n.timezoneNormal(bot.timezone)
              }
              title={
                <Typography variant={"body1"}>
                  {i18n.timezoneLabel(bot.timezone_agnostic)}
                </Typography>
              }
              action={
                <Switch
                  disabled={isFetching}
                  checked={!bot.timezone_agnostic}
                  onChange={onTimezoneUpdate}
                />
              }
            />
            {!bot.timezone_agnostic && (
              <Stack
                sx={{ p: 2 }}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Button
                  disabled={isFetching}
                  sx={{ mr: 2 }}
                  variant="outlined"
                  onClick={onTimezoneReset}
                >
                  {i18n.timezoneReset}
                </Button>
                <Typography variant="body1" sx={{ color: grey[600] }}>
                  {i18n.timezoneDetect(currentTimezone)}
                </Typography>
              </Stack>
            )}
          </Card>
          <Card variant={"outlined"} sx={{ background: "none" }}>
            <CardHeader
              title={
                <Typography variant={"body1"}>
                  {botVisibility(bot.private)}
                </Typography>
              }
              subheader={bot.private ? i18n.publicOff : i18n.publicOn}
              action={
                <Switch
                  disabled={isFetching}
                  checked={bot.private}
                  onChange={onPublicUpdate}
                />
              }
            />
          </Card>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useCollectionCreate } from "../../hooks/useCollectionCreate";

const i18n = {
  cancel: "Nevermind",
  inputHelper: "Something simple and short. You can also change it later",
  inputTitle: "Collection Title",
  submit: "Create",
  title: "New Collection",
};

const CollectionCreateModal = ({ onClose, open }) => {
  const { call, isFetching, isFetched: isCreated } = useCollectionCreate();
  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
    shouldUseNativeValidation: false,
  });

  useEffect(() => {
    if (isCreated) {
      onClose();
    }
  }, [isCreated, onClose]);

  const renderButtons = () => {
    return (
      <Stack direction="row" sx={{ marginTop: 1 }} spacing={1}>
        <Button variant="outlined" disabled={isFetching} onClick={onClose}>
          {i18n.cancel}
        </Button>
        <LoadingButton variant="contained" loading={isFetching} type="submit">
          {i18n.submit}
        </LoadingButton>
      </Stack>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.title}</DialogTitle>
      <form onSubmit={handleSubmit(call)} autoComplete="off">
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <TextField
              fullWidth
              {...register(`title`)}
              aria-label={i18n.inputTitle}
              helperText={i18n.inputHelper}
              label={i18n.inputTitle}
              required={true}
              type={"text"}
            />
          </Box>
        </DialogContent>
        <DialogActions>{renderButtons()}</DialogActions>
      </form>
    </Dialog>
  );
};

export default CollectionCreateModal;

import React, { useContext } from "react";
import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";

import { TwitterConnect } from "../parts/TwitterConnect";
import { GlobalContext } from "../../contexts/GlobalState";
import { DiscordConnect } from "../parts/DiscordConnect";
import { FarcasterConnect } from "../parts/FarcasterConnect";
import { BlueskyConnect } from "../parts/BlueskyConnect";

const i18n = {
  modalTitle: "New Bot",
};

const BotCreateModal = ({ onClose, open }) => {
  const { setActiveBot } = useContext(GlobalContext);

  const resetActiveBot = () => {
    setActiveBot(null);
  };

  const renderContent = () => {
    return (
      <Stack spacing={2} sx={{ marginTop: 1 }}>
        <TwitterConnect
          onClick={resetActiveBot}
          onConnected={onClose}
          label={"New Twitter Bot"}
        />
        <DiscordConnect
          onClick={resetActiveBot}
          onConnected={onClose}
          label={"New Discord Bot"}
        />
        <FarcasterConnect
          onClick={resetActiveBot}
          onConnected={onClose}
          label={"New Farcaster Bot"}
        />
        <BlueskyConnect
          onClick={resetActiveBot}
          onConnected={onClose}
          label={"New Bluesky Bot"}
        />
      </Stack>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle id="dialog-title">{i18n.modalTitle}</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
    </Dialog>
  );
};
export default BotCreateModal;

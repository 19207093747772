import { Api } from "../axios.config";

const path = "/bots";

export const createBot = (payload) => Api.post(path, payload);

export const getBot = (id) => Api.get(`${path}/${id}`);

export const getBots = ({ autopilot, order, page = 1, search, type }) => {
  let url = `/bots?page=${page}`;
  if (type) {
    url = `${url}&type=${type}`;
  }
  if (autopilot) {
    url = `${url}&autopilot=${autopilot}`;
  }
  if (order) {
    url = `${url}&order_by=${order}`;
  }
  if (search) {
    url = `${url}&search=${search}`;
  }

  return Api.get(url);
};

export const getPublicBots = (search) =>
  search
    ? Api.get(`${path}?public=true&search=${search}`)
    : Api.get(`${path}?public=true`);

export const editBot = (id, payload) => Api.put(`${path}/${id}`, payload);

export const unmuteBot = (id) => Api.put(`${path}/${id}/unmute`);

export const getBotStats = (id) => Api.get(`${path}/${id}/stats`);

export const deleteBot = (id) => Api.delete(`${path}/${id}`);

// Behaviors
export const deleteBotBehavior = (botId, behaviorId) =>
  Api.delete(`${path}/${botId}/behaviors/${behaviorId}`);

export const editBotBehavior = (botId, behaviorId, payload) =>
  Api.put(`${path}/${botId}/behaviors/${behaviorId}`, payload);

export const unmuteBotBehavior = (botId, behaviorId) =>
  Api.put(`${path}/${botId}/behaviors/${behaviorId}`, {
    enabled: true,
    error: null,
  });

export const createBotQuickstart = (botId, payload) =>
  Api.post(`${path}/${botId}/quickstarts`, payload);

export const addBotBehavior = (botId, payload) =>
  Api.post(`${path}/${botId}/behaviors`, payload);

// Queue
export const createBotQueue = (botId) => Api.post(`${path}/${botId}/queues`);

export const deleteBotQueue = (botId) => Api.delete(`${path}/${botId}/queues`);

export const getBotPublishedPosts = (botId, page) =>
  Api.get(`${path}/${botId}/bot_actions?page=${page}`);

export const getBotPendingPosts = (botId, page) =>
  Api.get(`${path}/${botId}/bot_actions?page=${page}&pending=true`);

export const shareBotPost = (botId, actionId) =>
  Api.post(`${path}/${botId}/bot_actions/${actionId}/share`);

export const deleteBotPost = (botId, actionId) =>
  Api.delete(`${path}/${botId}/bot_actions/${actionId}`);

// TODO: Rename
export const addBotToGroup = (botId, payload) =>
  Api.post(`${path}/${botId}/groups/`, payload);

export const deleteBotFromGroup = (botId, groupId) =>
  Api.delete(`${path}/${botId}/groups/${groupId}`);

import { Api } from "../axios.config";

const path = "/subscriptions";

export const getPlans = () => Api.get(path);

export const getSubscription = (id) => Api.get(`${path}/${id}`);
export const createSubscription = (payload) => Api.post(path, payload);
export const updateSubscription = (id, payload) =>
  Api.put(`${path}/${id}`, payload);
export const deleteSubscription = (id) => Api.delete(`${path}/${id}`);

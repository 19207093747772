export const BOT_TWITTER = "BotTwitter";
export const BOT_DISCORD = "BotDiscord";
export const BOT_MASTODON = "BotMastodon";
export const BOT_FARCASTER = "BotFarcaster";
export const BOT_BLUESKY = "BotBluesky";
export const BOT_TELEGRAM = "BotTelegram";

export const BLUESKY = "bluesky";
export const DISCORD = "discord";
export const FARCASTER = "farcaster";
export const MASTODON = "mastodon";
export const TWITTER = "twitter";
export const TELEGRAM = "twitter";

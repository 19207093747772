import { SvgIcon } from "@mui/material";

export const Highlight = (props) => {
  const defaultProps = {
    width: 24,
    height: 24,
    ...props,
  };

  return (
    <SvgIcon sx={defaultProps}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 5V19H15.0435C18.8855 19 22 15.866 22 12C22 8.13401 18.8855 5 15.0435 5H2ZM14.3044 6.53725C11.6284 6.90041 9.56523 9.20777 9.56523 12C9.56523 14.7923 11.6284 17.0996 14.3044 17.4628V6.53725ZM15.7826 17.4628C18.4586 17.0996 20.5218 14.7923 20.5218 12C20.5218 9.20777 18.4586 6.90041 15.7826 6.53725V17.4628ZM10.7556 17.5125C9.13092 16.2311 8.08697 14.2382 8.08697 12C8.08697 9.76179 9.13092 7.7689 10.7556 6.48751H3.47827V17.5125H10.7556Z"
        fill="white"
      />
    </SvgIcon>
  );
};

import { useState } from "react";
import { updateAuthentication } from "../api/v1/authentications";
import { useSnackbar } from "notistack";

const i18n = {
  success: "Authentication updated successfully",
  error: "Unable to update authentication at this time ",
};

export const useAuthenticationUpdate = () => {
  const [isFetching, setFetching] = useState(false);
  const [isError, setError] = useState(false);
  const [data, setData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const call = (id, payload) => {
    setFetching(true);
    updateAuthentication(id, payload)
      .then((result) => {
        setData(result.data);
        setFetching(false);
        enqueueSnackbar(i18n.success, { variant: "success" });
      })
      .catch(() => {
        setFetching(false);
        setError(true);
        enqueueSnackbar(i18n.error, { variant: "error" });
      });
  };
  return { data, call, isFetching, isError };
};

import { Api } from "../axios.config";

const path = "/contents";

export const createContent = (payload) => {
  return Api.post(path, payload, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

export const getContent = (id) => Api.get(`${path}/${id}`);

export const updateContent = (id, payload) => {
  return Api.put(`${path}/${id}`, payload, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

export const deleteContent = (id) => Api.delete(`${path}/${id}`);

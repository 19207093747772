import React from "react";
import { LoadingButton } from "@mui/lab";
import { Discord } from "../../assets/icons/Discord";

const i18n = {
  label: "Connect Discord",
};

const DISCORD_AUTH_URI = `https://discord.com/api/oauth2/authorize?client_id=978312705263960154&permissions=274878023696&redirect_uri=${encodeURIComponent(
  process.env.REACT_APP_DISCORD_REDIRECT,
)}&response_type=code&scope=identify%20bot`;

export const DiscordConnect = ({
  onClick,
  onConnected,
  label = i18n.label,
}) => {
  const onConnectDiscord = () => {
    onClick();
    onConnected();
    window.open(DISCORD_AUTH_URI, "_blank");
  };

  return (
    <LoadingButton
      sx={{ backgroundColor: "#7289da" }}
      variant="contained"
      onClick={onConnectDiscord}
      startIcon={<Discord width={20} height={20} inheritViewBox />}
    >
      {label}
    </LoadingButton>
  );
};

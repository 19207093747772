import React, { createContext, useReducer, useEffect } from "react";
import AppReducer from "./AppReducer";

const LOCAL_STORAGE_KEY = "state";
const savedState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

const defaultState = {
  activeBot: null,
  user: null,
};

const initialState = savedState || defaultState;

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  //Persist local state
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
  }, [state]);

  const setActiveBot = (id) => {
    dispatch({
      type: "SET_ACTIVE_BOT",
      payload: id,
    });
  };

  const setUser = (payload) => {
    dispatch({
      type: "SET_USER",
      payload,
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        activeBot: state.activeBot,
        setActiveBot,
        setUser,
        user: state.user,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

import React, { useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { useLoggedUser } from "../../hooks/useLoggedUser";

import { Edit, Loyalty, Person, Report, Share } from "@mui/icons-material";
import { cyan, green, grey, pink, red } from "@mui/material/colors";
import ProgressLoader from "../utils/ProgressLoader";
import SubscriptionCreateModal from "../modals/SubscriptionCreateModal";
import SubscriptionDeleteModal from "../modals/SubscriptionDeleteModal";
import SubscriptionInfoModal from "../modals/SubscriptionInfoModal";
import SubscriptionUpdateModal from "../modals/SubscriptionUpdateModal";
import { STRIPE_SUBSCRIPTION } from "../../constants/subscriptionTypes";
import AccountEditModal from "../modals/AccountEditModal";
import AuthenticationEditModal from "../modals/AuthenticationEditModal";
import moment from "moment/moment";

const i18n = {
  accountInfo: "User Info",
  auths: "Connected Social Accounts",
  subscriptionExpired: "Please renew your subscription.",
  billing: "plan details",
  plan: "Plan",
  cancel: "cancel",
  error: "Unable to load account info at this time...",
  defaultPlan: "Subscription",
  payment: "payment method",
  unlimited: "Unlimited daily posts",
  upgrade: "premium plans",
  socials: (count) => `${count} account${count === 1 ? "" : "s"}`,
  usage: (shared, limit) =>
    shared >= limit
      ? `You have reached the daily limit of ${limit} posts`
      : `${shared} of ${limit} daily posts shared`,
};

export const AccountCard = () => {
  const { user, isFetched, isError } = useLoggedUser();
  const [isSubModal, setSubModal] = useState(false);
  const [isDelModal, setDelModal] = useState(false);
  const [isUpdModal, setUpdModal] = useState(false);
  const [isEditModal, setEditModal] = useState(false);
  const [isAuthModal, setAuthModal] = useState(false);
  const [isSubInfoModal, setSubInfoModal] = useState(false);

  if (!isFetched && !user) {
    return "Loading...";
  }

  const plan = user?.data?.subscription_plan;
  const email = user?.data?.email;
  const auths = user?.data?.authentications;
  const hasAuths =
    user?.data?.authentications && user?.data?.authentications.length > 0;
  const isStripe = plan && plan?.type === STRIPE_SUBSCRIPTION;

  const { posts_shared, posts_limit } = user?.data?.account_usage;
  const isUnlimited = posts_limit < 0;

  const planName = plan ? plan.name : i18n.defaultPlan;
  const planUsage = Math.min((posts_shared / posts_limit) * 100, 100);
  const planExpired =
    plan &&
    plan?.expiration_date &&
    moment().diff(plan.expiration_date, "days") > 0;

  let planUsageColor;

  if (planUsage < 80) {
    planUsageColor = "success";
  } else if (planUsage > 80 && planUsage < 100) {
    planUsageColor = "warning";
  } else {
    planUsageColor = "error";
  }

  const subModalProps = {
    onClose: () => setSubModal(false),
    open: isSubModal,
  };
  const delModalProps = {
    plan,
    onClose: () => setDelModal(false),
    open: isDelModal,
  };
  const infoModalProps = {
    plan,
    onClose: () => setSubInfoModal(false),
    open: isSubInfoModal,
  };

  const updModalProps = {
    plan,
    onClose: () => setUpdModal(false),
    open: isUpdModal,
  };

  const editModalProps = {
    onClose: () => setEditModal(false),
    open: isEditModal,
  };

  const renderUpgradeAction = () => {
    return (
      <IconButton
        aria-label={i18n.accountInfo}
        onClick={() => setSubModal((prev) => !prev)}
      >
        <Edit />
      </IconButton>
    );
  };

  const renderActions = () => {
    if (user?.data?.subscription_plan) {
      return (
        <>
          <Button size="small" onClick={() => setSubInfoModal((prev) => !prev)}>
            {i18n.billing}
          </Button>

          {isStripe && (
            <Button size="small" onClick={() => setUpdModal((prev) => !prev)}>
              {i18n.payment}
            </Button>
          )}
          <Button
            size="small"
            sx={{ marginLeft: "auto", color: grey[600] }}
            onClick={() => setDelModal((prev) => !prev)}
          >
            {i18n.cancel}
          </Button>
        </>
      );
    }
  };

  if (!isFetched) {
    return (
      <Card>
        <CardContent>
          <ProgressLoader />
        </CardContent>
      </Card>
    );
  }

  if (!user || isError) {
    return (
      <Card>
        <CardContent>{i18n.error}</CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        action={
          <IconButton
            aria-label={i18n.accountInfo}
            onClick={() => setEditModal((prev) => !prev)}
          >
            <Edit />
          </IconButton>
        }
        avatar={
          <Avatar sx={{ bgcolor: cyan[200] }} variant="circle">
            <Person />
          </Avatar>
        }
        subheader={email}
        title={i18n.accountInfo}
      />
      <Divider />
      {hasAuths && (
        <>
          <CardHeader
            action={
              <IconButton
                aria-label={i18n.auths}
                onClick={() => setAuthModal(true)}
              >
                <Edit />
              </IconButton>
            }
            avatar={
              <Avatar sx={{ bgcolor: pink[100] }} variant="circle">
                <Share />
              </Avatar>
            }
            subheader={i18n.socials(auths.length)}
            title={i18n.auths}
          />
          <Divider />
        </>
      )}

      <CardHeader
        action={renderUpgradeAction()}
        avatar={
          <Avatar
            sx={{ bgcolor: !plan || planExpired ? red[400] : green[300] }}
            variant="circle"
          >
            {!plan || planExpired ? <Report /> : <Loyalty />}
          </Avatar>
        }
        subheader={
          !plan || planExpired
            ? i18n.subscriptionExpired
            : isUnlimited
              ? i18n.unlimited
              : i18n.usage(posts_shared, posts_limit)
        }
        title={`${planName} ${i18n.plan} ${planExpired ? "- Expired" : ""}`}
      />
      {!isUnlimited && !planExpired && plan && (
        <CardContent>
          <LinearProgress
            thickness={4}
            value={planUsage}
            color={planUsageColor}
            variant="determinate"
          />
        </CardContent>
      )}
      <CardActions disableSpacing>{renderActions()}</CardActions>
      {isSubModal && <SubscriptionCreateModal {...subModalProps} />}
      {isDelModal && <SubscriptionDeleteModal {...delModalProps} />}
      {isSubInfoModal && <SubscriptionInfoModal {...infoModalProps} />}
      {isUpdModal && <SubscriptionUpdateModal {...updModalProps} />}
      {isEditModal && <AccountEditModal {...editModalProps} />}
      {isAuthModal && (
        <AuthenticationEditModal
          open={isAuthModal}
          onClose={() => setAuthModal(false)}
        />
      )}
    </Card>
  );
};

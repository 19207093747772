import { useState } from "react";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { deleteBotFromGroup } from "../api/v1/bots";
import { groupBotsQuery } from "./useGroupBots";
import { groupQuery } from "./useGroup";

const i18n = {
  error: "Unable to remove bot to this group",
  success: "Bot removed successfully",
};

export const useGroupDeleteBot = () => {
  const [isFetching, setFetching] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [isError, setError] = useState(false);
  const [data, setData] = useState(null);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const invalidateQueries = async (queries) => {
    await Promise.all(
      queries.map((key) => {
        return queryClient.invalidateQueries(key);
      }),
    );
  };

  const call = (id, groupId) => {
    setFetching(true);

    deleteBotFromGroup(id, groupId)
      .then(async (result) => {
        await invalidateQueries([groupQuery(groupId), groupBotsQuery(groupId)]);

        enqueueSnackbar(i18n.success, { variant: "success" });
        setFetching(false);
        setFetched(true);
        setData(result);
      })
      .catch((err) => {
        const errorMsg =
          err && err.response.data.error ? err.response.data.error : i18n.error;
        enqueueSnackbar(errorMsg, { variant: "error" });
        setFetching(false);
        setError(true);
      });
  };
  return { call, data, isFetched, isFetching, isError };
};

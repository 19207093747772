import React from "react";
import { Box } from "@mui/material";

const Layout = ({ children }) => {
  return (
    <Box sx={{ marginTop: { md: 10, sm: 7, xs: 6 }, padding: 3 }}>
      {children}
    </Box>
  );
};

export default Layout;

import React, { useContext } from "react";

import ProgressLoader from "../../components/utils/ProgressLoader";
import { useParams } from "react-router";
import { useGroup } from "../../hooks/useGroup";
import { useGroupBots } from "../../hooks/useGroupBots";
import Layout from "../../components/Layout";
import { Header } from "./components/Header";
import {
  GROUP_BOT_ACTION_REMOVE,
  GroupBotCard,
} from "../../components/cards/GroupBotCard";
import { Box, Button, Pagination } from "@mui/material";
import { CardListing } from "../../components/layout/CardListing";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GroupBotAddModal } from "../../components/modals/GroupBotAddModal";
import { Add } from "@mui/icons-material";
import { UserContext } from "../../contexts/UserContext";

const i18n = {
  add: "add bots",
  error:
    "Error loading group... This group either does not exist or is set to private.",
};

const Group = () => {
  const [page, setPage] = useState(
    Number(new URLSearchParams(window.location.search).get("page")) || 1,
  );

  const navigate = useNavigate();
  const { id } = useParams();
  const { user: cookieUser, hasUser } = useContext(UserContext);

  const [isOpen, setIsOpen] = useState(false);

  const {
    data: group,
    isFetched: isFetchedGroup,
    isError: isErrorGroup,
  } = useGroup(id);
  const { data: bots, isFetched: isFetchedBots } = useGroupBots({
    id,
    page,
    enabled: isFetchedGroup && !isErrorGroup,
  });

  const belongsToUser = () => {
    return hasUser() && group && cookieUser.id === group.data.user.id;
  };

  const renderBots = () => {
    if (!isFetchedBots) {
      return <ProgressLoader />;
    }

    return (
      <Box>
        <CardListing>
          {bots.data.data.map((bot) => (
            <GroupBotCard
              key={`bot-${bot.id}`}
              action={GROUP_BOT_ACTION_REMOVE}
              group={group.data}
              bot={bot}
            />
          ))}
        </CardListing>
        {renderPagination()}
      </Box>
    );
  };

  const renderPagination = () => {
    const { total_pages } = bots.data;

    const hasPagination = total_pages > 1;

    if (hasPagination) {
      return (
        <Pagination
          page={page}
          count={total_pages}
          onChange={(event, page) => {
            setPage(page);
            navigate(`?page=${page}`);
          }}
        />
      );
    }
  };

  if (!isFetchedGroup) {
    return (
      <Layout>
        <ProgressLoader />
      </Layout>
    );
  }

  if (isErrorGroup) {
    return <Layout>{i18n.error}</Layout>;
  }

  return (
    <Layout>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}>
        <Header group={group.data} />
      </Box>
      {belongsToUser() && (
        <Button onClick={() => setIsOpen(true)}>
          <Add sx={{ mr: 1 }} />
          {i18n.add}
        </Button>
      )}
      <Box mt={2}>{renderBots()}</Box>

      {isOpen && (
        <GroupBotAddModal
          open={isOpen}
          group={group.data}
          onClose={() => setIsOpen(false)}
        />
      )}
    </Layout>
  );
};
export default Group;

import { useQuery } from "react-query";
import { getAccountStats } from "../api/v1/accounts";

export const useAccountStats = ({ account, enabled = true }) => {
  const query = `accounts/${account.id}/stats`;

  const { data, isError, isFetched, isFetching, remove } = useQuery(
    query,
    () => getAccountStats(account.id),
    {
      enabled,
      refetchOnWindowFocus: false,
      onError: (err) => {
        if (err.response.status === 401) {
          remove();
        }
      },
    },
  );
  return { data, isError, isFetched, isFetching, query };
};

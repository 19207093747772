import React from "react";
import { useParams } from "react-router";
import Layout from "../../components/Layout";
import { CardListing } from "../../components/layout/CardListing";
import ProgressLoader from "../../components/utils/ProgressLoader";
import { useRaribleCollectionItems } from "../../hooks/useRaribleCollectionItems";
import { Header } from "./components/Header";
import NFTContentCard from "./components/NFTContentCard";

const NftCollection = ({ chain }) => {
  let { id } = useParams();

  const { data: collection, isFetching } = useRaribleCollectionItems({
    enabled: true,
    blockchain: chain,
    contractAddress: id,
  });

  if (isFetching)
    return (
      <Layout>
        <ProgressLoader />
      </Layout>
    );

  return (
    <Layout>
      <Header chain={chain} />
      <CardListing>
        {collection.data.items.map((item) => {
          return <NFTContentCard key={`token-${item?.tokenId}`} data={item} />;
        })}
      </CardListing>
    </Layout>
  );
};
export default NftCollection;

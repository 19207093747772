import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import { grey } from "@mui/material/colors";
import {
  ArrowBack,
  Delete,
  Edit,
  GroupWork,
  LinkOff,
  Public,
  PublicOff,
  Workspaces,
} from "@mui/icons-material";
import { botIconFactory } from "../../../components/icons/BotIcons";
import { GroupDeleteModal } from "../../../components/modals/GroupDeleteModal";
import { GroupUpdateModal } from "../../../components/modals/GroupUpdateModal";
import { UserContext } from "../../../contexts/UserContext";
import {
  groupBotsCount,
  groupVisibility,
} from "../../../components/utils/groupUtils";

export const Header = ({ group }) => {
  const navigate = useNavigate();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const { user: activeUser, hasUser } = useContext(UserContext);
  const { name, bot_count, public: isPublic, avatars } = group;

  const belongsToUser = () => {
    return hasUser() && group && activeUser.id === group.user.id;
  };

  const renderAvatar = () => {
    if (avatars.length > 0) {
      return (
        <AvatarGroup max={3} spacing={"small"}>
          {avatars.map((auth, idx) => {
            return auth ? (
              <Badge
                key={`avatar-${idx}`}
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={botIconFactory(auth.provider, "large")}
              >
                <Avatar
                  sx={{ width: 60, height: 60 }}
                  src={auth.avatar.original}
                />
              </Badge>
            ) : (
              <Avatar sx={{ width: 60, height: 60 }} key={`avatar-${idx}`}>
                <LinkOff sx={{ width: 42, height: 42 }} />
              </Avatar>
            );
          })}
        </AvatarGroup>
      );
    } else {
      return (
        <Avatar sx={{ width: 60, height: 60 }}>
          <GroupWork sx={{ width: 42, height: 42 }} />
        </Avatar>
      );
    }
  };

  return (
    <Box>
      {hasUser() && (
        <IconButton
          sx={{ marginTop: -5 }}
          size="small"
          onClick={() => navigate(-1)}
        >
          <ArrowBack />
        </IconButton>
      )}

      <Box display="flex" justifyContent="space-between">
        <Stack
          direction="row"
          justifyContent="flex-start"
          spacing={2}
          sx={{ marginBottom: 4 }}
        >
          {renderAvatar()}

          <Stack direction="column" spacing={0} sx={{ paddingLeft: 1 }}>
            <Typography variant="h4">{name}</Typography>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ color: grey[600] }}
            >
              {isPublic ? (
                <Public fontSize="small" />
              ) : (
                <PublicOff fontSize="small" />
              )}
              <Typography sx={{ paddingRight: 1 }} variant="body2">
                {groupVisibility(isPublic)}
              </Typography>
              <Workspaces fontSize="small" />
              <Typography variant="body2">
                {groupBotsCount(bot_count)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {belongsToUser() && (
          <Box>
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              onClick={() => setIsEditOpen(true)}
            >
              <Edit />
            </Button>
            <Button variant="outlined" onClick={() => setIsDeleteOpen(true)}>
              <Delete />
            </Button>
          </Box>
        )}
      </Box>

      <GroupUpdateModal
        group={group}
        open={isEditOpen}
        onClose={() => setIsEditOpen(false)}
      />
      <GroupDeleteModal
        open={isDeleteOpen}
        group={group}
        onClose={() => setIsDeleteOpen(false)}
      />
    </Box>
  );
};

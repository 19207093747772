export const FeaturedPress = [
  {
    image: "images/home_rareprotocol.jpeg",
    link: "https://twitter.com/rareprotocolbot",
    target: "_self",
    title:
      "SuperRare DAO funds BotFrens to develop Twitter bot for Rare Protocol",
  },
  {
    image: "images/news_builder.jpeg",
    link: "https://nouns.build/dao/0xdf9b7d26c8fc806b1ae6273684556761ff02d422/vote/0xee46bb8653d187d2d0266fe9d1bc68cda7bd45c6b8bda59a5b788e5619503d6b",
    target: "_self",
    title:
      "Builder DAO funds BotFrens to continue development of Nounish DAOs bots",
  },
  {
    image: "images/news_rarible.png",
    link: "https://daotimes.com/rarible-dao-to-launch-a-twitter-bot-that-displays-real-time-marketplace-info/",
    target: "_blank",
    title:
      "RARI Foundation funds BotFrens to develop Twitter bot for Rarible marketplace",
  },
  {
    image: "images/news_foundation.jpg",
    link: "https://twitter.com/Foundation_Bot",
    target: "_blank",
    title:
      "Foundation NFT marketplace partners with BotFrens to launch a Twitter bot",
  },
  {
    image: "images/news_nouns.jpg",
    link: "https://nouns.wtf/vote/144",
    target: "_blank",
    title:
      "Nouns DAO funds BotFrens to develop one-click-bots for the Nounish ecosystem",
  },
];

export const FeaturedTeam = [
  {
    avatar: "images/team_andrei.png",
    email: null,
    linkedin: "https://www.linkedin.com/in/andreitr/",
    name: "Andrei Taraschuk",
    title: "Co-Founder, CEO",
    twitter: "https://twitter.com/andreitr",
  },
  {
    avatar: "images/team_cody.jpeg",
    email: null,
    linkedin: "https://www.linkedin.com/in/codybraun/",
    name: "Cody Braun",
    title: "Co-Founder, CTO",
    twitter: "https://twitter.com/ItsCodyBraun",
  },
  {
    avatar: "images/team_lilpizza.png",
    email: null,
    linkedin: "https://www.linkedin.com/in/jameson-hurst/",
    name: "Jamie Hurst",
    title: "Marketing",
    twitter: "https://twitter.com/lilpizza_eth",
  },
  {
    avatar: "images/team_botfren.jpeg",
    email: null,
    name: "BotFren",
    title: "Social Engagement",
    twitter: "https://twitter.com/botfrens",
  },
  {
    avatar: "",
    email: null,
    name: "Join Our Team",
    title: "Business Development ",
    twitter: "https://twitter.com/botfrens",
  },
];
